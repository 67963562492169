import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { produce } from 'immer'

import { makeGetDemandKey } from '@/hooks/useGetDemand'

import { handleToast } from '@/utils/handleToast'
import { Demand } from '@/types/demands'
import { updateIsQAOnResponsible } from '@/services/modules/athenas/demands/update-is-qa-on-responsible'

type UpdateIsQAOnResponsibleProps = {
  demandId: string
  reviewerId: number
  isQA: boolean
}

export function useUpdateIsQAOnResponsible() {
  const queryClient = useQueryClient()

  const updateIsQAOnResponsibleFromCache = useCallback(
    ({ reviewerId, demandId, isQA }: UpdateIsQAOnResponsibleProps) =>
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(demandId),
        produce((draft) => {
          if (!draft) return

          const reviewerIndex = draft.userList.findIndex(
            (user) => user.userId === reviewerId
          )

          draft.userList[reviewerIndex].isQA = isQA
        })
      ),
    [queryClient]
  )

  const mutation = useMutation({
    mutationFn: async ({
      reviewerId,
      demandId,
      isQA
    }: UpdateIsQAOnResponsibleProps) => {
      updateIsQAOnResponsibleFromCache({ reviewerId, demandId, isQA })

      await updateIsQAOnResponsible({ reviewerId, demandId, isQA })
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(makeGetDemandKey(variables.demandId))
    },
    onError: (_, variables) => {
      queryClient.invalidateQueries(makeGetDemandKey(variables.demandId))
      handleToast({
        type: 'error',
        message: `Erro ao ${variables.isQA ? 'adicionar' : 'remover'} o checklist de QA. Atualize a página e tente novamente.`
      })
    }
  })

  return mutation
}
