import { apiAthenas } from '@/services/api/apiAthenas'
import { FileType } from '@/types/fileTypes'

export async function getUrlForUploadWeedFiles(
  demandId: string,
  userId: number,
  fileType: string
) {
  const { data } = await apiAthenas.get<string>(
    `/demands/${demandId}/${userId}/files/${fileType}`
  )
  return data
}

export async function successUploadWeedFiles(
  demandId: string,
  userId: number,
  fileType: string,
  fileNameList: string[]
) {
  await apiAthenas.post<string>(
    `/user-demand/${demandId}/${userId}/${fileType}`,
    { fileNameList }
  )
}

export async function deleteWeedFiles({
  demandId,
  fileType,
  userId
}: {
  demandId: string
  userId: number
  fileType: FileType
}) {
  await apiAthenas.delete(`/demands/${demandId}/${userId}/files/${fileType}`)
}
