import { Button, Input } from '@/components'
import { twMerge } from '@/styles/tailwindMergeConfig'

import { useAuth } from '@/context/auth'
import { MdClose } from 'react-icons/md'
import { useGetDemand } from '@/hooks/useGetDemand'
import { useDeleteRevisionTime } from '@/hooks/useDeleteRevisionTime'

import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { usePutRevisionTime } from '@/hooks/usePutRevisionTime'
import { handleToast } from '@/utils/handleToast'

const revisionTimeSchema = z
  .object({
    hours: z.number().nonnegative(),
    minutes: z.number().nonnegative().lte(59)
  })
  .superRefine((val, ctx) => {
    const isValidHours = val.hours === 0 && val.minutes === 0

    if (isValidHours) {
      handleToast({ message: 'Digite uma hora valida!', type: 'error' })

      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'invalid hours',
        path: ['minutes']
      })
    }
  })

type RevisionTimeSchema = z.infer<typeof revisionTimeSchema>

export function RevisionTime() {
  const { data: demand } = useGetDemand()
  const { putRevisionTime } = usePutRevisionTime()

  const { user } = useAuth()
  const { deleteRevisionTime } = useDeleteRevisionTime()

  const infosCurrentUser = demand?.userList?.find(
    (reviewer) => reviewer.userId === user.id
  )

  const isRevisionTime = !!infosCurrentUser?.revisionTime
  const isDemandCompleted = demand!.status.value === 'concluded'

  const isDisabledChangeRevisionTime =
    !infosCurrentUser || isRevisionTime || isDemandCompleted

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<RevisionTimeSchema>({
    resolver: zodResolver(revisionTimeSchema)
  })

  function handleRevisionTime({ hours, minutes }: RevisionTimeSchema) {
    const formattedHours = () => {
      const newHours = hours.toString().padStart(2, '0')
      const newMinutes = minutes.toString().padStart(2, '0')

      return `${newHours}:${newMinutes}`
    }

    putRevisionTime.mutate({
      demandId: demand!.id,
      userId: user.id,
      revisionTime: formattedHours()
    })

    reset()
  }

  return (
    <>
      <div
        className={twMerge(
          'bg-cromai-m3-sys-light-surface-variant text-cromai-m3-sys-light-on-background',
          'dark:bg-cromai-m3-sys-dark-surface-variant dark:text-cromai-m3-sys-dark-on-background',
          ' flex min-h-[400px] w-full flex-col items-start gap-4 overflow-auto rounded px-6 py-4 font-mono text-labelLarge lg:h-full'
        )}
      >
        <div className="flex w-full gap-5">
          <form
            onSubmit={handleSubmit(handleRevisionTime)}
            className="flex w-full items-center gap-3"
          >
            <Input
              type="number"
              placeholder="Horas"
              isError={!!errors.hours}
              disabled={isDisabledChangeRevisionTime}
              className="no-arrow-buttons"
              {...register('hours', { valueAsNumber: true })}
            />
            <span>:</span>
            <Input
              type="number"
              placeholder="Minutos"
              isError={!!errors.minutes}
              disabled={isDisabledChangeRevisionTime}
              className="no-arrow-buttons"
              {...register('minutes', { valueAsNumber: true })}
            />
            <Button
              type="submit"
              variant="outlined"
              disabled={isDisabledChangeRevisionTime}
            >
              Adicionar
            </Button>
          </form>
        </div>
        {isRevisionTime && (
          <div className="group flex w-full items-center rounded bg-cromai-m3-sys-light-background px-6 py-4 text-cromai-m3-sys-light-on-background dark:bg-cromai-m3-sys-dark-background dark:text-cromai-m3-sys-dark-on-background">
            <span className="text-bodyLarge ">
              {infosCurrentUser?.revisionTime}
            </span>
            {!isDemandCompleted && (
              <button
                title="Remover tempo de revisão"
                onClick={() =>
                  deleteRevisionTime({ demandId: demand!.id, userId: user.id })
                }
                className="ml-auto hidden group-hover:block"
              >
                <MdClose size={24} />
              </button>
            )}
          </div>
        )}
      </div>
    </>
  )
}
