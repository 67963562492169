import { Accordion, SlideSheet } from '@/components'
import { WeedFilesUpload } from './WeedFilesUpload'
import { keysLabelMapping } from '@/utils/keysLabelMapping'
import { twMerge } from '@/styles/tailwindMergeConfig'

import { Demand, Reviewer } from '@/types/demands'

import { MdUploadFile as UploadFileIcon } from 'react-icons/md'

interface DialogUploadWeedProps {
  reviewer: Reviewer
  demand: Demand
}

const orderedLabelList = Object.keys(keysLabelMapping)

type ReviewerFilesKeys = Array<keyof Reviewer['files']['weed']>

export function DialogUploadWeed({ reviewer, demand }: DialogUploadWeedProps) {
  return (
    <SlideSheet
      trigger={
        <div
          className={twMerge(
            'flex items-center gap-3 px-3 py-1.5',
            'hover:bg-cromai-m3-sys-light-surface2-hover dark:hover:bg-cromai-m3-sys-dark-surface2-hover',
            'text-cromai-m3-sys-light-inverse-surface dark:text-cromai-m3-sys-dark-inverse-surface'
          )}
        >
          <UploadFileIcon size={21} />
          {demand.status.value === 'concluded'
            ? 'Visualizar arquivos'
            : 'Realizar upload de arquivos'}
        </div>
      }
      heading={`Resultados de ${reviewer.userName}`}
    >
      <Accordion.Root>
        {(Object.keys(reviewer.files.weed!) as ReviewerFilesKeys)
          .sort((a, b) => {
            const indexA = orderedLabelList.indexOf(a)
            const indexB = orderedLabelList.indexOf(b)

            return indexA - indexB
          })
          .map((keyFiles) => (
            <WeedFilesUpload
              key={keyFiles}
              demandStatus={demand.status.value}
              title={keyFiles}
              demandId={demand.id}
              userId={reviewer.userId}
              fileType={keyFiles}
              files={reviewer.files.weed![keyFiles]}
            />
          ))}
      </Accordion.Root>
    </SlideSheet>
  )
}
