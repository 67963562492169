import { useMemo } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import { twMerge } from '@/styles/tailwindMergeConfig'

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

import { MdArrowDropDown } from 'react-icons/md'
import { useGetSummaryBoards } from '@/hooks/boards/useGetSummaryBoards'

interface BoardsMenuProps {
  styleActive: string
}

export function BoardsMenu({ styleActive }: BoardsMenuProps) {
  const { id } = useParams()
  const { pathname } = useLocation()
  const { data } = useGetSummaryBoards()

  const board = data?.find((board) => board.id === id)

  const titleBoardMenu = useMemo(() => {
    if (board) {
      return board.name
    }

    if (pathname === '/boards/settings') {
      return 'Configurar quadros'
    }

    return 'Quadros'
  }, [board, pathname])

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        className={twMerge(
          'border-b-4 p-4',
          'hover:border-cromai-m3-sys-light-primary hover:text-cromai-m3-sys-light-on-surface-variant',
          'hover:dark:border-cromai-m3-sys-dark-primary hover:dark:text-cromai-m3-sys-dark-on-surface-variant',
          'flex cursor-pointer items-center justify-center text-labelXLarge outline-none transition-all',
          `${styleActive}`
        )}
      >
        {titleBoardMenu}

        {<MdArrowDropDown size={24} />}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="start"
          side="bottom"
          className={twMerge(
            'flex flex-col justify-center py-2',
            'mt-1  w-60 rounded',
            'text-bodyMedium, font-mono',
            'transition-all',
            'bg-cromai-m3-sys-light-surface2 dark:bg-cromai-m3-sys-dark-surface2',
            'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface',
            'shadow-lightElevation2 dark:shadow-darkElevation2'
          )}
        >
          {data?.map((board) => (
            <Link
              key={board.id}
              className="flex h-8 items-center px-4  hover:bg-cromai-m3-sys-light-surface2-hover dark:hover:bg-cromai-m3-sys-dark-surface2-hover"
              to={`/boards/${board.id}`}
            >
              {board.name}
            </Link>
          ))}

          <Link
            className="flex h-8 items-center px-4 hover:bg-cromai-m3-sys-light-surface2-hover dark:hover:bg-cromai-m3-sys-dark-surface2-hover"
            to="/boards/settings"
          >
            Configurar quadros...
          </Link>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
