import { AllPartsOfReviewers } from './ValidationTypes'

export function useAllPartsWithoutResults(
  allPartsOfReviewers: AllPartsOfReviewers[]
) {
  return allPartsOfReviewers.filter((part, _, parts) => {
    if (!part) return

    const withoutBothResults =
      !part.files.reconstruction_lines && !part.files.planting_failure

    if (withoutBothResults) return true
    if (part.files.reconstruction_lines) return

    const foundedPartWithReconstructionLines = parts.find((innerPart) => {
      if (!innerPart) return

      const isSamePart =
        innerPart.partNumber === part.partNumber &&
        part.partId !== innerPart.partId

      if (!isSamePart) return

      const hasReconstrunctionLinesWithoutPlantingFailure =
        innerPart.files.reconstruction_lines &&
        !innerPart.files.planting_failure

      return hasReconstrunctionLinesWithoutPlantingFailure
    })

    return !foundedPartWithReconstructionLines
  })
}
