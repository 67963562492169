import * as Scroll from '@radix-ui/react-scroll-area'
import { PropsWithChildren } from 'react'

type ScrollAreaProps = {
  className: string
} & PropsWithChildren

export function Scrollarea({ children, className }: ScrollAreaProps) {
  return (
    <Scroll.Root data-testid="scroll-area" className={className}>
      <Scroll.Viewport className="size-full border-inherit">
        {children}
      </Scroll.Viewport>
      <Scroll.Scrollbar
        orientation="vertical"
        className="mr-2 w-1 py-2"
      ></Scroll.Scrollbar>
    </Scroll.Root>
  )
}
