import { apiAthenas } from '@/services/api/apiAthenas'

export async function updateIsQAOnDemand({
  demandId,
  isQA
}: {
  demandId: string
  isQA: boolean
}) {
  await apiAthenas.put(`/demands/${demandId}/is-qa`, {
    isQA
  })
}
