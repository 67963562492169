import { apiAthenas } from '@/services/api/apiAthenas'

export async function createColumn({
  boardId,
  columnName
}: {
  columnName: string
  boardId: string
}) {
  const { data } = await apiAthenas.post<{
    columnName: string
    columnId: string
  }>(`/boards/${boardId}/column`, { columnName })

  return { id: data.columnId, name: data.columnName }
}
