import { Accordion, Button, LoadingCircle } from '@/components'
import { useDeletePartOfTheGrowLines } from '@/hooks/useDeletePartOfTheGrowLines'
import { GrowLines, Reviewer, Status } from '@/types/demands'
import { PartFilesUpload } from './PartFilesUpload'
import { PartNumber } from './PartNumber'

interface GrowLinePartProps {
  demandId: string
  reviewer: Reviewer
  part: GrowLines
  demandStatus: Status['value']
}

export function GrowLinePart({
  part,
  demandId,
  reviewer,
  demandStatus
}: GrowLinePartProps) {
  const { deletePartOfTheGrowLines } = useDeletePartOfTheGrowLines()

  return (
    <div className="flex w-full flex-col justify-between text-white">
      <div className="mb-4 flex items-center justify-between pr-10">
        <span className="font-mono text-sm font-bold leading-5 text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
          Indique qual a parte revisada:
        </span>
        <PartNumber
          demandStatus={demandStatus}
          demandId={demandId}
          partId={part.partId}
          userId={reviewer.userId}
          number={part.partNumber}
        />
      </div>
      <Accordion.Root>
        <PartFilesUpload
          demandStatus={demandStatus}
          demandId={demandId}
          userId={reviewer.userId}
          fileType={'perimeter'}
          partId={part.partId}
          files={part.files.perimeter}
        />
        <PartFilesUpload
          demandStatus={demandStatus}
          demandId={demandId}
          userId={reviewer.userId}
          fileType={'reconstruction_lines'}
          partId={part.partId}
          files={part.files.reconstruction_lines}
        />
        <PartFilesUpload
          demandStatus={demandStatus}
          demandId={demandId}
          userId={reviewer.userId}
          fileType={'planting_failure'}
          partId={part.partId}
          files={part.files.planting_failure}
        />
      </Accordion.Root>

      {demandStatus !== 'concluded' && (
        <Button
          className="ml-auto mr-8 text-cromai-m3-sys-light-error hover:text-cromai-m3-sys-light-error hover:opacity-80 dark:text-cromai-m3-sys-dark-error"
          variant="text"
          onClick={() =>
            deletePartOfTheGrowLines.mutate({
              demandId,
              partId: part.partId,
              userId: reviewer.userId
            })
          }
        >
          {deletePartOfTheGrowLines.isLoading && <LoadingCircle size={20} />}
          Remover parte
        </Button>
      )}
    </div>
  )
}
