import { queryClient } from '@/providers'
import { changeIsTagOnCard } from '@/services/modules/athenas/boards/change-is-tag-on-card'
import { handleToast } from '@/utils/handleToast'
import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Board } from '@/types/Board'
import { produce } from 'immer'
import { useCallback } from 'react'
import { Colors } from '@/types/Colors'
import { useMakeGetBoardKey } from '../useMakeGetBoardKey'

type UseChangeIsTagOnCardProps = {
  demandId: string
  isTag: boolean | 'indeterminate'
  tagId: string
  tagName: string
  tagColor: Colors
  columnIndex: number
  cardIndex: number
}

export function useChangeIsTagOnCard() {
  const { id } = useParams()
  const { queryKey } = useMakeGetBoardKey(id!)

  const changeIsTagOnCardOnCache = useCallback(
    ({
      columnIndex,
      cardIndex,
      tagId,
      tagName,
      tagColor,
      isTag
    }: UseChangeIsTagOnCardProps) =>
      queryClient.setQueryData<Board>(
        queryKey,
        produce((draft) => {
          if (!draft) return

          if (isTag) {
            draft.columns[columnIndex].cards[cardIndex].tags.push({
              id: tagId,
              name: tagName,
              color: tagColor
            })
            return
          }

          const filterTags = draft.columns[columnIndex].cards[
            cardIndex
          ].tags.filter((tag) => tag.id !== tagId)

          draft.columns[columnIndex].cards[cardIndex].tags = filterTags
        })
      ),
    [queryKey]
  )

  const mutation = useMutation({
    mutationFn: async ({
      demandId,
      columnIndex,
      cardIndex,
      tagId,
      tagName,
      tagColor,
      isTag
    }: UseChangeIsTagOnCardProps) => {
      changeIsTagOnCardOnCache({
        demandId,
        columnIndex,
        cardIndex,
        tagId,
        tagName,
        tagColor,
        isTag
      })

      await changeIsTagOnCard({ demandId, isTag, tagId })
    },
    onError: () => {
      queryClient.invalidateQueries(queryKey),
        handleToast({
          type: 'error',
          message:
            'Erro ao adicionar etiqueta. Atualize a página e tente criar novamente.'
        })
    }
  })

  return mutation
}
