import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/providers'
import { produce } from 'immer'

import { handleToast } from '@/utils/handleToast'
import { SummaryBoards } from '@/services/modules/athenas/boards/get-summary-boards'
import { deleteTag } from '@/services/modules/athenas/boards/delete-tag'
import { GET_SUMMARY_BOARDS_KEY } from '../useGetSummaryBoards'

interface UseDeleteTagProps {
  boardId: string
  tagId: string
}

export function useDeleteTag() {
  const deleteTagFromCache = useCallback(
    ({ boardId, tagId }: UseDeleteTagProps) =>
      queryClient.setQueryData<SummaryBoards[]>(
        [GET_SUMMARY_BOARDS_KEY],
        produce((draft) => {
          if (!draft) return

          const boardIndex = draft.findIndex((board) => board.id === boardId)

          const tagsFiltered = draft[boardIndex].tags.filter(
            (tag) => tag.id !== tagId
          )

          draft[boardIndex].tags = tagsFiltered
        })
      ),
    []
  )

  const mutation = useMutation({
    mutationFn: async ({ boardId, tagId }: UseDeleteTagProps) => {
      deleteTagFromCache({
        boardId,
        tagId
      })
      await deleteTag({
        boardId,
        tagId
      })
    },

    onError: () => {
      queryClient.invalidateQueries([GET_SUMMARY_BOARDS_KEY])
      handleToast({
        message:
          'Erro ao excluir etiqueta. Atualize a página e tente excluir novamente.',
        type: 'error'
      })
    }
  })

  return mutation
}
