import { Flip, ToastContainer } from 'react-toastify'

export function Toastify() {
  return (
    <ToastContainer
      position="bottom-center"
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
      theme="colored"
      transition={Flip}
      style={{
        width: 'max-content',
        minWidth: 451,
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: 0.1
      }}
    />
  )
}
