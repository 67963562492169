import { Accordion } from '@/components'
import { twMerge } from '@/styles/tailwindMergeConfig'
import { ReactNode } from 'react'

interface AccordionStyledProps {
  value: string
  title: ReactNode
  icon: ReactNode
  labelNumber: number
  children: ReactNode
}

export function AccordionStyled({
  value,
  title,
  icon,
  children,
  labelNumber
}: AccordionStyledProps) {
  return (
    <Accordion.Item value={value} className="group mb-2">
      <Accordion.Header
        className={twMerge(
          'flex items-center px-4 py-2',
          'bg-cromai-m3-sys-light-background  text-cromai-m3-sys-light-on-background',
          'dark:bg-cromai-m3-sys-dark-background dark:text-cromai-m3-sys-dark-on-background',
          'rounded-lg',
          'group-data-[state=open]:rounded-b-none'
        )}
      >
        <span className="mr-auto ">{title}</span>
        <span className="flex w-12 items-center gap-1 font-mono text-bodyLarge">
          {icon}
          {labelNumber}
        </span>
      </Accordion.Header>
      <Accordion.Content>
        <div
          className={twMerge(
            'flex items-center px-6',
            'bg-cromai-m3-sys-light-background  text-cromai-m3-sys-light-on-background',
            'dark:bg-cromai-m3-sys-dark-background dark:text-cromai-m3-sys-dark-on-background',
            'rounded-b-lg'
          )}
        >
          {children}
        </div>
      </Accordion.Content>
    </Accordion.Item>
  )
}
