import { WithClassNameProps } from '@/types'
import { useTheme } from '@/context/theme'

type LogoProps = {
  type?: 'isotipo' | 'imagotipo'
  version?: 'color' | 'mono'
} & WithClassNameProps

export function Logo({
  className,
  type = 'imagotipo',
  version = 'color'
}: LogoProps) {
  const { theme } = useTheme()

  const themeColor = {
    color: {
      system: {
        primary: '#E64B32',
        secondary: '#FAAF32',
        tertiary: '#CCCC00',
        quaternary: '#A0C84B',
        quinary: '#23AA50',
        text: '#FFFFFF'
      },
      light: {
        primary: '#E64B32',
        secondary: '#FAAF32',
        tertiary: '#CCCC00',
        quaternary: '#A0C84B',
        quinary: '#23AA50',
        text: '#053043'
      },
      dark: {
        primary: '#E64B32',
        secondary: '#FAAF32',
        tertiary: '#CCCC00',
        quaternary: '#A0C84B',
        quinary: '#23AA50',
        text: '#FFFFFF'
      }
    },
    mono: {
      system: {
        primary: '#FFFFFF',
        secondary: '#FFFFFF',
        tertiary: '#FFFFFF',
        quaternary: '#FFFFFF',
        quinary: '#FFFFFF',
        text: '#FFFFFF'
      },
      dark: {
        primary: '#FFFFFF',
        secondary: '#FFFFFF',
        tertiary: '#FFFFFF',
        quaternary: '#FFFFFF',
        quinary: '#FFFFFF',
        text: '#FFFFFF'
      },
      light: {
        primary: '#000000',
        secondary: '#000000',
        tertiary: '#000000',
        quaternary: '#000000',
        quinary: '#000000',
        text: '#000000'
      }
    }
  }

  return (
    <div className={className}>
      {type === 'imagotipo' ? (
        <svg
          width="343"
          height="79"
          viewBox="0 0 343 79"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-testid="svg-element"
        >
          <path
            d="M22.4282 15.5637C22.2756 12.0334 19.3991 9.2005 15.8253 9.2005C12.1643 9.2005 9.20065 12.1642 9.20065 15.8252C9.20065 19.4861 12.1643 22.4498 15.8253 22.4498C17.5904 22.4498 19.203 21.7525 20.3797 20.6193C24.2369 22.7985 26.939 25.6532 27.985 26.9171V76.8635H33.3676V25.0866L32.8446 24.3893C32.6702 24.106 28.8785 19.0067 22.4282 15.5637ZM15.8253 17.8954C14.6921 17.8954 13.7551 16.9583 13.7551 15.8252C13.7551 14.692 14.6921 13.7549 15.8253 13.7549C16.9585 13.7549 17.8955 14.692 17.8955 15.8252C17.8955 16.9583 16.9585 17.8954 15.8253 17.8954Z"
            fill={themeColor[version][theme]['secondary']}
          />
          <path
            d="M13.7115 34.7403C13.7115 34.5877 13.7551 34.457 13.7551 34.3045C13.7551 30.6435 10.7914 27.6798 7.13045 27.6798C3.46946 27.6798 0.505798 30.6435 0.505798 34.3045C0.505798 37.9654 3.46946 40.9291 7.13045 40.9291C8.65586 40.9291 10.0287 40.4061 11.1401 39.5344C13.8859 41.4521 15.8253 43.5005 16.697 44.5247V76.8417H22.0795V42.716L21.5565 42.0187C21.404 41.779 18.5928 37.9872 13.7115 34.7403ZM7.13045 36.3747C5.99729 36.3747 5.06025 35.4376 5.06025 34.3045C5.06025 33.1713 5.99729 32.2343 7.13045 32.2343C8.26362 32.2343 9.20065 33.1713 9.20065 34.3045C9.20065 35.4594 8.26362 36.3747 7.13045 36.3747Z"
            fill={themeColor[version][theme]['primary']}
          />
          <path
            d="M41.9752 0.505676C38.3142 0.505676 35.3506 3.46934 35.3506 7.13033C35.3506 9.83249 36.9632 12.1424 39.2731 13.1666V76.8417H44.6556V13.1666C46.9655 12.1206 48.5999 9.8107 48.5999 7.13033C48.6217 3.46934 45.6362 0.505676 41.9752 0.505676ZM41.9752 5.06013C43.1084 5.06013 44.0454 5.99717 44.0454 7.13033C44.0454 8.26349 43.1084 9.20053 41.9752 9.20053C40.8421 9.20053 39.905 8.26349 39.905 7.13033C39.9268 5.97537 40.8421 5.06013 41.9752 5.06013Z"
            fill={themeColor[version][theme]['tertiary']}
          />
          <path
            d="M68.147 22.4498C71.808 22.4498 74.7716 19.4861 74.7716 15.8252C74.7716 12.1642 71.808 9.2005 68.147 9.2005C64.5732 9.2005 61.6967 12.0334 61.5441 15.5637C55.072 19.0067 51.3021 24.106 51.1059 24.3893L50.5829 25.0866V76.8635H55.9655V26.8953C57.0333 25.6314 59.7354 22.7767 63.5708 20.5975C64.7911 21.7525 66.3819 22.4498 68.147 22.4498ZM68.147 13.7549C69.2802 13.7549 70.2172 14.692 70.2172 15.8252C70.2172 16.9583 69.2802 17.8954 68.147 17.8954C67.0138 17.8954 66.0768 16.9583 66.0768 15.8252C66.0768 14.692 67.0138 13.7549 68.147 13.7549Z"
            fill={themeColor[version][theme]['quaternary']}
          />
          <path
            d="M76.8418 27.6798C73.1808 27.6798 70.2172 30.6435 70.2172 34.3045C70.2172 34.457 70.2607 34.5877 70.2607 34.7403C65.3794 37.9872 62.5683 41.779 62.394 42.0187L61.871 42.716V76.8417H67.2535V44.5247C68.1034 43.5005 70.0428 41.4303 72.7886 39.5344C73.8999 40.4061 75.2946 40.9291 76.82 40.9291C80.481 40.9291 83.4447 37.9654 83.4447 34.3045C83.4665 30.6435 80.5028 27.6798 76.8418 27.6798ZM76.8418 36.3747C75.7086 36.3747 74.7716 35.4376 74.7716 34.3045C74.7716 33.1713 75.7086 32.2343 76.8418 32.2343C77.975 32.2343 78.912 33.1713 78.912 34.3045C78.912 35.4594 77.9968 36.3747 76.8418 36.3747Z"
            fill={themeColor[version][theme]['quinary']}
          />
          <path
            d="M131.844 69.9774C131.016 71.2413 130.079 72.3744 129.011 73.3986C127.943 74.4229 126.723 75.2727 125.328 75.9918C123.933 76.711 122.343 77.2558 120.556 77.648C118.769 78.0403 116.786 78.2146 114.585 78.2146C110.706 78.2146 107.394 77.6262 104.67 76.4495C101.946 75.2727 99.7012 73.6166 97.9579 71.4592C96.2146 69.3236 94.9506 66.7522 94.1661 63.7885C93.3816 60.8249 92.9676 57.5343 92.9676 53.9169C92.9676 50.147 93.3816 46.7475 94.2315 43.7185C95.0596 40.6676 96.3453 38.0962 98.0886 35.9607C99.832 33.8251 102.033 32.1907 104.713 31.014C107.394 29.859 110.553 29.2706 114.236 29.2706C118.137 29.2706 121.536 29.9462 124.413 31.2972C127.289 32.6483 129.556 34.7185 131.212 37.4643L123.803 42.4546C122.779 40.8638 121.515 39.6652 120.011 38.8154C118.507 37.9873 116.612 37.5514 114.323 37.5514C110.357 37.5514 107.437 38.8589 105.563 41.4957C103.689 44.1325 102.752 48.2729 102.752 53.9169C102.752 56.5101 102.948 58.7983 103.362 60.8031C103.776 62.8079 104.452 64.4859 105.367 65.837C106.304 67.188 107.524 68.2122 109.05 68.9314C110.575 69.6287 112.449 69.9992 114.65 69.9992C115.674 69.9992 116.633 69.912 117.549 69.7594C118.442 69.6069 119.292 69.3236 120.076 68.9532C120.861 68.5609 121.624 68.0815 122.321 67.4495C123.018 66.8394 123.716 66.0767 124.391 65.1614L131.844 69.9774Z"
            fill={themeColor[version][theme]['text']}
          />
          <path
            d="M163.376 39.9048C162.897 39.7305 162.287 39.5779 161.546 39.4254C160.805 39.2729 160.02 39.2075 159.236 39.2075C157.95 39.2075 156.73 39.3818 155.575 39.7305C154.42 40.0792 153.33 40.5586 152.284 41.1687C151.238 41.7789 150.236 42.4762 149.255 43.3043C148.297 44.1324 147.338 45.0041 146.379 45.8975V76.8634H136.899V30.5998H146.379V37.6603C147.381 36.5925 148.405 35.6119 149.473 34.653C150.541 33.716 151.631 32.8879 152.807 32.1906C153.962 31.4933 155.183 30.9267 156.447 30.5126C157.71 30.0986 159.04 29.9025 160.456 29.9025C161.546 29.9025 162.483 29.9896 163.246 30.1422C164.03 30.2947 164.684 30.5344 165.25 30.8177L163.376 39.9048Z"
            fill={themeColor[version][theme]['text']}
          />
          <path
            d="M207.853 53.8297C207.853 57.5997 207.439 60.9774 206.633 63.9628C205.826 66.9701 204.562 69.5197 202.841 71.6335C201.119 73.7473 198.918 75.3816 196.238 76.5148C193.558 77.6479 190.354 78.2145 186.628 78.2145C182.858 78.2145 179.633 77.648 176.931 76.493C174.229 75.338 172.006 73.7255 170.284 71.5899C168.563 69.4543 167.299 66.9047 166.493 63.9192C165.686 60.912 165.272 57.5561 165.272 53.8297C165.272 50.038 165.686 46.6167 166.514 43.5877C167.342 40.5586 168.606 37.9872 170.328 35.8516C172.049 33.7161 174.25 32.0817 176.952 30.9485C179.633 29.8154 182.858 29.2488 186.628 29.2488C190.354 29.2488 193.558 29.8154 196.238 30.9485C198.918 32.0817 201.119 33.7161 202.841 35.8516C204.562 37.9872 205.826 40.5586 206.633 43.5877C207.439 46.6385 207.853 50.038 207.853 53.8297ZM198.003 53.8297C198.003 48.3382 197.11 44.2414 195.345 41.561C193.558 38.8807 190.659 37.5514 186.628 37.5514C182.597 37.5514 179.676 38.8807 177.824 41.561C175.994 44.2414 175.057 48.3164 175.057 53.8297C175.057 59.1905 175.95 63.2001 177.759 65.9023C179.567 68.6044 182.509 69.9555 186.606 69.9555C190.572 69.9555 193.471 68.6044 195.279 65.9023C197.11 63.2219 198.003 59.1905 198.003 53.8297Z"
            fill={themeColor[version][theme]['text']}
          />
          <path
            d="M269.087 76.8635V49.6022C269.087 47.3141 269.022 45.4182 268.891 43.9146C268.761 42.411 268.477 41.2342 268.085 40.3408C267.671 39.4691 267.083 38.8371 266.32 38.4667C265.557 38.0962 264.533 37.9219 263.226 37.9219C262.288 37.9219 261.308 38.118 260.305 38.5103C259.303 38.9025 258.301 39.4255 257.276 40.1011C256.252 40.7548 255.272 41.5175 254.269 42.3892C253.289 43.2608 252.352 44.1543 251.48 45.0478V76.8417H242V49.6022C242 47.2487 241.935 45.3093 241.783 43.8056C241.63 42.302 241.347 41.1035 240.954 40.2318C240.54 39.3601 239.952 38.7718 239.211 38.4231C238.47 38.0962 237.468 37.9219 236.226 37.9219C235.223 37.9219 234.199 38.1398 233.131 38.5539C232.064 38.9897 231.039 39.5345 229.993 40.2318C228.969 40.9291 227.967 41.6918 227.008 42.5417C226.049 43.3916 225.177 44.2415 224.393 45.0478V76.8417H214.914V30.5999H224.393V37.0284C225.395 35.9171 226.441 34.8929 227.574 33.934C228.686 32.997 229.841 32.1689 231.039 31.4934C232.238 30.796 233.48 30.2512 234.766 29.859C236.051 29.4668 237.402 29.2706 238.819 29.2706C242.327 29.2706 244.986 29.968 246.838 31.3626C248.691 32.7573 249.933 34.8929 250.586 37.7258C251.85 36.3965 253.092 35.2197 254.291 34.1737C255.49 33.1277 256.732 32.2561 257.974 31.537C259.216 30.8178 260.523 30.2512 261.874 29.859C263.226 29.4668 264.664 29.2706 266.189 29.2706C268.652 29.2706 270.678 29.6629 272.269 30.4474C273.86 31.2319 275.124 32.3432 276.061 33.8033C276.998 35.2633 277.652 37.0067 278.022 39.0769C278.392 41.1253 278.567 43.4352 278.567 46.0066V76.8853H269.087V76.8635Z"
            fill={themeColor[version][theme]['text']}
          />
          <path
            d="M314.349 76.8634V71.6117C312.169 73.5511 309.881 75.1419 307.441 76.384C305.022 77.6262 302.385 78.2363 299.53 78.2363C297.177 78.2363 295.085 77.8877 293.276 77.1685C291.446 76.4712 289.92 75.4688 288.7 74.1831C287.458 72.8974 286.521 71.3938 285.867 69.6504C285.213 67.9071 284.908 65.9676 284.908 63.8539C284.908 61.0645 285.366 58.7328 286.303 56.837C287.24 54.9629 288.504 53.4157 290.138 52.2389C291.773 51.0404 293.712 50.1469 295.956 49.5586C298.201 48.9484 300.663 48.5126 303.344 48.2511L313.935 47.2269V45.0695C313.935 43.5441 313.717 42.2584 313.259 41.256C312.801 40.2318 312.148 39.4255 311.276 38.8153C310.404 38.2051 309.337 37.7693 308.051 37.5078C306.765 37.2681 305.327 37.1373 303.714 37.1373C302.145 37.1373 300.794 37.2899 299.639 37.5732C298.484 37.8783 297.46 38.2487 296.61 38.7281C295.76 39.2075 295.041 39.7305 294.496 40.3407C293.93 40.9509 293.494 41.5393 293.167 42.1276L285.671 37.3117C286.237 36.3093 287 35.3286 287.981 34.3698C288.961 33.411 290.204 32.5611 291.664 31.8202C293.124 31.0793 294.867 30.4691 296.894 29.9897C298.898 29.5103 301.23 29.2924 303.889 29.2924C307.114 29.2924 309.968 29.5539 312.409 30.0769C314.85 30.5999 316.876 31.4933 318.511 32.7136C320.145 33.9558 321.366 35.6119 322.194 37.6821C323.022 39.7523 323.436 42.3455 323.436 45.4182V76.8634H314.349ZM313.913 54.3745L307.288 54.9847C305.261 55.159 303.453 55.4423 301.884 55.8128C300.315 56.205 298.986 56.728 297.918 57.4035C296.85 58.0791 296.022 58.929 295.477 59.9314C294.91 60.9556 294.649 62.1977 294.649 63.6577C294.649 66.0984 295.303 67.8199 296.588 68.8441C297.874 69.8683 299.705 70.3696 302.058 70.3696C302.973 70.3696 303.91 70.217 304.913 69.8901C305.893 69.5633 306.896 69.1492 307.92 68.6044C308.944 68.0814 309.947 67.4495 310.971 66.7304C311.995 66.0112 312.976 65.2485 313.956 64.464V54.3745H313.913Z"
            fill={themeColor[version][theme]['text']}
          />
          <path
            d="M342.787 18.4183C342.787 20.3578 342.329 21.7089 341.392 22.4498C340.477 23.1907 339.039 23.5829 337.099 23.5829C334.985 23.5829 333.525 23.1471 332.719 22.2537C331.913 21.382 331.499 20.0963 331.499 18.4183C331.499 16.5878 331.934 15.2803 332.784 14.4741C333.634 13.6678 335.072 13.2537 337.099 13.2537C339.126 13.2537 340.586 13.646 341.457 14.4523C342.351 15.2368 342.787 16.566 342.787 18.4183ZM332.414 76.8634V30.5998H341.893V76.8634H332.414Z"
            fill={themeColor[version][theme]['text']}
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="83"
          height="77"
          viewBox="0 0 83 77"
          fill="none"
          data-testid="svg-element"
        >
          <path
            d="M21.9586 15.3629C21.806 11.8327 18.9295 8.99976 15.3557 8.99976C11.6947 8.99976 8.73106 11.9634 8.73106 15.6244C8.73106 19.2854 11.6947 22.2491 15.3557 22.2491C17.1208 22.2491 18.7334 21.5517 19.9102 20.4186C23.7673 22.5977 26.4694 25.4524 27.5154 26.7163V76.6627H32.898V24.8858L32.375 24.1885C32.2006 23.9052 28.4089 18.806 21.9586 15.3629ZM15.3557 17.6946C14.2225 17.6946 13.2855 16.7576 13.2855 15.6244C13.2855 14.4912 14.2225 13.5542 15.3557 13.5542C16.4889 13.5542 17.4259 14.4912 17.4259 15.6244C17.4259 16.7576 16.4889 17.6946 15.3557 17.6946Z"
            fill={themeColor[version][theme]['secondary']}
          />
          <path
            d="M13.2419 34.5396C13.2419 34.3871 13.2855 34.2563 13.2855 34.1038C13.2855 30.4428 10.3219 27.4791 6.66086 27.4791C2.99987 27.4791 0.0362091 30.4428 0.0362091 34.1038C0.0362091 37.7648 2.99987 40.7284 6.66086 40.7284C8.18627 40.7284 9.55914 40.2054 10.6705 39.3338C13.4163 41.2514 15.3557 43.2998 16.2274 44.324V76.641H21.6099V42.5153L21.0869 41.818C20.9344 41.5783 18.1233 37.7866 13.2419 34.5396ZM6.66086 36.174C5.5277 36.174 4.59066 35.2369 4.59066 34.1038C4.59066 32.9706 5.5277 32.0336 6.66086 32.0336C7.79403 32.0336 8.73107 32.9706 8.73107 34.1038C8.73107 35.2587 7.79403 36.174 6.66086 36.174Z"
            fill={themeColor[version][theme]['primary']}
          />
          <path
            d="M41.5056 0.304932C37.8447 0.304932 34.881 3.2686 34.881 6.92959C34.881 9.63175 36.4936 11.9417 38.8035 12.9659V76.641H44.186V12.9659C46.4959 11.9199 48.1303 9.60996 48.1303 6.92959C48.1521 3.2686 45.1666 0.304932 41.5056 0.304932ZM41.5056 4.85938C42.6388 4.85938 43.5758 5.79642 43.5758 6.92959C43.5758 8.06275 42.6388 8.99979 41.5056 8.99979C40.3725 8.99979 39.4354 8.06275 39.4354 6.92959C39.4572 5.77463 40.3725 4.85938 41.5056 4.85938Z"
            fill={themeColor[version][theme]['tertiary']}
          />
          <path
            d="M67.6774 22.2491C71.3384 22.2491 74.302 19.2854 74.302 15.6244C74.302 11.9634 71.3384 8.99976 67.6774 8.99976C64.1035 8.99976 61.227 11.8327 61.0745 15.3629C54.6024 18.806 50.8324 23.9052 50.6363 24.1885L50.1133 24.8858V76.6627H55.4959V26.7163C56.5636 25.4524 59.2658 22.5977 63.1011 20.4186C64.3215 21.5517 65.9122 22.2491 67.6774 22.2491ZM67.6774 13.5542C68.8105 13.5542 69.7476 14.4912 69.7476 15.6244C69.7476 16.7576 68.8105 17.6946 67.6774 17.6946C66.5442 17.6946 65.6072 16.7576 65.6072 15.6244C65.6072 14.4912 66.5442 13.5542 67.6774 13.5542Z"
            fill={themeColor[version][theme]['quaternary']}
          />
          <path
            d="M76.3722 27.4791C72.7112 27.4791 69.7476 30.4428 69.7476 34.1038C69.7476 34.2563 69.7912 34.3871 69.7912 34.5396C64.9098 37.7866 62.0987 41.5783 61.9244 41.818L61.4014 42.5153V76.641H66.7839V44.324C67.6338 43.2998 69.5733 41.2296 72.319 39.3338C73.4304 40.2054 74.825 40.7284 76.3505 40.7284C80.0114 40.7284 82.9751 37.7648 82.9751 34.1038C82.9969 30.4428 80.0332 27.4791 76.3722 27.4791ZM76.3722 36.174C75.2391 36.174 74.302 35.2369 74.302 34.1038C74.302 32.9706 75.2391 32.0336 76.3722 32.0336C77.5054 32.0336 78.4424 32.9706 78.4424 34.1038C78.4424 35.2587 77.5272 36.174 76.3722 36.174Z"
            fill={themeColor[version][theme]['quinary']}
          />
        </svg>
      )}
    </div>
  )
}
