import { PropsWithChildren } from 'react'
import { Logo } from '@/components'

export function SigninLayout({ children }: PropsWithChildren) {
  return (
    <div className="fixed flex h-screen w-full flex-col items-center justify-center bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant">
      <header className="absolute left-8 top-8">
        <Logo className="logo" type="imagotipo" version="color" />
      </header>
      {children}
    </div>
  )
}
