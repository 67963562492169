import { PropsWithChildren } from 'react'
import * as ContextMenu from '@radix-ui/react-context-menu'

import { useMoveColumnCard } from '@/hooks/boards/useMoveColumnCard'
import { useGetBoard } from '@/hooks/boards/useGetBoard'
import { twMerge } from '@/styles/tailwindMergeConfig'
import { Tag } from '@/types/Board'
import { TagsAvaliable } from './TagsAvaliable'

type CardMenuProps = {
  demandId: string
  demandName: string
  cardIndex: number
  columnIndex: number
  currentColumnId: string
  currentTags: Tag[]
  isMovableCards: boolean
} & PropsWithChildren

export function ContextMenuCard({
  children,
  demandId,
  demandName,
  cardIndex,
  currentColumnId,
  columnIndex,
  currentTags,
  isMovableCards
}: CardMenuProps) {
  const { query: getBoard } = useGetBoard()
  const { mutate: moveColumn } = useMoveColumnCard()

  const { tags: tagsAvaliable, columns } = getBoard.data!

  const listOfVerifiedTags = tagsAvaliable?.map((tagBoard) => {
    const tag = currentTags?.find((currentTag) => currentTag.id === tagBoard.id)

    return tag ? { ...tagBoard, isTag: true } : { ...tagBoard, isTag: false }
  })

  const columnWithMovableCards = columns.filter(
    (column) => column.isMovableCards
  )

  return (
    <ContextMenu.Root>
      {children}
      <ContextMenu.Portal>
        <ContextMenu.Content>
          <div className="flex w-full flex-col rounded bg-[#D6E8DA] pt-2 shadow-lightElevation2 dark:bg-[#202C21] dark:shadow-darkElevation2">
            <TagsAvaliable
              cardIndex={cardIndex}
              demandId={demandId}
              demandName={demandName}
              columnIndex={columnIndex}
              listOfVerifiedTags={listOfVerifiedTags}
            />
            {isMovableCards && (
              <ContextMenu.Label className="px-4 py-2 font-mono text-labelSmall  text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
                Mover para
              </ContextMenu.Label>
            )}
            {columnWithMovableCards?.map((column) => {
              const isNotCurrentColumn = column.id !== currentColumnId

              if (isNotCurrentColumn && isMovableCards) {
                return (
                  <ContextMenu.Item
                    key={`${demandId}-${column.id}`}
                    onClick={() =>
                      moveColumn({
                        cardIndex,
                        currentColumnId,
                        demandId,
                        newColumnId: column.id
                      })
                    }
                    className={twMerge(
                      'cursor-pointer outline-none transition-all',
                      'px-4 py-2 font-mono text-bodySmall',
                      'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface ',
                      'hover:bg-cromai-m3-sys-light-surface2-hover dark:hover:bg-cromai-m3-sys-dark-surface2-hover'
                    )}
                  >
                    {column.name}
                  </ContextMenu.Item>
                )
              }
            })}
          </div>
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  )
}
