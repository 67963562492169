import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'

type Theme = 'light' | 'dark' | 'system'

const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

function getSystemTheme() {
  return darkModeMediaQuery.matches ? 'dark' : 'light'
}

function setClassTheme(theme: Theme) {
  if (theme === 'dark') return document.documentElement.classList.add('dark')

  if (theme === 'light')
    return document.documentElement.classList.remove('dark')

  const themeSystem = getSystemTheme()

  document.documentElement.classList.toggle('dark', themeSystem === 'dark')
}

function getInitialTheme() {
  return (localStorage.getItem('@theme.v0.0.1') || 'system') as Theme
}

function useThemeContext() {
  const [themeConfig, setThemeConfig] = useState<Theme>(getInitialTheme)

  useEffect(() => {
    setClassTheme(themeConfig)
    localStorage.setItem('@theme.v0.0.1', themeConfig)

    darkModeMediaQuery.addEventListener('change', () =>
      setClassTheme(themeConfig)
    )
    return () => darkModeMediaQuery.removeEventListener('change', () => null)
  }, [themeConfig])

  const theme = themeConfig === 'system' ? getSystemTheme() : themeConfig

  return { themeConfig, setThemeConfig, theme } as const
}

const ThemeContext = createContext({} as ReturnType<typeof useThemeContext>)

export function ThemeProvider({ children }: { children: ReactNode }) {
  return (
    <ThemeContext.Provider value={useThemeContext()}>
      {children}
    </ThemeContext.Provider>
  )
}

export function useTheme() {
  return useContext(ThemeContext)
}
