import * as ScrollArea from '@radix-ui/react-scroll-area'

import { Header } from './Header'
import { LoadingSkeleton } from './LoadingSkeleton'

import { useGetDemand } from '@/hooks/useGetDemand'
import { handleToast } from '@/utils/handleToast'

import { twMerge } from '../../styles/tailwindMergeConfig'
import { useDownload } from '@/hooks/useDownload'
import { Responsible } from './Responsible'
import { useState } from 'react'
import { RevisionTime } from './RevisionTime'

export function DemandsDetails() {
  const [revisionTime, setRevisionTime] = useState<boolean>(false)

  const { data, isLoading, error } = useGetDemand({
    onError: () =>
      handleToast({
        message: 'Não foi possível carregar a página. Tente novamente.',
        type: 'error',
        autoClose: false
      })
  })

  const { download } = useDownload({
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível realizar o download'
      })
  })

  if (isLoading || error) {
    return <LoadingSkeleton className={twMerge(isLoading && 'animate-pulse')} />
  }

  const isActiveClass = [
    'bg-cromai-m3-sys-light-surface-variant text-cromai-m3-sys-light-on-surface',
    'dark:bg-cromai-m3-sys-dark-surface-variant dark:text-cromai-m3-sys-dark-on-surface',
    'rounded px-2 py-2 font-mono text-labelMedium'
  ].join(' ')

  const isDisabledClass = [
    'text-cromai-m3-sys-light-outline',
    'dark:text-cromai-m3-sys-dark-outline',
    'px-2 py-2 font-mono text-labelMedium'
  ].join(' ')

  if (data) {
    return (
      <ScrollArea.Root className="h-screen overflow-hidden">
        <ScrollArea.Viewport className="size-full border-inherit">
          <main className="flex size-full flex-col items-center bg-cromai-m3-sys-light-background px-7 pb-10 pt-6 font-mono dark:bg-cromai-m3-sys-dark-background lg:h-screen">
            <Header />
            <section
              className={twMerge(
                'lg:flex-row',
                'mt-6 flex h-full w-full max-w-screen-xl flex-col gap-6 '
              )}
            >
              {/* Aba Links */}
              <div className="flex flex-1 flex-col items-start gap-2">
                <span
                  className={twMerge(
                    'bg-cromai-m3-sys-light-surface-variant text-cromai-m3-sys-light-on-background',
                    'dark:bg-cromai-m3-sys-dark-surface-variant dark:text-cromai-m3-sys-dark-on-background',
                    'rounded px-6 py-2 font-mono text-labelSmall'
                  )}
                >
                  Links
                </span>
                <div
                  className={twMerge(
                    'bg-cromai-m3-sys-light-surface-variant text-cromai-m3-sys-light-on-background',
                    'dark:bg-cromai-m3-sys-dark-surface-variant dark:text-cromai-m3-sys-dark-on-background',
                    ' flex min-h-[400px] w-full flex-col items-start gap-4 overflow-auto rounded px-4 py-6 font-mono text-labelLarge lg:h-full'
                  )}
                >
                  {data.files?.map((file) => {
                    const dataForDownload = {
                      id: data.id,
                      key: file.key,
                      value: file.value
                    }
                    return (
                      <button
                        onClick={() => download.mutate(dataForDownload)}
                        key={file.key}
                        className="px-6 py-2 text-labelSmall text-cromai-m3-sys-light-on-surface-variant transition-all hover:opacity-80 dark:text-cromai-m3-sys-dark-on-surface-variant"
                      >
                        {file.label}
                      </button>
                    )
                  })}
                </div>
              </div>
              {/* Aba Responsaveis */}
              <div
                className={twMerge(
                  'lg:w-[420px]',
                  'flex flex-1 flex-col gap-2 lg:flex-none'
                )}
              >
                <div className="flex gap-2 ">
                  <button
                    onClick={() => setRevisionTime(false)}
                    className={twMerge(
                      !revisionTime ? isActiveClass : isDisabledClass,
                      'transition-opacity hover:opacity-80'
                    )}
                  >
                    Responsáveis
                  </button>
                  <button
                    onClick={() => setRevisionTime(true)}
                    className={twMerge(
                      revisionTime ? isActiveClass : isDisabledClass,
                      'transition-opacity hover:opacity-80'
                    )}
                  >
                    Seu tempo de revisão
                  </button>
                </div>
                {revisionTime ? <RevisionTime /> : <Responsible />}
              </div>
            </section>
          </main>
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar orientation="vertical" className="mr-2 w-1 py-2">
          <ScrollArea.Thumb className="w-1 rounded-full bg-cromai-m3-sys-light-outline dark:bg-cromai-m3-sys-dark-outline " />
        </ScrollArea.Scrollbar>
      </ScrollArea.Root>
    )
  }
}
