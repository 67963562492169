import { extendTailwindMerge, Config } from 'tailwind-merge'
import { CromaiFontSize } from './theme'
import { TV, tv } from 'tailwind-variants'

const cromaiFontSize = Object.keys(CromaiFontSize).map((text) => `text-${text}`)

export const twMergeConfig: Partial<Config> = {
  classGroups: {
    'font-size': cromaiFontSize
  }
}

export const twMerge = extendTailwindMerge(twMergeConfig)

export const customTv: TV = (variants, config) => {
  return tv(variants, {
    ...config,
    twMergeConfig
  })
}
