export function FilterSkeleton() {
  return (
    <div role="progressbar" className="flex animate-pulse flex-col gap-3.5">
      {Array.from({ length: 10 }).map((_, count) => (
        <div
          key={count}
          className="h-10 w-full rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant"
        />
      ))}
    </div>
  )
}
