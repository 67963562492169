import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { makeGetDemandKey } from './useGetDemand'

import { handleToast } from '@/utils/handleToast'
import { Demand } from '@/types/demands'

import { produce } from 'immer'
import { deletePartOfTheGrowLines } from '@/services/modules/athenas/growLines'

export function useDeletePartOfTheGrowLines() {
  const queryClient = useQueryClient()

  const deletePartOfTheGrowLinesFromCache = useCallback(
    (variables: { demandId: string; userId: number; partId: string }) =>
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(variables.demandId),
        produce((draft) => {
          if (!draft) return
          const index = draft.userList.findIndex(
            (user) => user.userId === variables.userId
          )
          if (index !== -1) {
            const partsFiltered = draft.userList[
              index
            ].files.grow_lines?.filter(
              (part) => part.partId !== variables.partId
            )
            draft.userList[index].files.grow_lines! = partsFiltered
              ? [...partsFiltered!]
              : []
          }
        })
      ),
    [queryClient]
  )

  const mutation = useMutation({
    mutationFn: deletePartOfTheGrowLines,
    cacheTime: 0,
    onSuccess: (_, variables) => {
      deletePartOfTheGrowLinesFromCache(variables)
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível remover a Parte. Tente novamente'
      })
  })
  return {
    deletePartOfTheGrowLines: mutation
  }
}
