import * as ScrollArea from '@radix-ui/react-scroll-area'
import * as Popover from '@radix-ui/react-popover'

import { Button } from '@/components'
import { useMemo, useState } from 'react'
import { MdOutlinePersonAdd as PersonAddIcon } from 'react-icons/md'
import { twMerge } from '@/styles/tailwindMergeConfig'
import { useGetUsers } from '@/hooks/demands/queries/useGetUsers'
import { useGetDemand } from '@/hooks/useGetDemand'
import { Reviewer } from './Reviewer'

interface PopoverReviewersProps {
  side?: 'top' | 'right' | 'bottom' | 'left' | undefined
  align?: 'center' | 'start' | 'end' | undefined
  sideOffset?: number
  alignOffset?: number
}

export function PopoverReviewers({
  align,
  side,
  alignOffset,
  sideOffset
}: PopoverReviewersProps) {
  const { data } = useGetDemand()

  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false)
  const [filter, setFilter] = useState<string>('')

  const { data: users } = useGetUsers()

  const filteredReviewers = useMemo(
    () =>
      users?.filter((user) =>
        user.name.toLowerCase().includes(filter.toLowerCase())
      ),
    [filter, users]
  )

  return (
    <Popover.Root onOpenChange={(open) => setIsOpenPopover(open)}>
      <Popover.Trigger asChild className="w-full">
        <Button
          disabled={data!.status.value === 'concluded'}
          className={`mb-4 w-full ${
            isOpenPopover &&
            'bg-cromai-m3-sys-light-inverse-on-surface dark:bg-cromai-m3-sys-dark-inverse-on-surface'
          }`}
          variant="outlined"
        >
          <PersonAddIcon size={18} />
          Adicionar responsáveis
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side={side}
          align={align}
          sideOffset={sideOffset}
          alignOffset={alignOffset}
        >
          <div className={twMerge('w-[321px] bg-[#EFF6ED] dark:bg-[#1E261E]')}>
            <div className="mb-2 w-full px-4 pt-4">
              <input
                type="text"
                title="Pesquisar"
                placeholder="Pesquisar"
                onChange={(e) => setFilter(e.target.value)}
                className="w-full rounded border border-cromai-m3-sys-light-outline bg-transparent px-4 py-2 text-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline dark:text-cromai-m3-sys-dark-outline"
              />
            </div>
            <ScrollArea.Root className="h-[calc(100vh-33.9rem)] overflow-hidden lg:h-[calc(100vh-15.3rem)]">
              <ScrollArea.Viewport className="size-full border-inherit px-4">
                {filteredReviewers?.map((user) => {
                  const isAddedUser = data!.userList?.find(
                    (currentUser) => currentUser.userId === user.id
                  )
                  return (
                    <Reviewer
                      isAddedUser={!!isAddedUser}
                      key={user.id}
                      user={{
                        id: user.id,
                        name: user.name,
                        email: user.email
                      }}
                    />
                  )
                })}
              </ScrollArea.Viewport>
              <ScrollArea.Scrollbar
                orientation="vertical"
                className="mr-2 w-1 py-2"
              >
                <ScrollArea.Thumb className="w-1 rounded-full bg-cromai-m3-sys-light-outline dark:bg-cromai-m3-sys-dark-outline " />
              </ScrollArea.Scrollbar>
            </ScrollArea.Root>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
