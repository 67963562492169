import { getDemand } from '@/services/modules/athenas/demands'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

export const GET_DEMAND_KEY = 'GET_DEMAND'

export function makeGetDemandKey(id: string) {
  return [GET_DEMAND_KEY, { id }]
}
interface UseGetDemandProps {
  onError?: () => void
}

export function useGetDemand({ onError }: UseGetDemandProps = {}) {
  const { id } = useParams()

  const query = useQuery({
    queryKey: makeGetDemandKey(id!),
    queryFn: () => getDemand(id!),
    onError
  })

  return query
}
