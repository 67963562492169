import { postResponsible } from '@/services/modules/athenas/demands'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { handleToast } from '@/utils/handleToast'
import { makeGetDemandKey } from '../../useGetDemand'

export function useAddResponsible() {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: postResponsible,
    onSuccess: (_, variables) =>
      queryClient.invalidateQueries(makeGetDemandKey(variables.demandId)),

    onError: () =>
      handleToast({
        type: 'error',
        message:
          'Não foi possível adicionar o responsável à demanda. Tente novamente.'
      })
  })
  return mutation
}
