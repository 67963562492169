import * as DialogRadix from '@radix-ui/react-dialog'
import { PropsWithChildren } from 'react'

type DialogProps = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
} & PropsWithChildren

export function Dialog({ isOpen, setIsOpen, children }: DialogProps) {
  return (
    <DialogRadix.Root open={isOpen} onOpenChange={setIsOpen}>
      <DialogRadix.Portal>
        <DialogRadix.Overlay className="absolute inset-0 bg-black opacity-40" />
        <DialogRadix.Content className="absolute left-1/2 top-1/2  -translate-x-1/2 -translate-y-1/2 rounded-3xl bg-cromai-m3-sys-light-surface-variant  dark:bg-cromai-m3-sys-dark-surface-variant">
          {children}
        </DialogRadix.Content>
      </DialogRadix.Portal>
    </DialogRadix.Root>
  )
}
