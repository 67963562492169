import { useUpdatePartNumber } from '@/hooks/useUpdatePartNumber'
import { Status } from '@/types/demands'
import { useState } from 'react'
import { LuLoader2 as LoaderIcon } from 'react-icons/lu'

interface PartNumberProps {
  demandId: string
  demandStatus: Status['value']
  partId: string
  userId: number
  number: number | null
}

export function PartNumber({
  demandId,
  demandStatus,
  partId,
  userId,
  number
}: PartNumberProps) {
  const [part, setPart] = useState('')

  const query = useUpdatePartNumber({
    demandId,
    partId,
    userId,
    partNumber: Number(part)
  })

  return (
    <label className="relative flex h-10 w-[9.1875rem] items-center justify-center rounded border border-cromai-m3-sys-light-outline bg-transparent p-2 text-center text-bodyLarge text-cromai-m3-sys-light-on-surface dark:border-cromai-m3-sys-dark-outline dark:text-cromai-m3-sys-dark-on-surface">
      <input
        type="number"
        aria-label="Número da parte"
        placeholder="Ex: 1"
        min={0}
        disabled={demandStatus === 'concluded'}
        defaultValue={number ? number : ''}
        onChange={(e) => setPart(e.target.value)}
        className="no-arrow-buttons w-0 flex-1 border-none bg-transparent text-center text-bodyLarge outline-none"
      />
      {query.isFetching && (
        <LoaderIcon
          role="progressbar"
          size={16}
          className="absolute right-2 ml-auto animate-spin"
        />
      )}
    </label>
  )
}
