import { apiAthenas } from '@/services/api/apiAthenas'
import { Colors } from '@/types/Colors'

export async function createTag({
  boardId,
  name,
  color
}: {
  boardId: string
  name: string
  color: string
}) {
  const { data } = await apiAthenas.post<{
    id: string
    name: string
    color: Colors
  }>(`/tags/${boardId}`, { name, color })

  return { id: data.id, name: data.name, color: data.color }
}
