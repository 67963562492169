import { useAuth } from '@/context/auth'
import Private from './Private'
import Public from './Public'

export function Router() {
  const { isAuthenticated, isInitialLoading } = useAuth()

  if (isInitialLoading) {
    return <h1>loading</h1>
  }

  return isAuthenticated ? <Private /> : <Public />
}
