import { Card } from '@/types/Board'

export function useGetTotalArea() {
  function getTotalArea(cards: Card[]) {
    const totalArea = cards.reduce((acc, card) => {
      acc = acc + Number(card.area)

      return acc
    }, 0)

    return totalArea
  }

  return { getTotalArea }
}
