interface DeadlineTagProps {
  type: 'date' | 'today' | 'tomorrow' | 'late'
  date: string
}

const deadlineStyle = {
  date: 'bg-transparent text-bodyMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface',
  today: 'bg-[#FF8253] text-labelLarge text-[#1A1C19]',

  tomorrow:
    'border-2 border-cromai-m3-sys-light-outline text-labelLarge text-cromai-m3-sys-light-on-surface dark:border-cromai-m3-sys-dark-outline  dark:text-cromai-m3-sys-dark-on-surface',

  late: 'bg-[#A20F0F] text-labelLarge text-[#FFFFFF]'
}

export function DeadlineTag({ type, date }: DeadlineTagProps) {
  return (
    <span
      className={`box-border flex h-6 w-14 items-center justify-center rounded-md text-center ${deadlineStyle[type]}`}
    >
      {date}
    </span>
  )
}
