import * as DialogRadix from '@radix-ui/react-dialog'
import { Button, Dialog } from '@/components'

import { useRecoverDemand } from '@/hooks/useRecoverDemand'

interface RecoverDialogProps {
  demandId: string
  demandName: string
  setIsOpen: (value: boolean) => void
  isOpen: boolean
}
export function RecoverDialog({
  demandId,
  demandName,
  isOpen,
  setIsOpen
}: RecoverDialogProps) {
  const { recoverDemandMutate, isRecoverDemandLoading } = useRecoverDemand({
    onSuccess: () => {
      setIsOpen(false)
    }
  })

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="flex w-[37.6875rem] flex-col p-6">
        <h1 className="mb-2 text-titleLarge text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
          Reaver demanda
        </h1>
        <span className="mb-8 font-mono text-bodyLarge text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
          Você tem certeza que deseja reaver a demanda
          <strong> {demandName}</strong>? Os resultados serão removidos do Atlas
          do cliente
        </span>
        <div className="flex w-full items-center justify-between">
          <DialogRadix.Close asChild>
            <Button variant="outlined">Cancelar</Button>
          </DialogRadix.Close>
          <Button
            className="w-36"
            loading={isRecoverDemandLoading}
            onClick={() => recoverDemandMutate({ demandId })}
          >
            Reaver demanda
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
