import { customTv } from '@/styles/tailwindMergeConfig'
import { Colors } from '@/types/Colors'

type TagProps = {
  label: string
  color: Colors
  className?: string
}

export function Tag({ label, color, className }: TagProps) {
  const variants = customTv({
    base: 'px-2 h-4 min-w-14 max-w-[11.25rem] truncate rounded font-mono text-[10px] font-semibold leading-4 tracking-wider',
    variants: {
      color: {
        turquoise:
          'text-[#164B35] dark:text-[#B5EED6] bg-[#4BCE97] dark:bg-[#216E4E]',
        yellow:
          'text-[#533F04] dark:text-[#F1DF98] bg-[#F5CD47] dark:bg-[#7F5F01]',
        orange:
          'text-[#702E00] dark:text-[#F9D7BE] bg-[#FEA362] dark:bg-[#A54800]',
        red: 'text-[#5D1F1A] dark:text-[#FBCCC9] bg-[#F87168] dark:bg-[#AE2E24]',
        blue: 'text-[#164555] dark:text-[#BEE6F5] bg-[#6CC3E0] dark:bg-[#206A83]',
        green:
          'text-[#37471F] dark:text-[#CCEAA0] bg-[#94C748] dark:bg-[#4C6B1F]',
        pink: 'text-[#50253F] dark:text-[#F8C8E6] bg-[#E774BB] dark:bg-[#943D73]',
        gray: 'text-[#091E42] dark:text-[#D8DDE4] bg-[#8590A2] dark:bg-[#596773]'
      }
    },
    defaultVariants: { color: 'turquoise' }
  })

  return (
    <span
      data-testid={label}
      title={`${label}`}
      aria-label={label}
      className={variants({
        className,
        color: color
      })}
    >
      {label}
    </span>
  )
}
