import { apiAthenas } from '@/services/api/apiAthenas'
import { Colors } from '@/types/Colors'

export interface SummaryBoards {
  id: string
  name: string
  columns: {
    id: string
    name: string
  }[]
  tags: {
    id: string
    name: string
    color: Colors
  }[]
}

export async function getSummaryBoards() {
  const { data } = await apiAthenas.get<SummaryBoards[]>('/boards')

  return data
}
