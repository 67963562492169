import * as CheckboxRadix from '@radix-ui/react-checkbox'

import { MdCheck as CheckIcon } from 'react-icons/md'
import { twMerge } from 'tailwind-merge'

type CheckboxProps = {
  title: string
  disabled?: boolean
  checked: boolean
  onCheckedChange: (checked: boolean | 'indeterminate') => void
}

export function Checkbox({
  title,
  checked,
  onCheckedChange,
  disabled
}: CheckboxProps) {
  return (
    <CheckboxRadix.Root
      title={title}
      disabled={disabled}
      checked={checked}
      onCheckedChange={onCheckedChange}
      className={twMerge(
        'flex size-4 items-center justify-center rounded-sm border-2 p-1.5',
        'border-cromai-m3-sys-light-on-background dark:border-cromai-m3-sys-dark-on-background'
      )}
    >
      <CheckboxRadix.Indicator>
        <CheckIcon />
      </CheckboxRadix.Indicator>
    </CheckboxRadix.Root>
  )
}
