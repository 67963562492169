import { useMemo, useState } from 'react'

import * as Select from '@radix-ui/react-dropdown-menu'

import { MdArrowDropDown } from 'react-icons/md'
import { twMerge } from 'tailwind-merge'
import { Item } from './Item'
import { Checkbox } from '../Checkbox'
import { Input } from '../Input'

export type Option = { id: string; label: string }

type MultiSelectProps = {
  placeholder: string
  onChange: (value: Option['id'][]) => void
  value: Option['id'][]
  options: Option[]
  search?: boolean
}

export function MultiSelect({
  placeholder,
  options,
  onChange,
  value,
  search
}: MultiSelectProps) {
  const [filter, setFilter] = useState<string>('')

  const optionsWithIsCheck = useMemo(
    () =>
      options.map((option) => {
        const opt = value?.find(
          (selectedOption) => selectedOption === option.id
        )

        return opt
          ? { ...option, isCheck: true }
          : { ...option, isCheck: false }
      }),
    [options, value]
  )

  const filteredItems = useMemo(
    () =>
      optionsWithIsCheck?.filter((option) =>
        option.label.toLowerCase().includes(filter.toLowerCase())
      ),
    [filter, optionsWithIsCheck]
  )

  function handleClick(item: Option) {
    const currentItem = value.find((option) => option === item.id)

    if (currentItem) {
      const newOptions = value.filter((option) => option !== item.id)
      return onChange(newOptions)
    }

    return onChange([...value, item.id])
  }

  const selecteds = options.filter((options) => {
    return value.find((id) => id === options.id)
  })

  return (
    <Select.Root>
      <Select.Trigger className="flex w-full items-center justify-between gap-2 rounded border border-cromai-m3-sys-light-outline px-4 py-3 text-cromai-m3-sys-light-on-surface-variant outline-none dark:border-cromai-m3-sys-dark-outline dark:text-cromai-m3-sys-dark-on-surface-variant">
        <div className="flex flex-wrap gap-1 font-mono">
          {!selecteds.length && (
            <span className="text-cromai-m3-sys-light-outline dark:text-cromai-m3-sys-dark-outline">
              {placeholder}
            </span>
          )}
          {selecteds.map((option) => (
            <Item key={option.id} label={option.label} />
          ))}
        </div>
        <span>
          <MdArrowDropDown size={24} />
        </span>
      </Select.Trigger>

      <Select.Portal>
        <Select.Content
          className={twMerge(
            'flex flex-col justify-center py-2',
            'mt-1 w-[26.2rem] rounded',
            'text-bodyMedium, font-mono',
            'bg-cromai-m3-sys-light-surface2 dark:bg-cromai-m3-sys-dark-surface2',
            'text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant',
            'shadow-lightElevation2 dark:shadow-darkElevation2'
          )}
          side={'bottom'}
          align={'center'}
        >
          {search && (
            <div className="bg-transparent px-3.5 py-1.5">
              <Input
                aria-label="search"
                className="h-8"
                placeholder="Pesquisar..."
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
          )}
          <div className="max-h-[calc(100vh-30rem)] overflow-auto">
            {filteredItems.map((item) => {
              return (
                <div
                  key={item.id}
                  onClick={() => handleClick(item)}
                  className={twMerge(
                    'flex cursor-pointer items-center gap-2 py-1.5 pl-3.5 outline-none',
                    'hover:bg-cromai-m3-sys-light-surface2-hover dark:hover:bg-cromai-m3-sys-dark-surface2-hover'
                  )}
                >
                  <Checkbox
                    title={item.label}
                    checked={item.isCheck}
                    onCheckedChange={() => {}}
                  />
                  {item.label}
                </div>
              )
            })}
          </div>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  )
}
