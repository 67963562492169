import * as DialogRadix from '@radix-ui/react-dialog'

import { Button, Dialog, Input } from '@/components'
import { useChangeDeadline } from '@/hooks/useChangeDeadline'
import { formatDate } from '@/utils/formatDate'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

interface RecoverDialogProps {
  demandId: string
  originalDeadline: string
  setIsOpen: (value: boolean) => void
  isOpen: boolean
}
const deadlineSchema = z.object({
  date: z.string()
})

type DeadlineSchema = z.infer<typeof deadlineSchema>

export function ChangeDeadlineDialog({
  isOpen,
  demandId,
  setIsOpen,
  originalDeadline
}: RecoverDialogProps) {
  const { changeDeadline } = useChangeDeadline({
    onSuccess: () => {
      setIsOpen(false)
    }
  })

  const { register, handleSubmit, setValue } = useForm<DeadlineSchema>({
    resolver: zodResolver(deadlineSchema)
  })

  const onSubmit = handleSubmit((data, event) => {
    event?.preventDefault()
    const date = new Date(data.date).toISOString()

    changeDeadline.mutate({ demandId, deliveryDate: date })
  })

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="flex w-[320px] flex-col p-6">
        <h1 className="mb-2 text-headlineSmall text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
          Alterar Prazo
        </h1>
        <span className="mb-5 font-mono text-bodyMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
          Informe o novo prazo para a demanda. O prazo original dessa demanda é{' '}
          {formatDate(originalDeadline)}
        </span>
        <form onSubmit={onSubmit}>
          <Input
            {...register('date', { required: true })}
            required
            label="Novo prazo"
            type="date"
            className="mb-5 rounded bg-transparent dark:[color-scheme:dark]"
          />
          <Button
            type="button"
            onClick={() => setValue('date', originalDeadline.slice(0, 10))}
            variant="outlined"
            className="w-min"
          >
            Usar prazo original
          </Button>

          <div className="mt-12 flex w-full justify-end gap-2">
            <DialogRadix.Close asChild>
              <Button type="button" variant="text">
                Cancelar
              </Button>
            </DialogRadix.Close>
            <Button
              type="submit"
              loading={changeDeadline.isLoading}
              variant="outlined"
              className="w-[5.5rem]"
            >
              Alterar
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  )
}
