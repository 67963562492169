import { useMutation, useQueryClient } from '@tanstack/react-query'
import { putReviewFinished } from '@/services/modules/athenas/demands'

import { makeGetDemandKey } from './useGetDemand'
import { Demand } from '@/types/demands'

import { handleToast } from '@/utils/handleToast'
import { useCallback } from 'react'

export function usePutReviewFinished() {
  const queryClient = useQueryClient()

  const putReviewFinishedFromCache = useCallback(
    (variables: { demandId: string; userId: number; isFinish: boolean }) => {
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(variables.demandId),
        (oldData) =>
          oldData
            ? {
                ...oldData,
                userList: oldData.userList.map((user) =>
                  user.userId === variables.userId
                    ? { ...user, isFinish: variables.isFinish }
                    : user
                )
              }
            : oldData
      )
    },
    [queryClient]
  )

  const mutation = useMutation({
    mutationFn: putReviewFinished,
    onSuccess: (_, variables) => putReviewFinishedFromCache(variables),
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível alterar o status. Tente novamente.'
      })
  })
  return {
    putReviewFinished: mutation
  }
}
