import { apiAthenas } from '@/services/api/apiAthenas'
import { Board } from '@/types/Board'

export type Deadline = 'today' | 'late' | 'tomorrow'
export type Other = 'qa' | 'mosaic'

export type GetBoardProps = {
  qa?: string
  mosaic?: string
  late?: string
  tomorrow?: string
  today?: string
  startDate?: string
  endDate?: string
  usersId?: number[]
  companiesId?: number[]
  others?: Other[]
}

export async function getBoard(id: string, props: GetBoardProps = {}) {
  const params = new URLSearchParams()

  props.usersId?.forEach((userId) => params.append('userId', userId.toString()))

  props.others?.forEach((other) => params.append(other, 'true'))
  props.companiesId?.forEach((companie) =>
    params.append('companyId', companie.toString())
  )

  props.startDate && params.append('startDate', String(props.startDate))
  props.endDate && params.append('endDate', String(props.endDate))

  props.qa && params.append('qa', String(props.qa))
  props.mosaic && params.append('mosaic', String(props.mosaic))

  props.late && params.append('late', String(props.late))
  props.today && params.append('today', String(props.today))
  props.tomorrow && params.append('tomorrow', String(props.tomorrow))

  const { data } = await apiAthenas.get<Board>(`/boards/${id}`, {
    params
  })

  return data
}
