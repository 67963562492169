import { Link, useLocation } from 'react-router-dom'

import { AccountMenu } from './AccountMenu'
import { MdMenu as MenuIcon, MdOutlineNotifications } from 'react-icons/md'

import { NavMenuItem } from '@/types/NavMenuItem'
import { isMenuActive } from '@/utils/isMenuActive'
import { twMerge } from 'tailwind-merge'

import { BoardsMenu } from './BoardsMenu'

const navMenu: NavMenuItem[] = [
  { name: 'Dashboard', href: '/dashboard', disabled: true },
  { name: 'Demandas', href: '/demands' },
  { name: 'Quadros', href: '/boards' },
  { name: 'Planejar', href: '/to-plan', disabled: true },
  { name: 'Acompanhar', href: '/to-accompany', disabled: true }
]

export function Appbar() {
  const { pathname } = useLocation()

  return (
    <header className="flex h-14 w-full items-center justify-center bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant">
      <div className="mx-8 flex size-full max-w-screen-xl items-center justify-between">
        <div className="lg:hidden">
          <MenuIcon
            size={24}
            className="text-cromai-m3-sys-light-on-surface-variant opacity-50 dark:text-cromai-m3-sys-dark-on-surface-variant"
          />
        </div>
        <div className="flex h-full flex-row items-center justify-center gap-14">
          <h1 className="whitespace-nowrap text-titleMedium text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
            Cromai Athenas
          </h1>

          <div className="hidden h-full flex-row gap-8 lg:flex">
            {navMenu.map((item) => {
              const styleActive = isMenuActive(item, pathname)
                ? twMerge(
                    'border-cromai-m3-sys-light-primary text-cromai-m3-sys-light-on-surface-variant',
                    'dark:border-cromai-m3-sys-dark-primary dark:text-cromai-m3-sys-dark-on-surface-variant'
                  )
                : twMerge(
                    'border-cromai-m3-sys-light-surface-variant text-cromai-m3-sys-light-outline',
                    'dark:border-cromai-m3-sys-dark-surface-variant dark:text-cromai-m3-sys-dark-outline'
                  )

              if (item.name === 'Quadros') {
                return <BoardsMenu styleActive={styleActive} key={item.name} />
              }

              return (
                <Link
                  aria-disabled={item.disabled}
                  key={item.name}
                  to={item.href}
                  className={twMerge(
                    'border-b-4 p-4',
                    'hover:border-cromai-m3-sys-light-primary hover:text-cromai-m3-sys-light-on-surface-variant',
                    'hover:dark:border-cromai-m3-sys-dark-primary hover:dark:text-cromai-m3-sys-dark-on-surface-variant',
                    'flex cursor-pointer items-center justify-center text-labelXLarge transition-all',
                    `
                    ${styleActive}
                    ${item.disabled && 'pointer-events-none opacity-50'}`
                  )}
                >
                  {item.name}
                </Link>
              )
            })}
          </div>
        </div>

        <div className="flex flex-row items-center gap-4">
          <MdOutlineNotifications
            size={24}
            className="cursor-not-allowed text-cromai-m3-sys-light-on-primary-container opacity-50 dark:text-cromai-m3-sys-dark-on-primary-container"
          />
          <AccountMenu />
        </div>
      </div>
    </header>
  )
}
