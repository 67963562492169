import { lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import routes from '@/routes'

import { Board } from '@/pages/Board'
import { DemandsDetails } from '@/pages/DemandDatails'
import { BoardSettings } from '@/pages/Board/BoardSettings'

import { DashboardLayout } from '@/layouts'
import { LoadingCircle } from '@/components'

const Demands = lazy(() => import('@/pages/Demands'))

export default function Private() {
  return (
    <Routes>
      <Route
        path={routes.demands}
        element={
          <DashboardLayout>
            <Suspense fallback={<LoadingCircle size={20} />}>
              <Demands />
            </Suspense>
          </DashboardLayout>
        }
      />
      <Route
        path={routes.demand}
        element={
          <Suspense fallback={<LoadingCircle size={20} />}>
            <DemandsDetails />
          </Suspense>
        }
      />
      <Route
        path={routes.frame}
        element={
          <DashboardLayout>
            <Suspense fallback={<LoadingCircle size={20} />}>
              <Board />
            </Suspense>
          </DashboardLayout>
        }
      />
      <Route
        path={routes.boardSettings}
        element={
          <DashboardLayout>
            <Suspense fallback={<LoadingCircle size={20} />}>
              <BoardSettings />
            </Suspense>
          </DashboardLayout>
        }
      />
      <Route path="*" element={<Navigate to={routes.demands} replace />} />
    </Routes>
  )
}
