import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  genereteUrlForUploadFiles,
  confirmUploadWeedFiles
} from '@/services/modules/athenas/demands'

import { makeGetDemandKey } from './useGetDemand'

import { Demand } from '@/types/demands'
import { FileType } from '@/types/fileTypes'
import { handleToast } from '@/utils/handleToast'

import JSzip from 'jszip'
import shp from 'shpjs'
import { produce } from 'immer'
import { useCallback } from 'react'
import { uploadRevisedWeedFilesOnStorage } from '@/services/modules/googleStorage'

interface MutationFnProps {
  demandId: string
  userId: number
  fileList: FileList
  fileType: FileType
}

export function useUploadWeedFiles() {
  const queryClient = useQueryClient()

  const updateWeedFilesFromCache = useCallback(
    (variables: MutationFnProps) =>
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(variables.demandId),
        produce((draft) => {
          if (!draft) return

          const index = draft.userList.findIndex(
            (user) => user.userId === variables.userId
          )
          if (index !== -1) {
            const fileNameList = Array.from(variables.fileList).map((file) => {
              return file.name
            })
            draft.userList[index].files.weed![variables.fileType] = [
              ...fileNameList
            ]
          }
        })
      ),
    [queryClient]
  )

  const mutation = useMutation({
    mutationFn: async ({
      demandId,
      userId,
      fileType,
      fileList
    }: MutationFnProps) => {
      const zip = new JSzip()

      const fileNameList = Array.from(fileList).map((file) => {
        return file.name
      })

      const fileName = fileNameList.at(0)!.slice(0, -4)

      const url = await genereteUrlForUploadFiles(demandId, userId, fileType)

      Array.from(fileList).map((file) => {
        zip.file(file.name, file)
      })

      const zipBlob = await zip.generateAsync({ type: 'uint8array' })

      await shp.parseZip(zipBlob)

      await uploadRevisedWeedFilesOnStorage(
        zipBlob,
        url,
        demandId,
        userId,
        fileName,
        fileType
      )

      await confirmUploadWeedFiles(demandId, userId, fileType, fileNameList)
    },
    cacheTime: 0,
    onSuccess: (_, variables) => {
      updateWeedFilesFromCache(variables)
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não possível inserir/alterar os resultados. Tente novamente'
      })
  })
  return mutation
}
