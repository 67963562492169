import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/providers'
import { produce } from 'immer'

import { handleToast } from '@/utils/handleToast'
import { SummaryBoards } from '@/services/modules/athenas/boards/get-summary-boards'
import { GET_SUMMARY_BOARDS_KEY } from './useGetSummaryBoards'
import { moveColumn } from '@/services/modules/athenas/boards/move-column'

interface UseMoveColumnProps {
  boardId: string
  columnId: string
  columnIndex: number
  newIndex: number
}

export function useMoveColumn() {
  const moveColumnOnCache = useCallback(
    ({ boardId, columnIndex, newIndex }: UseMoveColumnProps) =>
      queryClient.setQueryData<[SummaryBoards]>(
        [GET_SUMMARY_BOARDS_KEY],
        produce((draft) => {
          if (!draft) return
          const bordIndex = draft.findIndex((bord) => bord.id === boardId)

          const { columns } = draft[bordIndex]

          const [item] = columns.splice(columnIndex, 1)

          columns.splice(newIndex, 0, item)
        })
      ),
    []
  )

  const mutation = useMutation({
    mutationFn: async ({
      boardId,
      columnId,
      columnIndex,
      newIndex
    }: UseMoveColumnProps) => {
      moveColumnOnCache({
        boardId,
        columnIndex,
        columnId,
        newIndex
      })
      const boards = queryClient.getQueryData<SummaryBoards[]>([
        GET_SUMMARY_BOARDS_KEY
      ])

      const board = boards?.find((board) => board.id === boardId)

      const orderedListOfColumnIds = board!.columns.map((column) => column.id)

      await moveColumn({ boardId, orderedListOfColumnIds })
    },
    onError: () => {
      queryClient.invalidateQueries([GET_SUMMARY_BOARDS_KEY])
      handleToast({
        message:
          'Erro ao mover a coluna. Atualize a página e tente mover novamente.',
        type: 'error'
      })
    }
  })

  return mutation
}
