import { useDebounce } from 'use-debounce'

import { useQuery } from '@tanstack/react-query'

import { handleToast } from '@/utils/handleToast'

import { queryClient } from '@/providers'
import { produce } from 'immer'
import { useCallback } from 'react'
import { GET_SUMMARY_BOARDS_KEY } from './useGetSummaryBoards'
import { SummaryBoards } from '@/services/modules/athenas/boards/get-summary-boards'
import { updateColumnName } from '@/services/modules/athenas/boards/update-column-name'

interface UseUpdatePartNumberProps {
  boardId: string
  columnName: string
  columnId: string
  columnIndex: number
}

export function useUpdateColumnName({
  boardId,
  columnName,
  columnId,
  columnIndex
}: UseUpdatePartNumberProps) {
  const [debounceColumnName] = useDebounce(columnName, 2000)

  const updateColumnNameFromCache = useCallback(
    () =>
      queryClient.setQueryData<SummaryBoards[]>(
        [GET_SUMMARY_BOARDS_KEY],
        produce((draft) => {
          if (!draft) return

          const boardIndex = draft.findIndex((board) => board.id === boardId)

          draft[boardIndex].columns[columnIndex].name = debounceColumnName
        })
      ),
    [boardId, columnIndex, debounceColumnName]
  )

  const query = useQuery({
    queryKey: ['UPDATE_COLUMN_NAME', { boardId, columnId }, debounceColumnName],
    queryFn: () => updateColumnName({ boardId, columnId, columnName }),
    onSuccess: updateColumnNameFromCache,
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível alterar o nome da coluna. Tente novamente'
      }),
    enabled: !!debounceColumnName
  })
  return query
}
