import { useMutation, useQueryClient } from '@tanstack/react-query'

import { makeGetDemandKey } from './useGetDemand'
import { Demand } from '@/types/demands'

import { deleteRevisionTime } from '@/services/modules/athenas/demands'
import { handleToast } from '@/utils/handleToast'
import { produce } from 'immer'
import { useCallback } from 'react'

export function useDeleteRevisionTime() {
  const queryClient = useQueryClient()

  const deleteRevisionTimeFromCache = useCallback(
    (variables: { demandId: string; userId: number }) =>
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(variables.demandId),
        produce((draft) => {
          if (!draft) return

          const index = draft.userList.findIndex(
            (user) => user.userId === variables.userId
          )
          if (index !== -1) draft.userList[index].revisionTime = ''
        })
      ),
    [queryClient]
  )

  const mutation = useMutation({
    mutationFn: deleteRevisionTime,
    cacheTime: 0,
    onSuccess: (_, variables) => {
      deleteRevisionTimeFromCache(variables)
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível remover o tempo de revisão. Tente novamente.'
      })
  })
  return {
    deleteRevisionTime: mutation.mutate,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError
  }
}
