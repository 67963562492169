import { useDebounce } from 'use-debounce'

import { useQuery } from '@tanstack/react-query'

import { handleToast } from '@/utils/handleToast'

import { queryClient } from '@/providers'
import { produce } from 'immer'
import { useCallback } from 'react'
import { SummaryBoards } from '@/services/modules/athenas/boards/get-summary-boards'
import { GET_SUMMARY_BOARDS_KEY } from '../useGetSummaryBoards'

import { Colors } from '@/types/Colors'

import { updateTagColor } from '@/services/modules/athenas/boards/update-tag-color'

interface UseUpdateTagProps {
  tagIndex: number
  boardId: string
  tagId: string
  color: Colors
}

export function useUpdateTagColor({
  tagIndex,
  boardId,
  tagId,
  color
}: UseUpdateTagProps) {
  const [debounceColor] = useDebounce(color, 500)

  const updateTagColorFromCache = useCallback(
    () =>
      queryClient.setQueryData<SummaryBoards[]>(
        [GET_SUMMARY_BOARDS_KEY],
        produce((draft) => {
          if (!draft) return

          const boardIndex = draft.findIndex((board) => board.id === boardId)

          draft[boardIndex].tags[tagIndex].color = debounceColor
        })
      ),
    [boardId, debounceColor, tagIndex]
  )

  const query = useQuery({
    queryKey: ['UPDATE_TAG_COLOR', { boardId, tagId }, debounceColor],
    queryFn: () => updateTagColor({ boardId, tagId, color }),
    onSuccess: updateTagColorFromCache,
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível alterar a cor da etiqueta. Tente novamente'
      }),
    enabled: !!debounceColor
  })
  return query
}
