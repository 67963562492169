import { getCompanies } from '@/services/modules/athenas/companies/get-companies'
import { useQuery } from '@tanstack/react-query'

export function useGetCompanies() {
  const query = useQuery({
    queryKey: ['companies'],
    queryFn: ({ signal }) => getCompanies(signal),
    cacheTime: 8 * 60 * 60 * 1000, // 8 hours
    staleTime: 7 * 60 * 60 * 1000 // 7 hours
  })

  return query
}
