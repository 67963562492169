import { PropsWithChildren } from 'react'
import { Appbar } from '@/components'

export function DashboardLayout({ children }: PropsWithChildren) {
  return (
    <div className="relative flex flex-col items-center justify-center bg-cromai-m3-sys-light-background dark:bg-cromai-m3-sys-dark-background">
      <Appbar />
      {children}
    </div>
  )
}
