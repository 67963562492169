import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { isValidEmail } from '@/utils/isValidEmail'
import { handleToast } from '@/utils/handleToast'
import { Button, Input } from '@/components'

import { MdArrowBack } from 'react-icons/md'
import Email from '../../assets/email.svg'

import { changePasswordByEmail } from '@/services/modules/atlas/users/users'
import { env } from '@/settings/env'

export default function Recovery() {
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState('')

  async function handleEmail() {
    if (!isValidEmail(email)) {
      return handleToast({
        message: 'Formato de e-mail inválido.',
        type: 'error'
      })
    }
    try {
      setIsLoading(true)
      const response = await changePasswordByEmail(email.trim())
      if (response.status === 200) {
        setSuccess(true)
      }
    } catch (error) {
      return handleToast({
        message: 'Erro interno!',
        type: 'error'
      })
    }
  }

  return (
    <main className="flex h-screen items-center justify-center">
      <section className="relative flex w-[452px] flex-col items-center justify-center rounded bg-cromai-m3-sys-light-surface px-8 py-[82px] drop-shadow-bottom dark:bg-cromai-m3-sys-dark-surface">
        {success ? (
          <>
            <div className="flex w-full flex-col gap-12">
              <div className="flex flex-col gap-6 text-center text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
                <h1 className="text-headlineMedium">Cromai Athenas</h1>
                <div className="">
                  <p className="mb-4 font-mono text-bodyMedium">
                    Caso o endereço esteja cadastrado, você receberá um e-mail
                    para redefinir sua senha.
                  </p>
                  <span className="font-mono text-labelLarge text-cromai-m3-sys-light-on-tertiary-container dark:text-cromai-m3-sys-dark-on-tertiary-container">
                    {email}
                  </span>
                </div>
                <img
                  className="m-auto h-[5.3125rem] w-[6.625rem]"
                  src={Email}
                  alt="Imagem de um envelope representando um email"
                />
              </div>
              <NavLink to="/">
                <Button variant="outlined" className="w-full">
                  Voltar ao início
                </Button>
              </NavLink>
            </div>
          </>
        ) : (
          <>
            <NavLink to="/" title="Voltar">
              <MdArrowBack
                className="absolute left-9 top-9 text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface"
                size={24}
              />
            </NavLink>

            <div className="flex w-full flex-col gap-12">
              <div className="flex flex-col gap-6 text-center text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
                <h1 className="text-headlineMedium">Cromai Athenas</h1>
                <p className="text-bodyMedium">Entre com sua Conta Cromai.</p>
              </div>

              <div className=" flex flex-col gap-4">
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  label="Email"
                />
              </div>
              <Button disabled={isLoading} onClick={handleEmail}>
                Enviar instruções de redefinição
              </Button>
            </div>
          </>
        )}
        <span className="absolute bottom-4 text-bodyMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
          Versão {env.SOFTWARE_VERSION}
        </span>
      </section>
    </main>
  )
}
