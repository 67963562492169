import { Button, LoadingCircle } from '@/components'
import { useState } from 'react'
import {
  MdOutlineArrowDownward,
  MdOutlineArrowUpward,
  MdOutlineDelete
} from 'react-icons/md'

import { DeleteTagDialog } from './DeleteTagDialog'
import { TagColorsMenu } from '../TagColorsMenu'
import { Colors } from '@/types/Colors'
import { useUpdateTagName } from '@/hooks/boards/queries/useUpdateTagName'
import { useUpdateTagColor } from '@/hooks/boards/queries/useUpdateTagColor'
import { useMoveTag } from '@/hooks/boards/mutations/useMoveTag'
import { twMerge } from '@/styles/tailwindMergeConfig'
import { Tag } from '@/components/Tag'
import { AutoResizableTextarea } from './AutoResizableTextarea'

interface TagConfigProps {
  boardId: string
  tagId: string
  tagIndex: number
  tagName: string
  tagColor: Colors
  indexLastItem: number
}
export function TagConfig({
  boardId,
  tagIndex,
  tagColor,
  tagName,
  tagId,
  indexLastItem
}: TagConfigProps) {
  const [newTagName, setNewTagName] = useState('')
  const [newTagColor, setNewTagColor] = useState<Colors>()

  const { isFetching: isFetchingName } = useUpdateTagName({
    tagId,
    boardId,
    tagIndex,
    name: newTagName
  })
  const { isFetching: isFetchingColor } = useUpdateTagColor({
    tagId,
    boardId,
    tagIndex,
    color: newTagColor!
  })

  const { mutate: moveColumn } = useMoveTag()

  const [isOpenDeleteColumnDialog, setIsOpenDeleteColumnDialog] =
    useState(false)

  return (
    <div className="mt-4 flex items-center gap-4">
      <AutoResizableTextarea
        aria-label={tagName}
        defaultValue={tagName}
        newValue={newTagName}
        onChange={(e) => setNewTagName(e.target.value)}
        className="w-52"
      >
        <TagColorsMenu
          color={tagColor}
          setColor={(value) => setNewTagColor(value)}
        />
      </AutoResizableTextarea>

      <div
        className={twMerge(
          'flex h-9 w-[12.25rem] items-center rounded px-2 py-2.5',
          'bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant'
        )}
      >
        <Tag data-testid={tagName} label={tagName} color={tagColor} />
      </div>

      <Button
        title="Mover para baixo"
        className="w-10 p-0 text-black dark:text-white"
        variant="text"
        disabled={tagIndex === indexLastItem}
        onClick={() =>
          moveColumn({
            boardId,
            newIndex: tagIndex + 1,
            tagId,
            tagIndex
          })
        }
      >
        <MdOutlineArrowDownward size={18} />
      </Button>
      <Button
        title="Mover para cima"
        className="w-10 p-0 text-black dark:text-white"
        variant="text"
        disabled={tagIndex === 0}
        onClick={() =>
          moveColumn({
            boardId,
            newIndex: tagIndex - 1,
            tagId,
            tagIndex
          })
        }
      >
        <MdOutlineArrowUpward size={18} />
      </Button>
      <Button
        title="Remover coluna"
        className="w-10 p-0 text-black dark:text-white"
        variant="outlined"
        onClick={() => setIsOpenDeleteColumnDialog(true)}
      >
        <MdOutlineDelete size={18} />
      </Button>

      {(isFetchingName || isFetchingColor) && <LoadingCircle size={12} />}
      <DeleteTagDialog
        isOpen={isOpenDeleteColumnDialog}
        setIsOpen={setIsOpenDeleteColumnDialog}
        tagName={tagName}
        boardId={boardId}
        tagId={tagId}
      />
    </div>
  )
}
