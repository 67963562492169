import { twMerge } from '@/styles/tailwindMergeConfig'
import { WithClassNameProps } from '@/types'

import { LuLoader2 as LoadIcon } from 'react-icons/lu'
type LoaderCircleProps = WithClassNameProps & {
  size: number
}
export function LoadingCircle({ size, className }: LoaderCircleProps) {
  return (
    <LoadIcon
      role="progressbar"
      className={twMerge(
        className,
        'animate-spin text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface'
      )}
      size={size}
    />
  )
}
