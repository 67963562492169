import ScrollContainer from 'react-indiana-drag-scroll'

import { differenceInHours } from 'date-fns'
import { useGetBoard } from '@/hooks/boards/useGetBoard'

import { MdFunctions, MdNumbers } from 'react-icons/md'
import { BoardSkeletons } from './BoardSkeletons'
import { Column } from './Column'

import { useGetTotalArea } from './hooks/UseGetTotalArea'
import { percentage } from '@/utils/percentage'
import { Filters } from '@/components'

export function Board() {
  const { query: getBoard } = useGetBoard()
  const { getTotalArea } = useGetTotalArea()

  if (getBoard.isLoading) {
    return <BoardSkeletons loading={getBoard.isLoading} />
  }

  if (getBoard.isError) {
    return <BoardSkeletons />
  }

  const allCards = getBoard.data.columns.flatMap((column) => column.cards)

  const allCardsNotConclued = allCards.filter(
    (card) => card.status.value !== 'concluded'
  )

  const totalBoardArea = getTotalArea(allCardsNotConclued)

  const columns = getBoard.data.columns.map((column) => {
    const filteredCards = column.cards.filter((card) => {
      const isConclued = card.status.value === 'concluded'
      const isConcludedAt = card.concludedAt

      return isConclued && isConcludedAt
        ? differenceInHours(new Date(), isConcludedAt) < 24
        : true
    })

    return { ...column, cards: filteredCards }
  })

  return (
    <main className="h-[calc(100vh-3.5rem)] pt-4">
      <header className="flex w-full items-center justify-center">
        <div className="mx-8 size-full max-w-screen-xl items-center gap-8 overflow-hidden whitespace-nowrap lg:flex">
          <h1 className="text-start text-headlineSmall dark:text-cromai-m3-sys-dark-on-surface">
            {getBoard.data.name}
          </h1>
          <div className="flex flex-1 gap-8 text-labelLarge dark:text-cromai-m3-sys-dark-on-background">
            <span className="flex items-center gap-1">
              <MdNumbers size={16} />
              {allCardsNotConclued.length}
            </span>
            <span className="flex items-center gap-1">
              <MdFunctions size={16} />
              {getTotalArea(allCardsNotConclued)} ha
            </span>
            <Filters />
          </div>
        </div>
      </header>

      <ScrollContainer
        hideScrollbars={false}
        horizontal
        className="mt-4 flex w-screen gap-8 px-6 "
      >
        {columns.map((column, index) => {
          const totalColumnArea = getTotalArea(column.cards)

          const areaPercentage = percentage(totalColumnArea, totalBoardArea)

          return (
            <Column
              key={column.id}
              {...column}
              columnIndex={index}
              percentage={areaPercentage}
              totalArea={totalColumnArea}
            />
          )
        })}
      </ScrollContainer>
    </main>
  )
}
