import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/providers'
import { produce } from 'immer'

import { handleToast } from '@/utils/handleToast'
import { SummaryBoards } from '@/services/modules/athenas/boards/get-summary-boards'
import { deleteColumn } from '@/services/modules/athenas/boards/delete-column'
import { GET_SUMMARY_BOARDS_KEY } from './useGetSummaryBoards'

interface UseDeleteColumnProps {
  boardId: string
  columnId: string
}

export function useDeleteColumn() {
  const deleteColumnFromCache = useCallback(
    ({ boardId, columnId }: UseDeleteColumnProps) =>
      queryClient.setQueryData<SummaryBoards[]>(
        [GET_SUMMARY_BOARDS_KEY],
        produce((draft) => {
          if (!draft) return

          const boardIndex = draft.findIndex((board) => board.id === boardId)

          const columnsFiltered = draft[boardIndex].columns.filter(
            (column) => column.id !== columnId
          )

          draft[boardIndex].columns = columnsFiltered
        })
      ),
    []
  )

  const mutation = useMutation({
    mutationFn: async ({ boardId, columnId }: UseDeleteColumnProps) => {
      deleteColumnFromCache({
        boardId,
        columnId
      })
      await deleteColumn({
        boardId,
        columnId
      })
    },

    onError: () => {
      queryClient.invalidateQueries([GET_SUMMARY_BOARDS_KEY])
      handleToast({
        message:
          'Erro ao excluir a coluna. Atualize a página e tente excluir novamente.',
        type: 'error'
      })
    }
  })

  return mutation
}
