import { apiAthenas } from '@/services/api/apiAthenas'

export type GetCompaniesResponse = {
  id: number
  name: string
}

export async function getCompanies(signal?: AbortSignal) {
  const { data } = await apiAthenas.get<GetCompaniesResponse[]>('/companies', {
    signal
  })
  return data
}
