import { useMutation, useQueryClient } from '@tanstack/react-query'

import { makeGetDemandKey } from './useGetDemand'

import { Demand } from '@/types/demands'
import { FileType } from '@/types/fileTypes'
import { handleToast } from '@/utils/handleToast'

import JSzip from 'jszip'
import shp from 'shpjs'
import { produce } from 'immer'
import { useCallback } from 'react'
import {
  getUrlForUploadGrowLinesFiles,
  successUploadGrowLinesFiles
} from '@/services/modules/athenas/growLines'
import { uploadRevisedGrowLinesFilesOnStorage } from '@/services/modules/googleStorage'

interface MutationFnProps {
  demandId: string
  userId: number
  fileList: FileList
  fileType: FileType
  partId: string
}

export function useUploadGrowLinesFiles() {
  const queryClient = useQueryClient()

  const uploadGrowLinesFilesFromCache = useCallback(
    (variables: MutationFnProps) =>
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(variables.demandId),
        produce((draft) => {
          if (!draft) return

          const index = draft.userList.findIndex(
            (user) => user.userId === variables.userId
          )
          if (index !== -1) {
            const fileNameList = Array.from(variables.fileList).map((file) => {
              return file.name
            })
            const partIndex = draft.userList[index].files.grow_lines?.findIndex(
              (part) => part.partId === variables.partId
            )
            draft.userList[index].files.grow_lines![partIndex!].files[
              variables.fileType
            ] = [...fileNameList]
          }
        })
      ),
    [queryClient]
  )

  const mutation = useMutation({
    mutationFn: async ({
      demandId,
      userId,
      fileType,
      fileList,
      partId
    }: MutationFnProps) => {
      const zip = new JSzip()

      const fileNameList = Array.from(fileList).map((file) => {
        return file.name
      })

      const fileName = fileNameList.at(0)!.slice(0, -4)

      const url = await getUrlForUploadGrowLinesFiles(
        demandId,
        userId,
        fileType,
        partId
      )

      Array.from(fileList).map((file) => {
        zip.file(file.name, file)
      })

      const zipBlob = await zip.generateAsync({ type: 'uint8array' })

      await shp.parseZip(zipBlob)

      await uploadRevisedGrowLinesFilesOnStorage(
        zipBlob,
        url,
        demandId,
        userId,
        fileName,
        fileType,
        partId
      )

      await successUploadGrowLinesFiles(
        demandId,
        userId,
        fileType,
        fileNameList,
        partId
      )
    },
    cacheTime: 0,
    onSuccess: (_, variables) => {
      uploadGrowLinesFilesFromCache(variables)
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não possível inserir/alterar os resultados. Tente novamente'
      })
  })
  return mutation
}
