import { customTv } from '@/styles/tailwindMergeConfig'
import { MdLabel } from 'react-icons/md'

type TagColorProps = {
  className?: string
  size: number
  color:
    | 'turquoise'
    | 'yellow'
    | 'orange'
    | 'red'
    | 'blue'
    | 'green'
    | 'pink'
    | 'gray'
}

const translate = {
  turquoise: 'Turquesa',
  yellow: 'Amarelo',
  orange: 'Laranja',
  red: 'Vermelho',
  blue: 'Azul',
  green: 'Verde',
  pink: 'Rosa',
  gray: 'Cinza'
}

export function TagColor({ size, color, className }: TagColorProps) {
  const variants = customTv({
    variants: {
      color: {
        turquoise: 'text-[#4BCE97] dark:text-[#216E4E]',
        yellow: 'text-[#F5CD47] dark:text-[#7F5F01]',
        orange: 'text-[#FEA362] dark:text-[#A54800]',
        red: 'text-[#F87168] dark:text-[#AE2E24]',
        blue: 'text-[#6CC3E0] dark:text-[#206A83]',
        green: 'text-[#94C748] dark:text-[#4C6B1F]',
        pink: 'text-[#E774BB] dark:text-[#943D73]',
        gray: 'text-[#8590A2] dark:text-[#596773]'
      }
    },
    defaultVariants: { size: 24, color: 'turquoise' }
  })

  return (
    <figure
      title={translate[color]}
      className={variants({
        color: color,
        className
      })}
    >
      <MdLabel size={size} />
    </figure>
  )
}
