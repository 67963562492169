import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { produce } from 'immer'

import { makeGetDemandKey } from '@/hooks/useGetDemand'
import { updateIsQAOnDemand } from '@/services/modules/athenas/demands/update-is-qa-on-demand'

import { handleToast } from '@/utils/handleToast'
import { Demand } from '@/types/demands'

type UseUpdateIsQAOnDemandProps = {
  demandId: string
  isQA: boolean
}

export function useUpdateIsQAOnDemand() {
  const queryClient = useQueryClient()

  const updateIsQAOnDemanFromCache = useCallback(
    (variables: { demandId: string; isQA: boolean }) =>
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(variables.demandId),
        produce((draft) => {
          if (!draft) return

          draft.isQA = variables.isQA as any
        })
      ),
    [queryClient]
  )

  const mutation = useMutation({
    mutationFn: async ({ demandId, isQA }: UseUpdateIsQAOnDemandProps) => {
      updateIsQAOnDemanFromCache({ demandId, isQA })

      await updateIsQAOnDemand({ demandId, isQA })
    },
    onSuccess: (_, variables) =>
      queryClient.invalidateQueries(makeGetDemandKey(variables.demandId)),
    onError: (_, variables) => {
      queryClient.invalidateQueries(makeGetDemandKey(variables.demandId))
      handleToast({
        type: 'error',
        message: `Erro ao ${variables.isQA ? 'adicionar' : 'remover'} o checklist de QA. Atualize a página e tente novamente.`
      })
    }
  })

  return mutation
}
