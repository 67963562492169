import * as AvatarRadix from '@radix-ui/react-avatar'
import { twMerge } from '@/styles/tailwindMergeConfig'

type AvatarProps = {
  name: string
  url?: string
  size?: 'small' | 'medium'
}

const sizes = {
  small: 'h-6 w-6 text-labelSmall',
  medium: 'h-10 w-10 text-labelLarge'
}

export function Avatar({ name, url, size = 'medium' }: AvatarProps) {
  function getUserNameInitials(fullName: string) {
    const splitName = fullName.split(' ')
    const firstName = splitName.at(0)
    const lastName = splitName.at(-1)
    const initials = [firstName, lastName].map((name) =>
      name?.at(0)?.toLocaleUpperCase()
    )

    return initials.join('')
  }

  return (
    <AvatarRadix.Root className="">
      <AvatarRadix.Image
        title={name}
        className={twMerge(
          `${sizes[size]}`,
          'flex items-center overflow-hidden rounded-full object-cover font-mono'
        )}
        src={url}
      />
      <AvatarRadix.Fallback
        title={name}
        className={twMerge(
          `${sizes[size]}`,
          'flex items-center justify-center rounded-full font-mono',
          'bg-cromai-m3-sys-light-primary  text-cromai-m3-sys-light-on-primary',
          'dark:bg-cromai-m3-sys-dark-primary dark:text-cromai-m3-sys-dark-on-primary'
        )}
      >
        {getUserNameInitials(name.trim())}
      </AvatarRadix.Fallback>
    </AvatarRadix.Root>
  )
}
