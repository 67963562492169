import { User } from '@/context/auth'
import { GetAccess } from '@/services/modules/atlas/authentication/access/GetAccess'
import {
  AuthRequest,
  PostAuth
} from '@/services/modules/atlas/authentication/auth/PostAuth'
import { useMutation } from '@tanstack/react-query'

interface UseSignInProps {
  onSuccess: (data: { user: User; token: string; accessToken: string }) => void
  onError: () => void
}

export function useSignIn({ onError, onSuccess }: UseSignInProps) {
  const mutation = useMutation({
    mutationFn: async (data: AuthRequest) => {
      const response = await PostAuth(data)

      const claims = response.data.token.split('.')[1]

      const user = JSON.parse(atob(claims)) as User

      const accessToken = await GetAccess(response)

      return {
        user,
        token: response.data.token,
        accessToken: accessToken.data.token
      }
    },
    cacheTime: 0,
    onSuccess: onSuccess,
    onError: onError
  })

  return mutation
}
