import { Button, Input, LoadingCircle } from '@/components'
import { useMoveColumn } from '@/hooks/boards/useMoveColumn'
import { useUpdateColumnName } from '@/hooks/boards/useUpdateColumnName'
import { useState } from 'react'
import {
  MdOutlineArrowDownward,
  MdOutlineArrowUpward,
  MdOutlineDelete
} from 'react-icons/md'
import { DeleteColumnDialog } from './DeleteColumnDialog'

interface ColumnConfigProps {
  boardId: string
  columnId: string
  columnIndex: number
  columnName: string
  indexLastItem: number
}
export function ColumnConfig({
  boardId,
  columnId,
  columnIndex,
  columnName,
  indexLastItem
}: ColumnConfigProps) {
  const [isOpenDeleteColumnDialog, setIsOpenDeleteColumnDialog] =
    useState(false)
  const [newColumnName, setNewColumnName] = useState('')

  const query = useUpdateColumnName({
    boardId,
    columnId,
    columnIndex,
    columnName: newColumnName
  })
  const { mutate: moveColumn } = useMoveColumn()

  return (
    <div className="mt-4 flex items-center gap-4">
      <Input
        title="Nome da coluna"
        type="text"
        defaultValue={columnName}
        onChange={(event) => setNewColumnName(event.target.value)}
        className="w-52"
      />
      <Button
        title="Mover para baixo"
        className="w-10 p-0 text-black dark:text-white"
        variant="text"
        disabled={columnIndex === indexLastItem}
        onClick={() =>
          moveColumn({
            boardId,
            columnId,
            columnIndex,
            newIndex: columnIndex + 1
          })
        }
      >
        <MdOutlineArrowDownward size={18} />
      </Button>
      <Button
        title="Mover para cima"
        className="w-10 p-0 text-black dark:text-white"
        variant="text"
        disabled={columnIndex === 0}
        onClick={() =>
          moveColumn({
            boardId,
            columnId,
            columnIndex,
            newIndex: columnIndex - 1
          })
        }
      >
        <MdOutlineArrowUpward size={18} />
      </Button>
      <Button
        title="Remover coluna"
        className="w-10 p-0 text-black dark:text-white"
        variant="outlined"
        onClick={() => setIsOpenDeleteColumnDialog(true)}
      >
        <MdOutlineDelete size={18} />
      </Button>

      {query.isFetching && <LoadingCircle size={12} />}
      <DeleteColumnDialog
        isOpen={isOpenDeleteColumnDialog}
        setIsOpen={setIsOpenDeleteColumnDialog}
        columnName={columnName}
        boardId={boardId}
        columnId={columnId}
      />
    </div>
  )
}
