import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/providers'
import { produce } from 'immer'

import { handleToast } from '@/utils/handleToast'
import { SummaryBoards } from '@/services/modules/athenas/boards/get-summary-boards'
import { moveTag } from '@/services/modules/athenas/boards/move-tag'
import { GET_SUMMARY_BOARDS_KEY } from '../useGetSummaryBoards'

interface UseMoveTagProps {
  boardId: string
  tagId: string
  tagIndex: number
  newIndex: number
}

export function useMoveTag() {
  const moveTagOnCache = useCallback(
    ({ boardId, tagIndex, newIndex }: UseMoveTagProps) =>
      queryClient.setQueryData<[SummaryBoards]>(
        [GET_SUMMARY_BOARDS_KEY],
        produce((draft) => {
          if (!draft) return

          const boardIndex = draft.findIndex((bord) => bord.id === boardId)

          const { tags } = draft[boardIndex]

          const [item] = tags.splice(tagIndex, 1)

          tags.splice(newIndex, 0, item)
        })
      ),
    []
  )

  const mutation = useMutation({
    mutationFn: async ({
      boardId,
      tagId,
      tagIndex,
      newIndex
    }: UseMoveTagProps) => {
      moveTagOnCache({
        boardId,
        tagIndex,
        tagId,
        newIndex
      })
      const boards = queryClient.getQueryData<SummaryBoards[]>([
        GET_SUMMARY_BOARDS_KEY
      ])

      const board = boards?.find((board) => board.id === boardId)

      const organizedTagsId = board!.tags.map((tag) => tag.id)

      await moveTag({ boardId, organizedTagsId })
    },
    onError: () => {
      queryClient.invalidateQueries([GET_SUMMARY_BOARDS_KEY])
      handleToast({
        message:
          'Erro ao mover etiqueta. Atualize a página e tente mover novamente.',
        type: 'error'
      })
    }
  })

  return mutation
}
