import { useDebounce } from 'use-debounce'

import { useQuery } from '@tanstack/react-query'

import { handleToast } from '@/utils/handleToast'

import { queryClient } from '@/providers'
import { produce } from 'immer'
import { useCallback } from 'react'
import { SummaryBoards } from '@/services/modules/athenas/boards/get-summary-boards'
import { GET_SUMMARY_BOARDS_KEY } from '../useGetSummaryBoards'

import { updateTagName } from '@/services/modules/athenas/boards/update-tag-name'

interface UseUpdateTagProps {
  tagIndex: number
  boardId: string
  tagId: string
  name: string
}

export function useUpdateTagName({
  tagIndex,
  boardId,
  tagId,
  name
}: UseUpdateTagProps) {
  const [debounceName] = useDebounce(name, 2000)

  const updateTagFromCache = useCallback(
    () =>
      queryClient.setQueryData<SummaryBoards[]>(
        [GET_SUMMARY_BOARDS_KEY],
        produce((draft) => {
          if (!draft) return

          const boardIndex = draft.findIndex((board) => board.id === boardId)

          draft[boardIndex].tags[tagIndex].name = debounceName
        })
      ),
    [boardId, debounceName, tagIndex]
  )

  const query = useQuery({
    queryKey: ['UPDATE_TAG_NAME', { boardId, tagId }, debounceName],
    queryFn: () => updateTagName({ boardId, tagId, name }),
    onSuccess: updateTagFromCache,
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível alterar o nome da etiqueta. Tente novamente'
      }),
    enabled: !!debounceName
  })
  return query
}
