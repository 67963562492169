import { Reviewers } from './ValidationTypes'

export function useAllFilesPerReviewers(reviewers: Reviewers[]) {
  return reviewers.flatMap((reviewer) => {
    const parts = reviewer.files.map((part) => {
      const { perimeter, reconstruction_lines, planting_failure } = part.files

      const files = [
        ...(perimeter || []),
        ...(reconstruction_lines || []),
        ...(planting_failure || [])
      ]

      return { partNumber: part.partNumber, files }
    })

    return { reviewerName: reviewer.userName, parts }
  })
}
