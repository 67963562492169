import * as ContextMenu from '@radix-ui/react-context-menu'

import { Avatar, DeadlineTag } from '@/components'
import { twMerge } from '@/styles/tailwindMergeConfig'
import { Card as CardTypes } from '@/types/Board'
import { Link } from 'react-router-dom'
import { MdImage as ImageIcon, MdVisibility } from 'react-icons/md'

import { useGetUsers } from '@/hooks/demands/queries/useGetUsers'
import { Tag } from '@/components/Tag'

export function Card({
  demandId,
  demandName,
  companyName,
  area,
  term,
  isQA,
  isMosaic,
  userList,
  tags
}: CardTypes) {
  const { data: users } = useGetUsers()

  const mappedReviewers = users?.filter((user) =>
    userList.find((reviewer) => reviewer.userId === user.id)
  )

  return (
    <ContextMenu.Trigger asChild>
      <Link
        to={`/demands/${demandId}`}
        title={demandId}
        className={twMerge(
          'w-[196px] rounded p-2',
          'bg-cromai-m3-sys-light-surface-variant  dark:bg-cromai-m3-sys-dark-surface-variant',
          'transition-all hover:opacity-80'
        )}
      >
        <div className="flex items-center justify-between">
          <DeadlineTag date={term.date} type={term.type} />
          <span
            className={twMerge(
              'flex items-center gap-1 font-mono text-labelXLarge',
              'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface-variant'
            )}
          >
            {isMosaic && <ImageIcon size={16} title="Mosaico" />}
            {area} ha
          </span>
        </div>
        <section
          className={twMerge(
            'mt-2 flex flex-col gap-1',
            'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface-variant'
          )}
        >
          <h2 className="font-mono text-labelSmall">{companyName}</h2>
          <span className="max-h-8 overflow-hidden font-mono text-bodySmall">
            {demandName}
          </span>
          <div className="flex items-center gap-1">
            {isQA && (
              <MdVisibility
                size={16}
                title="QA"
                className="text-cromai-m3-ref-tertiary-30 dark:text-cromai-m3-ref-tertiary-80"
              />
            )}
            <span className="w-14 truncate font-mono text-bodySmall">
              {demandId}
            </span>
          </div>
        </section>
        <div className="flex w-full flex-wrap gap-1 py-2">
          {tags?.map((tag) => (
            <Tag key={tag.id} label={tag.name} color={tag.color} />
          ))}
        </div>
        <div className="flex flex-wrap gap-1 break-all">
          {mappedReviewers?.map((user) => (
            <Avatar name={user.name} size="small" key={user.id} />
          ))}
        </div>
      </Link>
    </ContextMenu.Trigger>
  )
}
