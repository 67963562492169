export type envKeys =
  | 'API_ATLAS_URL'
  | 'API_ATHENAS_URL'
  | 'API_AUTH_COMPANY_ID'
  | 'SOFTWARE_VERSION'

export type envType = {
  [key in envKeys]: string
}

export const env: envType = {
  API_ATLAS_URL: import.meta.env.VITE_API_ATLAS_URL,
  API_ATHENAS_URL: import.meta.env.VITE_API_ATHENAS_URL,
  API_AUTH_COMPANY_ID: import.meta.env.VITE_API_AUTH_COMPANY_ID,
  SOFTWARE_VERSION: import.meta.env.VITE_SOFTWARE_VERSION
}
