import { lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import routes from '@/routes'

import { SigninLayout } from '@/layouts'

import Recovery from '@/pages/Recovery'
import { LoadingCircle } from '@/components'
const Signin = lazy(() => import('@/pages/Signin'))

export default function Public() {
  return (
    <Routes>
      <Route
        path={routes.root}
        element={
          <SigninLayout>
            <Suspense fallback={<LoadingCircle size={20} />}>
              <Signin />
            </Suspense>
          </SigninLayout>
        }
      />
      <Route
        path={routes.recovery}
        element={
          <SigninLayout>
            <Recovery />
          </SigninLayout>
        }
      />

      <Route path="*" element={<Navigate to={routes.root} replace />} />
    </Routes>
  )
}
