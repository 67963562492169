import { useCallback } from 'react'
import { useDebounce } from 'use-debounce'
import { produce } from 'immer'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { Demand } from '@/types/demands'
import { makeGetDemandKey } from './useGetDemand'
import { updateGrowLinePartNumber } from '@/services/modules/athenas/growLines'
import { handleToast } from '@/utils/handleToast'

interface UseUpdatePartNumberProps {
  partNumber: number
  demandId: string
  partId: string
  userId: number
}

export function useUpdatePartNumber({
  partNumber,
  demandId,
  partId,
  userId
}: UseUpdatePartNumberProps) {
  const queryClient = useQueryClient()

  const [debouncePartNumber] = useDebounce(partNumber, 2000)

  const updatePartNumberFromCache = useCallback(
    () =>
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(demandId),
        produce((draft) => {
          if (!draft) return

          const index = draft.userList.findIndex(
            (user) => user.userId === userId
          )

          if (index !== -1) {
            const partIndex = draft.userList[index].files.grow_lines?.findIndex(
              (part) => part.partId === partId
            )
            draft.userList[index].files.grow_lines![partIndex!].partNumber =
              partNumber
          }
        })
      ),
    [partNumber, demandId, partId, userId, queryClient]
  )

  const query = useQuery({
    queryKey: [
      'UPDATE_PART_NUMBER',
      { demandId, userId, partId },
      debouncePartNumber
    ],
    queryFn: () =>
      updateGrowLinePartNumber(demandId, userId, partId, partNumber),
    onSuccess: updatePartNumberFromCache,
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível adicionar a Parte. Tente novamente'
      }),
    enabled: !!debouncePartNumber
  })

  return {
    ...query,
    isFetching: query.isFetching || partNumber !== debouncePartNumber
  }
}
