import { customTv } from '@/styles/tailwindMergeConfig'
import { Status } from '@/types/demands'
import * as Tooltip from '@radix-ui/react-tooltip'
import { useMemo } from 'react'

import {
  MdCheckCircleOutline,
  MdOutlineAssignmentReturned,
  MdOutlineBlock,
  MdOutlineDownload,
  MdOutlineExtension,
  MdOutlineVisibility,
  MdSchedule,
  MdWarningAmber
} from 'react-icons/md'

interface StatusTagProps {
  status: Status
  side: 'top' | 'right' | 'bottom' | 'left' | undefined
  align: 'center' | 'start' | 'end' | undefined
  variant?: 'border' | 'noBorder'
}

const variants = customTv({
  base: 'font-mono flex h-6 w-[118px] items-center gap-2 text-nowrap rounded px-2 text-labelMedium cursor-default',
  variants: {
    type: {
      border: [
        'border',
        'border-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline',
        'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface',
        'bg-cromai-m3-sys-light-background dark:bg-cromai-m3-sys-dark-background'
      ].join(' '),

      noBorder: [
        'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface',
        'bg-cromai-m3-sys-light-background dark:bg-cromai-m3-sys-dark-background'
      ].join(' '),

      warning:
        'bg-[#FFDE32] border-2 border-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline'
    }
  },

  defaultVariants: {
    type: 'noBorder'
  }
})

const statusIconSelected = {
  registered: {
    icon: <MdOutlineAssignmentReturned size={16} />
  },
  processing: { icon: <MdSchedule size={16} /> },
  processed: { icon: <MdOutlineExtension size={16} /> },
  revision: { icon: <MdOutlineVisibility size={16} /> },
  delivering: { icon: <MdOutlineDownload size={16} /> },
  concluded: {
    icon: <MdCheckCircleOutline size={16} />
  },
  not_delivered: {
    icon: <MdWarningAmber size={16} />
  },
  rejected: {
    icon: <MdOutlineBlock size={16} />
  },
  processing_error: {
    icon: <MdOutlineBlock size={16} />
  }
}

export function StatusTag({ status, variant, side, align }: StatusTagProps) {
  const statusSelectedValue = useMemo(
    () => statusIconSelected[status?.value as keyof typeof statusIconSelected],
    [status]
  )
  const isWarningStatus =
    status?.value === 'not_delivered' || status?.value === 'unknown'

  return (
    <Tooltip.Provider delayDuration={0}>
      <Tooltip.Root>
        <Tooltip.Trigger>
          <div
            data-testid={status.label}
            className={variants({
              type: isWarningStatus ? 'warning' : variant
            })}
          >
            {statusSelectedValue?.icon}
            <span className="overflow-hidden text-nowrap">{status.label}</span>
          </div>
        </Tooltip.Trigger>

        <Tooltip.Portal>
          <Tooltip.Content side={side} align={align}>
            <div className="my-1.5 flex cursor-default items-center gap-2 truncate rounded-sm bg-cromai-m3-sys-light-on-surface-variant px-3 font-mono text-labelSmall text-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-on-surface-variant dark:text-cromai-m3-sys-dark-surface-variant">
              {status.message}
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}
