import * as DialogRadix from '@radix-ui/react-dialog'
import * as ScrollArea from '@radix-ui/react-scroll-area'

import { Avatar, Button, Accordion, Dialog } from '@/components'
import { MdClose as CloseIcon, MdFilePresent } from 'react-icons/md'
import { Reviewer } from '@/types/demands'
import { useCallback, useMemo } from 'react'
import { useGetDemand } from '@/hooks/useGetDemand'
import { useDeliveryDemand } from '@/hooks/useDeliveryDemand'
import { AccordionStyled } from '../AccordionStyled'
import { twMerge } from '@/styles/tailwindMergeConfig'

interface WeedDeliveryDialogProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

export function WeedDeliveryDialog({
  isOpen,
  setIsOpen
}: WeedDeliveryDialogProps) {
  const { data } = useGetDemand()
  const { deliveryDemand } = useDeliveryDemand({
    onSuccess: () => {
      setIsOpen(false)
    }
  })
  const { userList: reviewers, demandName, treatment, id: demandId } = data!

  const listOfAllReviewerFiles = useCallback(
    (reviewer: Reviewer) =>
      Object.keys(reviewer.files.weed!).reduce<string[]>(
        (listFiles, weedType) =>
          reviewer.files.weed![weedType] !== null
            ? [...listFiles, ...reviewer.files.weed![weedType]]
            : listFiles,
        []
      ),
    []
  )

  const mappedReviewers = useMemo(
    () =>
      reviewers.map((reviewer) => ({
        ...reviewer,
        files: listOfAllReviewerFiles(reviewer)
      })),
    [reviewers, listOfAllReviewerFiles]
  )
  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className=" flex h-[432px] w-[603px] flex-col">
        <div className="flex w-full flex-row items-start justify-between gap-5 px-6 pt-6 text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
          <h1 className="font-mono text-bodyLarge ">
            Você tem certeza que deseja entregar a demanda{' '}
            <strong>{demandName}?</strong>
          </h1>
          <DialogRadix.Close title="Fechar">
            <CloseIcon size={24} className="" />
          </DialogRadix.Close>
        </div>
        <p className="mb-6 px-6 font-mono text-bodyMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
          Os arquivos serão compactados e enviados.
        </p>
        {reviewers.length === 0 && (
          <p className=" flex items-center justify-center font-mono text-bodyLarge text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
            Nenhum responsável atribuidos demanda.
          </p>
        )}
        <ScrollArea.Root className="mb-auto overflow-hidden px-6">
          <ScrollArea.Viewport className="h-full w-full border-inherit">
            <div className="flex w-full flex-col gap-2">
              <Accordion.Root>
                {mappedReviewers.map((reviewer) => {
                  return (
                    <AccordionStyled
                      key={reviewer.userId}
                      value={reviewer.userName}
                      title={
                        <div className="flex gap-6">
                          <Avatar name={reviewer.userName} size="small" />
                          {reviewer.userName}
                        </div>
                      }
                      icon={<MdFilePresent size={24} />}
                      labelNumber={reviewer.files.length}
                    >
                      <div
                        className={twMerge(
                          'flex flex-col gap-2 break-all px-2',
                          'text-cromai-m3-sys-light-on-surface-variant',
                          'dark:text-cromai-m3-sys-dark-on-surface-variant'
                        )}
                      >
                        {reviewer.files?.map((file) => (
                          <span
                            className="flex gap-2 px-3 py-2 font-mono text-labelSmall"
                            key={file}
                          >
                            {file}
                          </span>
                        ))}
                      </div>
                    </AccordionStyled>
                  )
                })}
              </Accordion.Root>
            </div>
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar
            orientation="vertical"
            className="mr-2 w-1 py-2"
          >
            <ScrollArea.Thumb className="w-1 rounded-full bg-cromai-m3-sys-light-outline dark:bg-cromai-m3-sys-dark-outline " />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>

        <div className="mt-6 flex justify-between px-6 pb-6">
          <DialogRadix.Close asChild>
            <Button variant="outlined" className="">
              Cancelar
            </Button>
          </DialogRadix.Close>
          <Button
            className="w-24"
            loading={deliveryDemand.isLoading}
            onClick={() => deliveryDemand.mutate({ demandId, treatment })}
          >
            Entregar
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
