export const CromaiColors = {
  cromai: {
    identity: {
      primaria: {
        verde: {
          1: '#23AA50',
          2: '#A0C84B',
          3: '#CCCC00'
        },
        laranja: '#FAAF32',
        vermelha: '#E64B32',
        azul: '#053043'
      },
      texto: '#001D29',
      status: {
        sucesso: '#A0C84B',
        alerta: '#FAAF32',
        progresso: '#55AFCB',
        inativo: '#CECFCE',
        erro: '#E64B32'
      }
    },
    m3: {
      sys: {
        light: {
          primary: '#006E2C',
          'on-primary': '#FFFFFF',
          'primary-container': '#80FC98',
          'on-primary-container': '#002107',
          secondary: '#4A6800',
          'on-secondary': '#FFFFFF',
          'secondary-container': '#C9F170',
          'on-secondary-container': '#131F00',
          tertiary: '#00658B',
          'on-tertiary': '#FFFFFF',
          'tertiary-container': '#C3E7FF',
          'on-tertiary-container': '#001E2D',
          error: '#BA1B1B',
          'on-error': '#FFFFFF',
          'error-container': '#FFDAD4',
          'on-error-container': '#410001',
          background: '#FCFDF7',
          'on-background': '#1A1C19',
          surface: '#FFFFFF',
          surface2: '#D6E8DA',
          'surface2-hover': '#CADCCE',
          'on-surface': '#1A1C19',
          'surface-variant': '#DEE5DA',
          'on-surface-variant': '#414940',
          outline: '#727970',
          'inverse-on-surface': '#f0f1eb',
          'inverse-surface': '#2f312e',
          'inverse-primary': '#63de7f',
          shadow: '#000000'
        },
        dark: {
          primary: '#63DE7F',
          'on-primary': '#003912',
          'primary-container': '#00531F',
          'on-primary-container': '#80FC98',
          secondary: '#ADD457',
          'on-secondary': '#253600',
          'secondary-container': '#374E00',
          'on-secondary-container': '#C9F170',
          tertiary: '#79D0FF',
          'on-tertiary': '#00344A',
          'tertiary-container': '#004C6A',
          'on-tertiary-container': '#C3E7FF',
          error: '#FFB4A9',
          'on-error': '#680003',
          'error-container': '#930006',
          'on-error-container': '#FFFFFF',
          background: '#1A1C19',
          'on-background': '#E1E3DD',
          surface: '#1A1C19',
          surface2: '#202C21',
          'surface2-hover': '#28372A',
          'on-surface': '#E1E3DD',
          'surface-variant': '#414940',
          'on-surface-variant': '#C1C9BE',
          outline: '#8B9389',
          'inverse-on-surface': '#1a1c19',
          'inverse-surface': '#e1e3dd',
          'inverse-primary': '#006e2c',
          shadow: '#000000'
        }
      },
      ref: {
        primary: {
          0: '#000000',
          10: '#002107',
          20: '#003912',
          30: '#00531F',
          40: '#006E2C',
          50: '#008A39',
          60: '#1EA64E',
          70: '#44C266',
          80: '#63DE7F',
          90: '#80FC98',
          95: '#C2FFC9',
          99: '#F4FFF0',
          100: '#FFFFFF'
        },
        secondary: {
          0: '#000000',
          10: '#131F00',
          20: '#253600',
          30: '#374E00',
          40: '#4A6800',
          50: '#608202',
          60: '#799C25',
          70: '#93B83F',
          80: '#ADD457',
          90: '#C9F170',
          95: '#D6FF7D',
          99: '#F4FFF0',
          100: '#FFFFFF'
        },
        tertiary: {
          0: '#000000',
          10: '#001E2D',
          20: '#00344A',
          30: '#004C6A',
          40: '#00658B',
          50: '#0080AF',
          60: '#279BCE',
          70: '#4DB5E9',
          80: '#79D0FF',
          90: '#C3E7FF',
          95: '#E2F3FF',
          99: '#F9FCFF',
          100: '#FFFFFF'
        },
        neutral: {
          0: '#000000',
          10: '#1A1C19',
          20: '#2F312E',
          30: '#454844',
          40: '#5D5F5B',
          50: '#757873',
          60: '#8F918C',
          70: '#AAACA7',
          80: '#C6C7C2',
          90: '#E1E3DD',
          95: '#F0F1EB',
          99: '#F4FFF0',
          100: '#FFFFFF'
        },
        neutralVariant: {
          0: '#000000',
          10: '#161D16',
          20: '#2B322B',
          30: '#414940',
          40: '#596058',
          50: '#727970',
          60: '#8B9389',
          70: '#A6ADA3',
          80: '#C1C9BE',
          90: '#DEE5DA',
          95: '#EBF3E7',
          99: '#F4FFF0',
          100: '#FFFFFF'
        },
        error: {
          0: '#000000',
          10: '#410001',
          20: '#680003',
          30: '#930006',
          40: '#BA1B1B',
          50: '#DD3730',
          60: '#FF5449',
          70: '#FF897A',
          80: '#FFB4A9',
          90: '#FFDAD4',
          95: '#FFEDE9',
          99: '#FCFCFC',
          100: '#FFFFFF'
        }
      }
    },
    source: {
      seed: '#22A850',
      primary: '#006E2C',
      secondary: '#A1C74C',
      tertiary: '#003044',
      neutral: '#5D5F5B',
      neutralVariant: '#596058',
      error: '#BA1B1B'
    },
    athenas: {
      bg: '#F0F0F0',
      light: '#F3F6F4',
      'green-dark': '#005c25'
    },
    weed: {
      gpa: '#1CBE00',
      gbp: '#0000FF',
      ofl: '#FFFF00',
      mamona: '#DD00FF',
      trep: '#FF0000',
      indf: '#FF9900'
    }
  }
}

export const CromaiBoxShadow = {
  lightElevation1: '0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004D',
  lightElevation2: '0px 2px 6px 2px #00000026, 0px 1px 2px 0px #0000004D',
  lightElevation3: '0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026',
  lightElevation4: '0px 2px 3px 0px #0000004D, 0px 6px 10px 4px #00000026',
  lightElevation5: '0px 4px 4px 0px #0000004D, 0px 8px 12px 6px #00000026',
  darkElevation1: '0px 1px 2px 0px #0000004D, 0px 1px 3px 1px #00000026',
  darkElevation2: '0px 1px 2px 0px #0000004D, 0px 2px 6px 2px #00000026',
  darkElevation3: '0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026',
  darkElevation4: '0px 2px 3px 0px #0000004D, 0px 6px 10px 4px #00000026',
  darkElevation5: '0px 4px 4px 0px #0000004D, 0px 8px 12px 6px #00000026'
}

export const CromaiDropShadow = {
  bottom: '2px 4px 6px #00000026',
  primary: '0px 6px 16px #008A3952'
}

export const CromaiFontSize = {
  displayLarge: [
    '57px',
    {
      lineHeight: '64px',
      letterSpacing: '-0,25px',
      fontWeight: '400'
    }
  ],
  displayMedium: [
    '45px',
    {
      lineHeight: '52px',
      letterSpacing: '0',
      fontWeight: '400'
    }
  ],
  displaySmall: [
    '36px',
    {
      lineHeight: '44px',
      letterSpacing: '0',
      fontWeight: '400'
    }
  ],

  headlineLarge: [
    '32px',
    {
      lineHeight: '40px',
      letterSpacing: '0',
      fontWeight: '500'
    }
  ],
  headlineMedium: [
    '28px',
    {
      lineHeight: '36px',
      letterSpacing: '0',
      fontWeight: '500'
    }
  ],
  headlineSmall: [
    '24px',
    {
      lineHeight: '32px',
      letterSpacing: '0',
      fontWeight: '500'
    }
  ],

  titleLarge: [
    '22px',
    {
      lineHeight: '28px',
      letterSpacing: '0',
      fontWeight: '500'
    }
  ],
  titleMedium: [
    '16px',
    {
      lineHeight: '24px',
      letterSpacing: '0.1px',
      fontWeight: '500'
    }
  ],
  titleSmall: [
    '14px',
    {
      lineHeight: '20px',
      letterSpacing: '0.1px',
      fontWeight: '500'
    }
  ],

  labelXXLarge: [
    '18px',
    {
      lineHeight: '20px',
      letterSpacing: '0.1px',
      fontWeight: '600'
    }
  ],
  labelXLarge: [
    '16px',
    {
      lineHeight: '20px',
      letterSpacing: '0.1px',
      fontWeight: '600'
    }
  ],
  labelLarge: [
    '14px',
    {
      lineHeight: '20px',
      letterSpacing: '0.1px',
      fontWeight: '600'
    }
  ],
  labelMedium: [
    '12px',
    {
      lineHeight: '16px',
      letterSpacing: '0.5px',
      fontWeight: '600'
    }
  ],
  labelSmall: [
    '11px',
    {
      lineHeight: '16px',
      letterSpacing: '0.5px',
      fontWeight: '600'
    }
  ],

  bodyLarge: [
    '16px',
    {
      lineHeight: '24px',
      letterSpacing: '0.5px',
      fontWeight: '400'
    }
  ],
  bodyMedium: [
    '14px',
    {
      lineHeight: '20px',
      letterSpacing: '0.25px',
      fontWeight: '400'
    }
  ],
  bodySmall: [
    '12px',
    {
      lineHeight: '16px',
      letterSpacing: '0.4px',
      fontWeight: '400'
    }
  ]
}

export const CromaiTheme = {
  CromaiBoxShadow,
  CromaiColors,
  CromaiDropShadow,
  CromaiFontSize
}
