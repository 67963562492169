import { customTv } from '@/styles/tailwindMergeConfig'
import { FileType } from '@/types/fileTypes'

type BoxColorProps = {
  fileType: FileType
  disabled?: boolean
}

const variants = customTv({
  base: 'flex justify-center items-center gap-3 whitespace-nowrap font-mono transition-all',
  variants: {
    fileType: {
      perimeter: '',
      midSizeGrass: 'bg-cromai-weed-gpa mr-2 h-4 w-4 rounded-sm',
      tallSizeGrass: 'bg-cromai-weed-gpa mr-2 h-4 w-4 rounded-sm',
      lowSizeGrass: 'bg-cromai-weed-gbp mr-2 h-4 w-4 rounded-sm',
      otherBroadleaf: 'bg-cromai-weed-ofl mr-2 h-4 w-4 rounded-sm',
      horseweed: 'bg-cromai-weed-mamona mr-2 h-4 w-4 rounded-sm',
      ricino: 'bg-cromai-weed-mamona mr-2 h-4 w-4 rounded-sm',
      sourgrass: 'bg-cromai-weed-trep mr-2 h-4 w-4 rounded-sm',
      bindweed: 'bg-cromai-weed-trep mr-2 h-4 w-4 rounded-sm',
      indefinite: 'bg-cromai-weed-indf mr-2 h-4 w-4 rounded-sm',
      planting_failure: '',
      reconstruction_lines: ''
    }
  }
})

export function BoxColor({ fileType }: BoxColorProps) {
  return (
    <div
      role="figure"
      className={variants({
        fileType: fileType
      })}
    ></div>
  )
}
