import { getSummaryBoards } from '@/services/modules/athenas/boards/get-summary-boards'
import { useQuery } from '@tanstack/react-query'

export const GET_SUMMARY_BOARDS_KEY = 'GET_SUMMARY_BOARDS'

export function useGetSummaryBoards() {
  const query = useQuery({
    queryKey: [GET_SUMMARY_BOARDS_KEY],
    queryFn: getSummaryBoards
  })

  return { ...query }
}
