import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { makeGetDemandKey } from './useGetDemand'

import { handleToast } from '@/utils/handleToast'
import { Demand } from '@/types/demands'

import { produce } from 'immer'
import { FileType } from '@/types/fileTypes'
import { deleteGrowLinesFiles } from '@/services/modules/athenas/growLines'

export function useDeleteGrowLinesFiles() {
  const queryClient = useQueryClient()

  const deleteGrowLinesFilesFromCache = useCallback(
    (variables: {
      demandId: string
      userId: number
      fileType: FileType
      partId: string
    }) =>
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(variables.demandId),
        produce((draft) => {
          if (!draft) return
          const index = draft.userList.findIndex(
            (user) => user.userId === variables.userId
          )
          if (index !== -1) {
            const partIndex = draft.userList[index].files.grow_lines?.findIndex(
              (part) => part.partId === variables.partId
            )
            draft.userList[index].files.grow_lines![partIndex!].files[
              variables.fileType
            ] = null
          }
        })
      ),
    [queryClient]
  )

  const mutation = useMutation({
    mutationFn: deleteGrowLinesFiles,
    cacheTime: 0,
    onSuccess: (_, variables) => {
      deleteGrowLinesFilesFromCache(variables)
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não possível remover os arquivos. Tente novamente'
      })
  })
  return mutation
}
