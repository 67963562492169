import { useMediaQuery } from 'react-responsive'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwind from '../../tailwind.config'

const tailwindConfig = resolveConfig(tailwind)

const breakpoints = tailwindConfig!.theme!.screens!
type BreakpointKey = 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export function useBreakpoint(breakpointKey: BreakpointKey) {
  const isMediaQuery = useMediaQuery({
    query: `(min-width: ${
      breakpoints[breakpointKey as keyof typeof breakpoints]
    })`
  })
  return [isMediaQuery]
}
