import { useCallback } from 'react'

import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/providers'

import { useParams } from 'react-router-dom'
import { produce } from 'immer'

import { Board } from '@/types/Board'
import { handleToast } from '@/utils/handleToast'
import { moveColumnCard } from '@/services/modules/athenas/boards/move-column-card'
import { useMakeGetBoardKey } from './useMakeGetBoardKey'

interface UseMoveColumnCardProps {
  demandId: string
  cardIndex: number
  newColumnId: string
  currentColumnId: string
}

export function useMoveColumnCard() {
  const { id } = useParams()

  const { queryKey } = useMakeGetBoardKey(id!)

  const moveColumnCardOnCache = useCallback(
    (variables: UseMoveColumnCardProps) =>
      queryClient.setQueryData<Board>(
        queryKey,
        produce((draft) => {
          if (!draft) return

          const currentColumn = draft.columns.find(
            (column) => column.id === variables.currentColumnId
          )

          draft.columns.map((column) => {
            if (column.id === variables.newColumnId) {
              const [currentCard] = currentColumn!.cards.splice(
                variables.cardIndex,
                1
              )

              column.cards.push(currentCard)
            }
          })
        })
      ),
    [queryKey]
  )

  const mutation = useMutation({
    mutationFn: ({
      demandId,
      newColumnId,
      cardIndex,
      currentColumnId
    }: UseMoveColumnCardProps) => {
      moveColumnCardOnCache({
        cardIndex,
        currentColumnId,
        demandId,
        newColumnId
      })
      return moveColumnCard({ newColumnId, demandId })
    },
    onError: () => {
      queryClient.invalidateQueries(queryKey)
      handleToast({
        type: 'error',
        message:
          'Erro ao mover a demanda. Atualize a página e tente mover novamente.'
      })
    }
  })

  return mutation
}
