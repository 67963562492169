import { ComponentProps, forwardRef } from 'react'

import { customTv, twMerge } from '@/styles/tailwindMergeConfig'
import { LuLoader2 as LoaderIcon } from 'react-icons/lu'

type ButtonProps = ComponentProps<'button'> & {
  size?: 'xl' | 'base' | 'lg' | 'sm' | 'xs' | 'icon'
  variant?: 'filled' | 'pill' | 'outlined' | 'text'
  disabled?: boolean
  loading?: boolean
  title?: string
}

const variants = customTv({
  base: 'flex justify-center items-center gap-3 whitespace-nowrap font-mono transition-all',
  variants: {
    color: {
      filled: [
        'rounded-md',
        'bg-cromai-m3-sys-light-primary text-cromai-m3-sys-light-on-primary',
        'hover:bg-cromai-m3-sys-light-inverse-primary hover:text-cromai-m3-sys-light-on-primary',
        'dark:bg-cromai-m3-sys-dark-primary dark:text-cromai-m3-sys-dark-on-primary dark:hover:bg-cromai-m3-sys-dark-inverse-primary dark:hover:text-cromai-m3-sys-dark-on-primary'
      ].join(' '),

      pill: [
        'rounded-full',
        'bg-cromai-m3-sys-light-primary text-cromai-m3-sys-light-on-primary hover:bg-cromai-m3-sys-light-inverse-primary hover:text-cromai-m3-sys-light-on-primary',
        'dark:bg-cromai-m3-sys-dark-primary dark:text-cromai-m3-sys-dark-on-primary dark:hover:bg-cromai-m3-sys-dark-inverse-primary dark:hover:text-cromai-m3-sys-dark-on-primary'
      ].join(' '),

      outlined: [
        'rounded-md',
        'border border-cromai-m3-sys-light-primary text-cromai-m3-sys-light-on-surface hover:border-cromai-m3-sys-light-inverse-primary',
        'dark:border-cromai-m3-sys-dark-primary dark:text-cromai-m3-sys-dark-on-surface dark:hover:border-cromai-m3-sys-dark-inverse-primary'
      ].join(' '),

      text: [
        'text-cromai-m3-sys-light-primary hover:text-cromai-m3-sys-light-inverse-primary',
        'dark:text-cromai-m3-sys-dark-primary dark:hover:text-cromai-m3-sys-dark-inverse-primary'
      ].join(' ')
    },
    size: {
      xl: 'h-12 px-6 py-[14px] text-labelXXLarge',
      lg: 'h-11 px-5 py-3 text-labelXLarge',
      base: 'h-10 p-5 py-[10px] text-labelLarge',
      sm: 'h-8 px-3 py-2 text-labelMedium ',
      xs: 'h-8 px-3 py-2 text-labelSmall',
      icon: 'p-2'
    },
    disabled: {
      true: twMerge(
        'bg-cromai-m3-sys-light-surface-variant text-cromai-m3-sys-light-outline',
        'dark:bg-cromai-m3-sys-dark-surface-variant dark:text-cromai-m3-sys-dark-outline',
        'pointer-events-none opacity-50 cursor-not-allowed rounded-md border-none'
      )
    }
  },
  compoundVariants: [
    {
      disabled: true,
      className:
        'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface bg-cromai-m3-sys-light-outline dark:bg-cromai-m3-sys-dark-outline'
    }
  ],
  defaultVariants: {
    size: 'base',
    color: 'filled'
  }
})

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      loading,
      className,
      disabled,
      children,
      onClick,
      size,
      variant,
      title,
      type
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        title={title}
        onClick={onClick}
        type={type}
        disabled={disabled || loading}
        className={variants({
          disabled: disabled || loading,
          color: variant,
          size: size,
          className
        })}
      >
        {loading ? (
          <LoaderIcon
            role="progressbar"
            className="animate-spin text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface"
            size={20}
          />
        ) : (
          children
        )}
      </button>
    )
  }
)
