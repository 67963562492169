import { getUsers } from '@/services/modules/athenas/users'
import { useQuery } from '@tanstack/react-query'

export function useGetUsers() {
  const query = useQuery({
    queryKey: ['reviewers'],
    queryFn: ({ signal }) => getUsers(signal),
    cacheTime: 8 * 60 * 60 * 1000, // 8 hours
    staleTime: 7 * 60 * 60 * 1000 // 7 hours
  })

  return query
}
