import { useGetDemand } from '@/hooks/useGetDemand'
import { GrowLinesDeliveryDialog } from '../GrowLinesDeliveryDialog'
import { WeedDeliveryDialog } from '../WeedDeliveryDialog'
import { useMemo, useState } from 'react'

const deliveryDialog = {
  weed: WeedDeliveryDialog,
  planting_failure: GrowLinesDeliveryDialog
}

export function useDeliveryDiaolog() {
  const [isOpenDeliveryDiaolg, setIsOpenDeliveryDiaolg] = useState(false)

  const { data } = useGetDemand()

  const CurrentDeliveryDialog = useMemo(
    () => deliveryDialog[data?.treatment as keyof typeof deliveryDialog],
    [data?.treatment]
  )

  return {
    CurrentDeliveryDialog: () => (
      <CurrentDeliveryDialog
        isOpen={isOpenDeliveryDiaolg}
        setIsOpen={setIsOpenDeliveryDiaolg}
      />
    ),
    openCurrentDeliveryDialog: () => setIsOpenDeliveryDiaolg(true)
  }
}
