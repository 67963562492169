import { addNewPartForGrowLines } from '@/services/modules/athenas/growLines'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { handleToast } from '@/utils/handleToast'
import { Demand, GrowLines } from '@/types/demands'
import { makeGetDemandKey } from './useGetDemand'
import { produce } from 'immer'
import { useCallback } from 'react'

export function useAddNewPart() {
  const queryClient = useQueryClient()

  const updateGrowlinesFromCache = useCallback(
    (
      data: GrowLines,
      variables: {
        demandId: string
        userId: number
      }
    ) =>
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(variables.demandId),
        produce((draft) => {
          if (!draft) return

          const userIndex = draft.userList.findIndex(
            (user) => user.userId === variables.userId
          )
          const growLines = draft.userList[userIndex].files.grow_lines
          if (growLines) {
            draft.userList[userIndex].files.grow_lines?.push(data)
            return
          }
          draft.userList[userIndex].files.grow_lines! = [{ ...data }]
        })
      ),
    [queryClient]
  )

  const mutation = useMutation({
    mutationFn: addNewPartForGrowLines,
    onSuccess: updateGrowlinesFromCache,
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível adicionar a Parte. Tente novamente '
      })
  })
  return mutation
}
