import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { produce } from 'immer'

import { Demand, Term } from '@/types/demands'
import { changeDeadline } from '@/services/modules/athenas/demands'
import { makeGetDemandKey } from './useGetDemand'
import { handleToast } from '@/utils/handleToast'

export function useChangeDeadline({ onSuccess }: { onSuccess: () => void }) {
  const queryClient = useQueryClient()

  const updateStatusDemandFromCache = useCallback(
    ({
      date,
      type,
      demandId
    }: {
      date: string
      type: Term
      demandId: string
    }) =>
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(demandId),
        produce((draft) => {
          if (!draft) return

          draft.term = {
            date,
            type
          }
        })
      ),
    [queryClient]
  )

  const mutation = useMutation({
    mutationFn: changeDeadline,
    onSuccess: ({ date, type }, variables) => {
      updateStatusDemandFromCache({
        date,
        type,
        demandId: variables.demandId
      }),
        onSuccess(),
        handleToast({ message: 'Prazo de entrega alterado', type: 'success' })
    },
    onError: () =>
      handleToast({
        message:
          'Erro ao alterar prazo de entrega. Atualize a página e tente novamente.',
        type: 'error'
      })
  })

  return { changeDeadline: mutation }
}
