import { useMutation } from '@tanstack/react-query'
import { recoverDemand } from '@/services/modules/athenas/demands'

import { handleToast } from '@/utils/handleToast'

export function useRecoverDemand({ onSuccess }: { onSuccess: () => void }) {
  const mutation = useMutation({
    mutationFn: recoverDemand,

    onSuccess: () => {
      onSuccess()
      handleToast({
        type: 'success',
        message: 'Requisição realizada com sucesso.'
      })
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Ocorreu um erro na solicitação. Tente novamente.'
      })
  })
  return {
    recoverDemandMutate: mutation.mutate,
    isRecoverDemandLoading: mutation.isLoading
  }
}
