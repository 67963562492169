import { MdAdd } from 'react-icons/md'
import { Avatar, Button, LoadingCircle } from '@/components'
import { useAddResponsible } from '@/hooks/demands/mutations/useAddResponsible'
import { useGetDemand } from '@/hooks/useGetDemand'

interface ReviewerProps {
  user: {
    name: string
    email: string
    id: number
  }
  isAddedUser: boolean
}

export function Reviewer({ user, isAddedUser }: ReviewerProps) {
  const { mutate: addResponsible, isLoading: isLoadingAddResponsible } =
    useAddResponsible()
  const { data } = useGetDemand()

  return (
    <div
      className={`group flex w-full items-center px-4 py-2 hover:visible hover:bg-[#CCDBCC] hover:dark:bg-[#445545] ${
        isAddedUser && 'opacity-50'
      }`}
    >
      <div className="flex items-center gap-3">
        <Avatar size="small" name={user.name} />
        <div className="flex flex-col gap-1 font-mono">
          <span className="text-bodyMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
            {user.name}
          </span>
          <span className="max-w-[190px] truncate text-labelMedium text-cromai-m3-sys-light-outline dark:text-cromai-m3-sys-dark-outline">
            {user.email}
          </span>
        </div>
      </div>
      {!isAddedUser && (
        <Button
          title={`Adicionar ${user.name}`}
          size="icon"
          variant="outlined"
          className="ml-auto hidden group-hover:block"
          onClick={() =>
            addResponsible({
              userId: user.id,
              userName: user.name,
              demandId: data!.id
            })
          }
        >
          {isLoadingAddResponsible ? (
            <LoadingCircle size={12} />
          ) : (
            <MdAdd size={12} />
          )}
        </Button>
      )}
    </div>
  )
}
