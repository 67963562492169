import { customTv, twMerge } from '@/styles/tailwindMergeConfig'
import { useEffect, useRef } from 'react'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  isError?: boolean
  newValue: string
}

const variants = customTv({
  base: [
    'flex px-4 relative w-full justify-between rounded border min-h-[52px] items-center',
    'border-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline',
    'focus:border-cromai-m3-sys-light-primary dark:focus:border-cromai-m3-sys-dark-primary',
    'focus:outline-none focus:ring-transparent',
    'bg-cromai-m3-sys-light-surface dark:bg-cromai-m3-sys-dark-surface'
  ].join(' '),

  variants: {
    borderError: {
      true: [
        'border-cromai-m3-ref-error-30 dark:border-cromai-m3-ref-error-40',
        'focus:border-cromai-m3-ref-error-30 dark:focus:border-cromai-m3-ref-error-50'
      ].join(' ')
    }
  }
})

export function AutoResizableTextarea({
  isError,
  className,
  children,
  onChange,
  defaultValue,
  newValue,
  ...props
}: TextareaProps) {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '20px'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [newValue])

  return (
    <label className={variants({ className, borderError: isError })}>
      <textarea
        {...props}
        ref={textareaRef}
        onChange={onChange}
        defaultValue={defaultValue}
        className={twMerge(
          'py-4',
          'font-mono text-bodyMedium font-normal leading-tight',
          'flex resize-none items-center justify-center bg-transparent outline-none',
          'text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant',
          'placeholder:text-cromai-m3-sys-light-outline dark:placeholder:text-cromai-m3-sys-dark-outline'
        )}
      />
      {children}
    </label>
  )
}
