import { AllPartsOfReviewers } from './ValidationTypes'

export function useAllResultsDuplicates(
  allPartsOfReviewers: AllPartsOfReviewers[]
) {
  return allPartsOfReviewers.filter((part, _, parts) => {
    return parts.find((innerPart) => {
      const isSamePart =
        innerPart.partNumber === part.partNumber &&
        part.partId !== innerPart.partId

      if (!isSamePart) return

      if (part.files.reconstruction_lines) {
        const hasEqualFilesInBoth = innerPart.files.reconstruction_lines
        return hasEqualFilesInBoth
      }

      if (part.files.planting_failure) {
        const hasEqualFilesInBoth = innerPart.files.planting_failure
        const notHasRecontrctionLinesInEqualPart =
          !innerPart.files.reconstruction_lines

        return hasEqualFilesInBoth || notHasRecontrctionLinesInEqualPart
      }
    })
  })
}
