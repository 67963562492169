import { useState } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { MdArrowDropDown } from 'react-icons/md'
import { twMerge } from '@/styles/tailwindMergeConfig'

import { useGetDemand } from '@/hooks/useGetDemand'

import { RecoverDialog } from '../RecoverDialog'
import { ChangeDeadlineDialog } from '../ChangeDeadlineDialog'
import { useDeliveryDiaolog } from '../hooks/useDeliveryDiaolog'
import { useUpdateIsQAOnDemand } from '@/hooks/demands/mutations/useUpdateIsQAOnDemand'

type ItemProps = {
  label: string
  disabled?: boolean
  className?: string
  handleClick?: () => void
}

function Item({ handleClick, className, disabled, label }: ItemProps) {
  return (
    <DropdownMenu.Item
      className={twMerge(
        disabled && 'pointer-events-none opacity-40',

        'flex cursor-pointer items-start px-3 py-1.5 outline-none transition-all',
        'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface ',
        'hover:bg-cromai-m3-sys-light-surface2-hover dark:hover:bg-cromai-m3-sys-dark-surface2-hover',
        className
      )}
      onClick={handleClick}
    >
      {label}
    </DropdownMenu.Item>
  )
}

export function Actions() {
  const { data } = useGetDemand()
  const { mutate: updateIsQAOnDemand } = useUpdateIsQAOnDemand()
  const [isOpenRecoverDialog, setIsOpenRecoverDialog] = useState(false)
  const [isOpenChangeDeadlineDialog, setIsOpenChangeDeadlineDialog] =
    useState(false)

  const { CurrentDeliveryDialog, openCurrentDeliveryDialog } =
    useDeliveryDiaolog()

  const isCompleted =
    data!.status.value === 'concluded' && 'pointer-events-none'

  const isQA = !!data?.isQA

  function handleChangeIsQA() {
    updateIsQAOnDemand({ demandId: data!.id, isQA: !isQA })
  }

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger
          aria-label="Ações"
          className={twMerge(
            'flex items-center justify-center',
            'h-8 w-[7.125rem] gap-2 rounded-lg border px-3',
            'whitespace-nowrap font-mono text-labelMedium',
            'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface',
            'border-cromai-m3-sys-light-primary  dark:border-cromai-m3-sys-dark-outline'
          )}
        >
          <span className="lg:hidden">Ações</span>
          <span className="hidden gap-4 lg:flex">Mais ações</span>
          <MdArrowDropDown size={16} />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            align="start"
            side="bottom"
            className={twMerge(
              'absolute flex w-[7.125rem] flex-col rounded-md py-2 font-mono',
              'text-labelMedium',
              'bg-[#D6E8DA] dark:bg-[#202C21]',
              'shadow-lightElevation2 dark:shadow-darkElevation2'
            )}
          >
            {isCompleted ? (
              <Item
                label="Reaver"
                className="lg:hidden"
                handleClick={() => {
                  setIsOpenRecoverDialog(true)
                }}
              />
            ) : (
              <Item
                label="Entregar"
                className="lg:hidden"
                handleClick={openCurrentDeliveryDialog}
              />
            )}
            <Item
              label="Alterar prazo"
              handleClick={() => {
                setIsOpenChangeDeadlineDialog(true)
              }}
            />
            <Item
              label={isQA ? 'Remover QA' : 'Adicionar QA'}
              handleClick={handleChangeIsQA}
            />
            <Item disabled label="Acessar Revisão" className="lg:hidden" />
            <Item disabled label="Reprocessar" />
            <Item disabled label="Reportar" />
            <Item disabled label="Recusar" />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      <CurrentDeliveryDialog />

      <RecoverDialog
        demandName={data!.demandName}
        demandId={data!.id}
        setIsOpen={setIsOpenRecoverDialog}
        isOpen={isOpenRecoverDialog}
      />

      <ChangeDeadlineDialog
        demandId={data!.id}
        originalDeadline={data!.originalDeadline}
        setIsOpen={setIsOpenChangeDeadlineDialog}
        isOpen={isOpenChangeDeadlineDialog}
      />
    </>
  )
}
