import { apiAthenas } from '@/services/api/apiAthenas'

export async function moveColumnCard({
  demandId,
  newColumnId
}: {
  demandId: string
  newColumnId: string
}) {
  return await apiAthenas.put(`/demands/${demandId}`, {
    currentColumnId: newColumnId
  })
}
