import { apiAthenas } from '@/services/api/apiAthenas'
import { GrowLines } from '@/types/demands'
import { FileType } from '@/types/fileTypes'

export async function addNewPartForGrowLines({
  demandId,
  userId
}: {
  demandId: string
  userId: number
}) {
  const { data } = await apiAthenas.post<GrowLines>(
    `/parts/${demandId}/${userId}`
  )
  return data
}

export async function deletePartOfTheGrowLines({
  demandId,
  partId,
  userId
}: {
  demandId: string
  userId: number
  partId: string
}) {
  await apiAthenas.delete(`/parts/delete/${demandId}/${userId}/${partId}`)
}

export async function updateGrowLinePartNumber(
  demandId: string,
  userId: number,
  partId: string,
  partNumber: number
) {
  return await apiAthenas.put(`/parts/${demandId}/${userId}/${partId}`, {
    partNumber: partNumber
  })
}

export async function getUrlForUploadGrowLinesFiles(
  demandId: string,
  userId: number,
  fileType: string,
  partId?: string
) {
  const { data } = await apiAthenas.get<string>(
    `/demands/${demandId}/${userId}/files/${fileType}/${partId}`
  )
  return data
}

export async function successUploadGrowLinesFiles(
  demandId: string,
  userId: number,
  fileType: string,
  fileNameList: string[],
  partId: string
) {
  await apiAthenas.put<string>(
    `/parts/update/${demandId}/${userId}/${partId}/${fileType}`,
    { fileNameList }
  )
}

export async function deleteGrowLinesFiles({
  demandId,
  fileType,
  partId,
  userId
}: {
  demandId: string
  userId: number
  fileType: FileType
  partId: string
}) {
  await apiAthenas.delete(
    `/demands/${demandId}/${userId}/files/${fileType}/${partId}`
  )
}
