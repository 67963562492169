import { Reviewers } from './ValidationTypes'

export function useAllPartsOfReviewers(reviewers: Reviewers[]) {
  return reviewers.flatMap((reviewer) =>
    reviewer.files.map((part) => ({
      reviewer: reviewer.userName,
      ...part
    }))
  )
}
