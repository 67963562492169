import { deleteResponsible } from '@/services/modules/athenas/demands'
import { Demand } from '@/types/demands'
import { handleToast } from '@/utils/handleToast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { makeGetDemandKey } from './useGetDemand'
import { useCallback } from 'react'
import { produce } from 'immer'

export function useDeleteResponsible() {
  const queryClient = useQueryClient()

  const deleteResponsibleFromCache = useCallback(
    (variables: { demandId: string; userId: number }) =>
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(variables.demandId),
        produce((draft) => {
          if (!draft) return

          const index = draft.userList.findIndex(
            (user) => user.userId === variables.userId
          )
          if (index !== -1) draft.userList.splice(index, 1)
        })
      ),
    [queryClient]
  )

  const mutation = useMutation({
    mutationFn: deleteResponsible,
    cacheTime: 0,
    onError: () =>
      handleToast({
        type: 'error',
        message:
          'Não foi possível remover o responsável da demanda. Tente novamente.'
      }),

    onSuccess: (_, variables) => {
      deleteResponsibleFromCache(variables)
    }
  })
  return {
    deleteResponsible: mutation.mutate,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError
  }
}
