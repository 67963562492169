import { apiAthenas } from '@/services/api/apiAthenas'
import { Colors } from '@/types/Colors'

export async function updateTagColor({
  boardId,
  tagId,
  color
}: {
  boardId: string
  tagId: string
  color: Colors
}) {
  return await apiAthenas.put<{
    id: string
  }>(`/tags/${boardId}/${tagId}`, { color })
}
