export const keysLabelMapping = {
  perimeter: 'Perímetro individual',
  tallSizeGrass: 'Gramíneas porte alto (GPA)',
  midSizeGrass: 'Gramíneas porte médio (GPM)',
  lowSizeGrass: 'Gramíneas porte baixo (GPB)',
  otherBroadleaf: 'Outras folhas largas (OFL)',
  horseweed: 'Buva',
  sourgrass: 'Amargoso',
  ricino: 'Mamona',
  bindweed: 'Trepadeira',
  indefinite: 'Indefinidas',
  planting_failure: 'Falha de plantio',
  reconstruction_lines: 'Reconstrução de linhas'
}
