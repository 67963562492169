import {
  Accordion,
  Button,
  BoxColor,
  FileInputButton,
  LoadingCircle
} from '@/components'
import { useDeleteWeedFiles } from '@/hooks/useDeleteWeedFiles'
import { useUploadWeedFiles } from '@/hooks/useUploadWeedFiles'
import { twMerge } from '@/styles/tailwindMergeConfig'
import { Status } from '@/types/demands'
import { FileType } from '@/types/fileTypes'
import { keysLabelMapping } from '@/utils/keysLabelMapping'
import { MdAdd as PlusIcon, MdCached as UpdateIcon } from 'react-icons/md'

interface weedFilesUploadProps {
  demandId: string
  demandStatus: Status['value']
  title: string
  userId: number
  fileType: FileType
  files: string[]
}

export function WeedFilesUpload({
  demandId,
  demandStatus,
  userId,
  fileType,
  files
}: weedFilesUploadProps) {
  const { mutate: uploadWeedFiles, isLoading: isUploadWeedFiles } =
    useUploadWeedFiles()
  const { mutate: deleteWeedFiles, isLoading: isDeleteWeedFiles } =
    useDeleteWeedFiles()

  const isNotFinishedDemand = demandStatus !== 'concluded'
  const isFiles = !!files.length

  return (
    <Accordion.Item value={fileType} className="mb-3">
      <Accordion.Header disabled={!isFiles}>
        <div className="flex w-full items-center">
          <span className="mr-auto flex items-center font-mono text-bodyMedium text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
            <BoxColor fileType={fileType} />
            <h1>{keysLabelMapping[fileType]}</h1>
          </span>
          {isNotFinishedDemand && (
            <FileInputButton
              size="xs"
              variant={isFiles ? 'filled' : 'outlined'}
              label={isFiles ? 'Substituir arquivos' : 'Adicionar arquivos'}
              onFileSelect={(files) =>
                uploadWeedFiles({
                  demandId,
                  userId,
                  fileType,
                  fileList: files! // TODO: receber null
                })
              }
              loading={isUploadWeedFiles}
              multiple
              className={[
                'w-[9.1875rem]',
                isFiles &&
                  'bg-cromai-m3-sys-light-secondary-container text-cromai-m3-sys-light-on-secondary-container dark:bg-cromai-m3-sys-dark-secondary-container dark:text-cromai-m3-sys-dark-on-secondary-container'
              ].join(' ')}
            >
              {isFiles ? <UpdateIcon size={16} /> : <PlusIcon size={16} />}
            </FileInputButton>
          )}
        </div>
      </Accordion.Header>
      <Accordion.Content>
        {isFiles && (
          <div
            className={twMerge(
              'break-all px-2',
              'text-cromai-m3-sys-light-on-surface-variant',
              'dark:text-cromai-m3-sys-dark-on-surface-variant'
            )}
          >
            {files.map((file) => (
              <span
                className="flex items-center gap-2 px-3 py-2 font-mono text-labelSmall"
                key={file}
              >
                {file}
              </span>
            ))}

            {isNotFinishedDemand && (
              <Button
                title="Remover"
                className="ml-auto mr-6 text-cromai-m3-sys-light-error hover:text-cromai-m3-sys-light-error hover:opacity-80 dark:text-cromai-m3-sys-dark-error"
                variant="text"
                onClick={() =>
                  deleteWeedFiles({
                    demandId,
                    fileType,
                    userId
                  })
                }
              >
                {isDeleteWeedFiles && <LoadingCircle size={20} />}
                Remover arquivos
              </Button>
            )}
          </div>
        )}
      </Accordion.Content>
    </Accordion.Item>
  )
}
