import { Checkbox, Input, TagColor, SlideSheet } from '@/components'
import { useChangeIsTagOnCard } from '@/hooks/boards/mutations/useChangeIsTagOnCard'
import { twMerge } from '@/styles/tailwindMergeConfig'
import { Colors } from '@/types/Colors'
import { useMemo, useState } from 'react'

type TagsAvaliableProps = {
  demandId: string
  demandName: string
  columnIndex: number
  cardIndex: number

  listOfVerifiedTags: {
    isTag: boolean
    id: string
    name: string
    color: Colors
  }[]
}

export function TagsAvaliable({
  demandId,
  demandName,
  columnIndex,
  cardIndex,
  listOfVerifiedTags
}: TagsAvaliableProps) {
  const [filter, setFilter] = useState('')
  const { mutate: changeIsTag } = useChangeIsTagOnCard()

  const filteredTags = useMemo(
    () =>
      listOfVerifiedTags?.filter((tag) =>
        tag.name.toLowerCase().includes(filter.toLowerCase())
      ),
    [filter, listOfVerifiedTags]
  )

  return (
    <SlideSheet
      trigger={
        <span
          className={twMerge(
            'w-full px-4 py-2 text-start font-mono text-labelSmall',
            'cursor-pointer outline-none transition-all',
            'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface ',
            'hover:bg-cromai-m3-sys-light-surface2-hover dark:hover:bg-cromai-m3-sys-dark-surface2-hover'
          )}
        >
          Etiquetas
        </span>
      }
      heading={demandName}
    >
      <Input
        aria-label="Procurar etiquetas"
        placeholder="Procurar etiquetas"
        onChange={(event) => setFilter(event.target.value)}
      />
      <div className="mt-4 h-[calc(100vh-10.5rem)] overflow-auto">
        {filteredTags?.map((tag) => (
          <div
            key={tag.id}
            className="flex items-center gap-2 px-2 py-1.5 text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface"
          >
            <Checkbox
              checked={tag.isTag}
              onCheckedChange={(checked) =>
                changeIsTag({
                  demandId,
                  isTag: checked,
                  tagId: tag.id,
                  columnIndex,
                  cardIndex,
                  tagColor: tag.color,
                  tagName: tag.name
                })
              }
              title={tag.name}
            />
            <TagColor color={tag.color} size={24} />
            <span>{tag.name}</span>
          </div>
        ))}
      </div>
    </SlideSheet>
  )
}
