import { apiAthenas } from '@/services/api/apiAthenas'

export async function moveColumn({
  boardId,
  orderedListOfColumnIds
}: {
  boardId: string
  orderedListOfColumnIds: string[]
}) {
  await apiAthenas.put(`/boards/${boardId}/column`, {
    extraColumnsOrder: orderedListOfColumnIds
  })
}
