import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { makeGetDemandKey } from './useGetDemand'
import { deleteWeedFiles } from '@/services/modules/athenas/weed'

import { handleToast } from '@/utils/handleToast'
import { Demand } from '@/types/demands'

import { produce } from 'immer'
import { FileType } from '@/types/fileTypes'

export function useDeleteWeedFiles() {
  const queryClient = useQueryClient()

  const deleteReviewersFilesFromCache = useCallback(
    (variables: { demandId: string; userId: number; fileType: FileType }) =>
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(variables.demandId),
        produce((draft) => {
          if (!draft) return

          const index = draft.userList.findIndex(
            (user) => user.userId === variables.userId
          )

          if (index === -1) return

          draft.userList[index].files.weed![variables.fileType] = []
        })
      ),
    [queryClient]
  )

  const mutation = useMutation({
    mutationFn: deleteWeedFiles,
    cacheTime: 0,
    onSuccess: (_, variables) => {
      deleteReviewersFilesFromCache(variables)
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não possível remover os arquivos. Tente novamente'
      })
  })
  return mutation
}
