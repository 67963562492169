import * as DialogRaix from '@radix-ui/react-dialog'

import { Button } from '@/components'
import {
  MdClose as CloseIcon,
  MdOutlinePersonRemove as PersonRemoveIcon
} from 'react-icons/md'

import { useDeleteResponsible } from '@/hooks/useDeleteResponsible'
import { twMerge } from '@/styles/tailwindMergeConfig'

interface DialogRemoveReviewerProps {
  reviewer: {
    userId: number
    userName: string
    revisionTime?: string
  }
  demandId: string
}

export function DialogRemoveReviewer({
  reviewer,
  demandId
}: DialogRemoveReviewerProps) {
  const { deleteResponsible } = useDeleteResponsible()

  return (
    <DialogRaix.Root>
      <DialogRaix.Trigger
        className={twMerge(
          'flex items-center gap-3 px-3 py-1.5',
          'hover:bg-cromai-m3-sys-light-surface2-hover dark:hover:bg-cromai-m3-sys-dark-surface2-hover',
          'text-cromai-m3-sys-light-inverse-surface dark:text-cromai-m3-sys-dark-inverse-surface'
        )}
      >
        <PersonRemoveIcon size={21} />
        Remover revisor
      </DialogRaix.Trigger>
      <DialogRaix.Portal>
        <DialogRaix.Overlay className="absolute inset-0 bg-black opacity-40" />
        <DialogRaix.Content
          aria-describedby="Remove o responsavel"
          className="absolute left-1/2 top-1/2 w-[485px] -translate-x-1/2 -translate-y-1/2 rounded-3xl bg-cromai-m3-sys-light-surface-variant p-6 dark:bg-cromai-m3-sys-dark-surface-variant"
        >
          <div>
            <div className="flex flex-row items-start gap-5 text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
              <DialogRaix.Title className="font-mono text-bodyLarge ">
                Você tem certeza que deseja remover{' '}
                <strong>{reviewer.userName}</strong> desta demanda?
              </DialogRaix.Title>
              <DialogRaix.Close title="Fechar">
                <CloseIcon size={24} />
              </DialogRaix.Close>
            </div>

            <DialogRaix.Description className="font-mono text-bodyMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
              Ao confirmar esta ação o tempo total de revisão será afetado em
              {reviewer.revisionTime ? ` ${reviewer.revisionTime}` : ' 00:00'}.
            </DialogRaix.Description>
            <div className="mt-6 flex justify-between">
              <DialogRaix.Close asChild>
                <Button variant="outlined" className="">
                  Cancelar
                </Button>
              </DialogRaix.Close>
              <DialogRaix.Close asChild>
                <Button
                  onClick={() =>
                    deleteResponsible({ userId: reviewer.userId, demandId })
                  }
                >
                  Confirmar
                </Button>
              </DialogRaix.Close>
            </div>
          </div>
        </DialogRaix.Content>
      </DialogRaix.Portal>
    </DialogRaix.Root>
  )
}
