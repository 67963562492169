import { apiAtlas } from '@/services/api/apiAtlas'

export async function changePasswordByEmail(email: string) {
  return apiAtlas.post(
    '/courrier/password/change',
    {},
    {
      params: {
        email
      }
    }
  )
}
