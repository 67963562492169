import * as DialogRadix from '@radix-ui/react-dialog'
import { Button, Dialog } from '@/components'
import { useDeleteTag } from '@/hooks/boards/mutations/useDeleteTag'

interface DeleteTagDialogProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  tagName: string
  boardId: string
  tagId: string
}

export function DeleteTagDialog({
  isOpen,
  setIsOpen,
  boardId,
  tagName,
  tagId
}: DeleteTagDialogProps) {
  const { mutate: deleteTag } = useDeleteTag()

  function handleDeleteTag() {
    deleteTag({ boardId, tagId })
    setIsOpen(false)
  }

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="w-[27rem] p-6">
        <DialogRadix.Title className="mb-2 text-titleLarge text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
          Remover etiqueta {tagName}
        </DialogRadix.Title>
        <span className="font-mono text-bodyLarge text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
          Essa etiqueta será removida de todas as demandas desse quadro. Essa
          ação não pode ser desfeita.
        </span>
        <footer className="mt-6 flex w-full items-center justify-between">
          <DialogRadix.Close asChild>
            <Button variant="outlined">Cancelar</Button>
          </DialogRadix.Close>
          <Button onClick={handleDeleteTag}>Remover</Button>
        </footer>
      </div>
    </Dialog>
  )
}
