import { apiAthenas } from '@/services/api/apiAthenas'

export async function updateIsQAOnResponsible({
  demandId,
  reviewerId,
  isQA
}: {
  demandId: string
  reviewerId: number
  isQA: boolean
}) {
  await apiAthenas.put(`/user-demand/${demandId}/${reviewerId}`, {
    isQA
  })
}
