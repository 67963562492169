import { toast } from 'react-toastify'
import {
  MdOutlineErrorOutline,
  MdCheckCircleOutline,
  MdWarningAmber,
  MdClose,
  MdOutlinePending
} from 'react-icons/md'

interface ToastifyProps {
  message: string
  type: 'error' | 'success' | 'info' | 'warning'
  autoClose?: number | false
}

export const listIcons = {
  error: <MdOutlineErrorOutline size={30} />,
  success: <MdCheckCircleOutline size={20} />,
  info: <MdOutlinePending size={20} />,
  warning: <MdWarningAmber size={20} />
}

export function handleToast({
  message,
  type,
  autoClose = 5000
}: ToastifyProps) {
  return toast(message, {
    autoClose: autoClose,
    type: type,
    icon: listIcons[type],
    closeButton: ({ closeToast }) => (
      <button onClick={closeToast}>
        <MdClose className="mr-1" size={24} />
      </button>
    )
  })
}
