import { apiAthenas } from '@/services/api/apiAthenas'

export async function updateTagName({
  boardId,
  tagId,
  name
}: {
  boardId: string
  tagId: string
  name: string
}) {
  return await apiAthenas.put<{
    id: string
  }>(`/tags/${boardId}/${tagId}`, { name })
}
