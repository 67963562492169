import { useSearchParams } from 'react-router-dom'

const GET_BOARD_KEY = 'GET_BOARD'

export function useMakeGetBoardKey(id: string) {
  const [searchParams] = useSearchParams()

  const late = searchParams.get('late') ?? undefined
  const tomorrow = searchParams.get('tomorrow') ?? undefined
  const today = searchParams.get('today') ?? undefined
  const startDate = searchParams.get('startDate') ?? undefined
  const endDate = searchParams.get('endDate') ?? undefined
  const usersId = searchParams.getAll('userId').map(Number)
  const companiesId = searchParams.getAll('companyId').map(Number)
  const qa = searchParams.get('qa') ?? undefined
  const mosaic = searchParams.get('mosaic') ?? undefined

  const params = {
    qa,
    mosaic,
    late,
    tomorrow,
    today,
    startDate,
    endDate,
    usersId,
    companiesId
  }

  return { queryKey: [GET_BOARD_KEY, id, { ...params }], params }
}
