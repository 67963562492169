import { twMerge } from '@/styles/tailwindMergeConfig'
import { MdArrowBack } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

interface LoadingSkeleton {
  className?: string
}

export function LoadingSkeleton({ className }: LoadingSkeleton) {
  const navigate = useNavigate()

  return (
    <main className=" flex h-screen w-full flex-col items-center bg-cromai-m3-sys-light-background px-7 dark:bg-cromai-m3-sys-dark-background">
      <header
        className={twMerge(
          className,
          'mt-6 flex w-full max-w-screen-xl items-start justify-between pt-3 font-mono'
        )}
      >
        <div className="flex">
          <button title="Voltar" className="flex" onClick={() => navigate(-1)}>
            <MdArrowBack
              size={24}
              className="text-cromai-m3-sys-light-on-background hover:opacity-80 dark:text-cromai-m3-sys-dark-on-background  "
            />
          </button>

          <div className="ml-5">
            <div className="h-6 w-[361px] rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant"></div>
            <div className="mt-1 h-4 w-[371px] rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant"></div>
            <div className="mt-2 flex gap-4">
              <div className="h-6 w-[224px] rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant" />
              <div className="h-6 w-[118px] rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant" />
              <div className="h-6 w-[56px] rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant" />
            </div>
          </div>
        </div>
        <div className="h-6 w-[361px] rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant" />
      </header>

      <section
        className={twMerge(
          className,
          'mt-6 flex h-[calc(100vh-12rem)] w-full max-w-screen-xl flex-col gap-6 lg:flex-row '
        )}
      >
        {/* Aba Links */}
        <div className="flex h-full w-full flex-col items-start gap-2">
          <span className="h-8 w-20 rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant" />
          <div
            className={twMerge(
              'bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant',
              ' flex h-full w-full rounded'
            )}
          ></div>
        </div>

        {/* Aba Responsaveis */}
        <div className="flex h-full w-full flex-col gap-2 lg:w-[500px]">
          <span className="h-8 w-28 rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant" />
          <div className="flex h-full w-full flex-col items-start overflow-auto rounded bg-cromai-m3-sys-light-surface-variant px-6 py-4 font-mono text-labelLarge dark:bg-cromai-m3-sys-dark-surface-variant"></div>
        </div>
      </section>
    </main>
  )
}
