import { twMerge } from '@/styles/tailwindMergeConfig'

export function BoardSkeletons({ loading }: { loading?: boolean }) {
  return (
    <div
      role="progressbar"
      className={twMerge(
        'flex w-full flex-col gap-8 px-6 py-4',
        'h-[calc(100vh-3.5rem)] overflow-hidden',
        `${loading && 'animate-pulse'}`
      )}
    >
      <header className="mb-0 flex w-full flex-col items-center rounded">
        <div className="flex w-full max-w-screen-xl items-center gap-8 rounded">
          <div className="h-10 w-52 rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant" />
          <div className="h-10 w-20 rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant" />
          <div className="h-10 w-20 rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant" />
        </div>
      </header>

      <div className="flex size-full gap-8">
        {Array.from({ length: 10 }).map((_, counter) => {
          return (
            <div key={counter} className="flex flex-col gap-4 pb-2">
              <div className="h-5 w-20 rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant" />
              <div className="h-5 w-52 rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant" />
              <div className="h-full w-52 rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant" />
            </div>
          )
        })}
      </div>
    </div>
  )
}
