import { Button, Input } from '@/components'
import { useCreateColumn } from '@/hooks/boards/useCreateColumn'
import { SummaryBoards } from '@/services/modules/athenas/boards/get-summary-boards'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { ColumnConfig } from './ColumnConfig'

const columnNameSchema = z.object({
  name: z.string()
})
type ColumnNameSchema = z.infer<typeof columnNameSchema>

type ColumnsProps = {
  summaryBoard?: SummaryBoards
}

export function Columns({ summaryBoard }: ColumnsProps) {
  const { mutate: createColumn, isLoading: isCreateColumn } = useCreateColumn()

  const { register, handleSubmit, reset } = useForm<ColumnNameSchema>({
    resolver: zodResolver(columnNameSchema)
  })

  const handleSubmitNewColumn = handleSubmit((data, event) => {
    event?.preventDefault()
    createColumn({ columnName: data.name, boardId: summaryBoard!.id })
    reset()
  })

  return (
    <div className="mt-6 text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background">
      <h1 className="mb-2 text-titleMedium">Colunas extras</h1>
      <p className="mb-4 w-[432px] font-mono text-bodySmall ">
        Configure a quantidade e o nome das colunas extras do quadro
        selecionado. Todas as colunas extras serão adicionadas após a coluna
        “Processadas” e antes da coluna “Concluídas”.
      </p>
      <form
        onSubmit={handleSubmitNewColumn}
        className="flex items-center gap-4"
      >
        <Input
          required
          className="w-52"
          placeholder="Coluna extra"
          {...register('name', { required: true })}
        />

        <Button
          loading={isCreateColumn}
          disabled={!summaryBoard}
          variant="outlined"
          className="w-[152px]"
        >
          Adicionar coluna
        </Button>
      </form>

      <div className="h-[calc(100vh-20rem)] overflow-auto">
        {summaryBoard?.columns.map((column, index) => {
          return (
            <ColumnConfig
              key={column.id}
              boardId={summaryBoard.id}
              columnId={column.id}
              columnName={column.name}
              columnIndex={index}
              indexLastItem={summaryBoard.columns.length - 1}
            />
          )
        })}
      </div>
    </div>
  )
}
