import { useGetUsers } from '@/hooks/demands/queries/useGetUsers'
import { useGetCompanies } from '@/hooks/useGetCompanies'
import { useSearchParamsValues } from '@/hooks/useSearchParamsValues'

const statusList = [
  { id: 'registered', label: 'Cadastrada' },
  { id: 'processing', label: 'Processando' },
  { id: 'processed', label: 'Processada' },
  { id: 'rejected', label: 'Recusada' },
  { id: 'concluded', label: 'Concluída' }
]

const typeList = [
  { id: 'scanweed-cane', label: 'Scanweed cana-de-açúcar' },
  { id: 'scanweed-soybean', label: 'Scanweed soja' },
  { id: 'growline-cane', label: 'Growline cana-de-açúcar' }
]

export function useFiltersApplied() {
  const { data: users } = useGetUsers()
  const { data: companies } = useGetCompanies()
  const searchParamsValue = useSearchParamsValues()

  const usersList = searchParamsValue.usersId.map((id) => {
    const isUser = users?.find((user) => user.id == Number(id))

    if (isUser) {
      return isUser.name
    }
  })

  const companiesList = searchParamsValue.companiesId.map((id) => {
    const isCompany = companies?.find((company) => company.id == Number(id))

    if (isCompany) {
      return isCompany.name
    }
  })

  const status = searchParamsValue.status.map((currentStatus) => {
    const isStatus = statusList?.find(
      (possibleStatus) => currentStatus === possibleStatus.id
    )

    if (isStatus) {
      return isStatus.label
    }
  })

  const types = searchParamsValue.types.map((currentType) => {
    const isType = typeList?.find(
      (possibleType) => currentType === possibleType.id
    )

    if (isType) {
      return isType.label
    }
  })

  const qa = searchParamsValue.qa && 'QA'
  const mosaic = searchParamsValue.mosaic && 'Mosaico'
  const late = searchParamsValue.late && 'Atrasadas'
  const today = searchParamsValue.today && 'Entrega hoje'
  const tomorrow = searchParamsValue.tomorrow && 'Entrega amanhã'

  const startDate = () => {
    if (searchParamsValue.startDate) {
      const newDate = new Date(searchParamsValue.startDate).toLocaleString(
        'pt-BR',
        { day: '2-digit', month: '2-digit', year: 'numeric' }
      )
      console.log(newDate)
      return newDate
    }
    return undefined
  }
  const endDate = () => {
    if (searchParamsValue.endDate) {
      const newDate = new Date(searchParamsValue.endDate).toLocaleString(
        'pt-BR',
        { day: '2-digit', month: '2-digit', year: 'numeric' }
      )
      console.log(newDate)
      return newDate
    }
    return undefined
  }

  const allValuesFormatted = [
    qa,
    mosaic,
    late,
    today,
    tomorrow,
    endDate(),
    startDate(),
    ...types,
    ...status,
    ...usersList,
    ...companiesList
  ].filter((item) => item !== undefined && item !== null)

  console.log(allValuesFormatted)

  const countFilters = allValuesFormatted.length

  const labels = [
    'Filtrar',
    `${countFilters} Filtro aplicado`,
    `${countFilters} Filtros aplicados`
  ]

  const findLabel =
    labels[Math.min(allValuesFormatted.length, labels.length - 1)]

  return {
    countFilters,
    filters: allValuesFormatted,
    buttonLabel: findLabel
  }
}
