import { twMerge } from '@/styles/tailwindMergeConfig'
import * as TooltipRadix from '@radix-ui/react-tooltip'
import { ReactNode } from 'react'

type TooltipProps = {
  trigger: ReactNode
  text?: string
  listItems?: string[]
  side?: 'top' | 'right' | 'bottom' | 'left'
  align?: 'center' | 'start' | 'end'
}

export function Tooltip({
  trigger,
  align,
  listItems,
  side,
  text
}: TooltipProps) {
  const isContent = !!listItems?.length || !!text
  return (
    <TooltipRadix.Provider delayDuration={500}>
      <TooltipRadix.Root>
        <TooltipRadix.Trigger asChild>{trigger}</TooltipRadix.Trigger>

        <TooltipRadix.Portal>
          <TooltipRadix.Content side={side} align={align}>
            {isContent && (
              <div
                className={twMerge(
                  'my-1.5 flex flex-col items-center gap-2 truncate rounded-sm px-3 py-1',
                  'truncate font-mono text-labelSmall',
                  'bg-cromai-m3-sys-light-inverse-surface dark:bg-cromai-m3-sys-dark-inverse-surface',
                  'text-cromai-m3-sys-light-inverse-on-surface dark:text-cromai-m3-sys-dark-inverse-on-surface'
                )}
              >
                {text}
                {listItems?.map((item) => <p key={item}>{item}</p>)}
              </div>
            )}
          </TooltipRadix.Content>
        </TooltipRadix.Portal>
      </TooltipRadix.Root>
    </TooltipRadix.Provider>
  )
}
