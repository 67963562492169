import { customTv } from '@/styles/tailwindMergeConfig'
import { useState, forwardRef, ReactNode } from 'react'
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5'

import { twMerge } from 'tailwind-merge'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  isError?: boolean
  icon?: ReactNode
}

const variants = customTv({
  base: [
    'm-0 w-full rounded border px-4 py-[14px] font-mono text-bodyMedium font-normal leading-tight focus:outline-none focus:ring-transparent',
    'bg-cromai-m3-sys-light-surface dark:bg-cromai-m3-sys-dark-surface',
    'border-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline',
    'text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant',
    'placeholder:text-cromai-m3-sys-light-outline dark:placeholder:text-cromai-m3-sys-dark-outline',
    'focus:border-cromai-m3-sys-light-primary dark:focus:border-cromai-m3-sys-dark-primary'
  ].join(' '),

  variants: {
    borderError: {
      true: [
        'border-cromai-m3-ref-error-30 dark:border-cromai-m3-ref-error-40',
        'focus:border-cromai-m3-ref-error-30 dark:focus:border-cromai-m3-ref-error-50'
      ].join(' ')
    }
  }
})

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, className, type, isError, children, icon, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false)

    return (
      <label>
        <div className="flex justify-between font-mono text-labelMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
          {label}
        </div>
        <div className={twMerge('relative w-full rounded', label && 'mt-2')}>
          <input
            {...props}
            ref={ref}
            className={variants({
              borderError: isError,
              className
            })}
            type={showPassword ? 'text' : type}
          />
          {children && children}

          {icon && (
            <div
              data-testid="icon-prop"
              className="absolute right-4 top-6 flex h-0 cursor-pointer items-center justify-center text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant"
            >
              {icon}
            </div>
          )}

          {type === 'password' && (
            <button
              type="button"
              data-testid="btn-show-password"
              className="absolute right-4 top-6 flex h-0 cursor-pointer items-center justify-center"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <IoEyeOffOutline
                  data-testid="svg-closed-eye"
                  className="text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant"
                />
              ) : (
                <IoEyeOutline
                  data-testid="svg-open-eye"
                  className="text-cromai-m3-sys-light-on-surface-variant  dark:text-cromai-m3-sys-dark-on-surface-variant"
                />
              )}
            </button>
          )}
        </div>
      </label>
    )
  }
)
