import { PropsWithChildren } from 'react'
import * as RadixSelect from '@radix-ui/react-select'

import { twMerge } from '@/styles/tailwindMergeConfig'

import { MdArrowDropDown } from 'react-icons/md'

type SelectProps = {
  placeholder: string
  onValueChange: (value: string) => void
  value: string
  side?: 'bottom' | 'top' | 'right' | 'left'
  align?: 'center' | 'start' | 'end'
} & PropsWithChildren

export function Select({
  placeholder,
  children,
  onValueChange,
  value,
  align = 'center',
  side = 'bottom'
}: SelectProps) {
  return (
    <RadixSelect.Root onValueChange={onValueChange} value={value}>
      <RadixSelect.Trigger className="flex w-52 items-center justify-between gap-2 rounded border border-cromai-m3-sys-light-outline px-4 py-3 text-cromai-m3-sys-light-on-surface-variant outline-none dark:border-cromai-m3-sys-dark-outline dark:text-cromai-m3-sys-dark-on-surface-variant">
        <RadixSelect.Value placeholder={placeholder} className="" />
        <RadixSelect.Icon>
          <MdArrowDropDown size={24} />
        </RadixSelect.Icon>
      </RadixSelect.Trigger>

      <RadixSelect.Portal>
        <RadixSelect.Content position="popper" side={side} align={align}>
          <RadixSelect.ScrollUpButton />
          <RadixSelect.Viewport
            className={twMerge(
              'flex flex-col justify-center py-2',
              'mt-1 w-52 rounded',
              'text-bodyMedium, font-mono',
              'bg-cromai-m3-sys-light-surface2 dark:bg-cromai-m3-sys-dark-surface2',
              'text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant',
              'shadow-lightElevation2 dark:shadow-darkElevation2'
            )}
          >
            {children}
          </RadixSelect.Viewport>
          <RadixSelect.ScrollDownButton />
        </RadixSelect.Content>
      </RadixSelect.Portal>
    </RadixSelect.Root>
  )
}
