import axios from 'axios'
import { env } from '@/settings/env'

export const apiAthenas = axios.create({
  baseURL: env.API_ATHENAS_URL
})

apiAthenas.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('@athenas:accessToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

apiAthenas.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      // Lidar com o token inválido, expirado ou ausente
      // Redirecionar para a página de login, por exemplo
    }
    return Promise.reject(error)
  }
)
