import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/providers'
import { produce } from 'immer'

import { handleToast } from '@/utils/handleToast'
import { SummaryBoards } from '@/services/modules/athenas/boards/get-summary-boards'
import { createColumn } from '@/services/modules/athenas/boards/create-column'
import { GET_SUMMARY_BOARDS_KEY } from './useGetSummaryBoards'

export function useCreateColumn() {
  const createColumnOnCache = useCallback(
    ({ id, name, bordId }: { id: string; name: string; bordId: string }) =>
      queryClient.setQueryData<SummaryBoards[]>(
        [GET_SUMMARY_BOARDS_KEY],
        produce((draft) => {
          if (!draft) return

          const index = draft.findIndex((bord) => bord.id === bordId)

          draft[index].columns.push({ id, name })
        })
      ),
    []
  )

  const mutation = useMutation({
    mutationFn: createColumn,
    onSuccess: ({ id, name }, variables) => {
      createColumnOnCache({ id, name, bordId: variables.boardId })
    },
    onError: () =>
      handleToast({
        message:
          'Erro ao criar coluna. Atualize a página e tente criar novamente.',
        type: 'error'
      })
  })

  return mutation
}
