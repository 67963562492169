import { twMerge } from '@/styles/tailwindMergeConfig'
import * as AccordionRadix from '@radix-ui/react-accordion'
import { ReactNode } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'

function AccordionRoot({
  children,
  type = 'single'
}: {
  children: ReactNode
  type?: 'single' | 'multiple'
}) {
  return (
    <AccordionRadix.Root type={type} collapsible>
      {children}
    </AccordionRadix.Root>
  )
}
function AccordionItem({
  children,
  ...props
}: AccordionRadix.AccordionItemProps) {
  return <AccordionRadix.Item {...props}>{children}</AccordionRadix.Item>
}

function AccordionHeader({
  children,
  className,
  disabled = false
}: {
  className?: string
  children: ReactNode
  disabled?: boolean
}) {
  return (
    <AccordionRadix.Header
      className={twMerge(className, 'flex w-full justify-between ')}
    >
      {children}
      <AccordionRadix.Trigger
        aria-label="Abrir/Fechar"
        className=" transition duration-300 ease-in-out data-[state=open]:origin-center data-[state=open]:rotate-180"
        disabled={disabled}
      >
        <MdKeyboardArrowDown
          size={24}
          className={twMerge(
            disabled && 'pointer-events-none opacity-50',
            'text-cromai-m3-sys-light-on-surface-variant',
            'dark:text-cromai-m3-sys-dark-on-surface-variant',
            'hover:opacity-80',
            'm-2'
          )}
        />
      </AccordionRadix.Trigger>
    </AccordionRadix.Header>
  )
}

function AccordionContent({ children }: { children: ReactNode }) {
  return (
    <AccordionRadix.Content className="w-full">
      {children}
    </AccordionRadix.Content>
  )
}

export const Accordion = {
  Root: AccordionRoot,
  Header: AccordionHeader,
  Item: AccordionItem,
  Content: AccordionContent
}
