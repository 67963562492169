import * as Popover from '@radix-ui/react-popover'

import { Colors } from '@/types/Colors'
import { TagColor } from '@/components'

type TagColorsMenuProps = {
  setColor: (value: Colors) => void
  color: Colors
}

const colors = [
  'turquoise',
  'yellow',
  'orange',
  'red',
  'blue',
  'green',
  'pink',
  'gray'
]

export function TagColorsMenu({ setColor, color }: TagColorsMenuProps) {
  return (
    <Popover.Root>
      <Popover.Trigger aria-label={'Cores da etiqueta'}>
        <TagColor
          size={24}
          color={color}
          className={
            'absolute right-4 top-3.5 flex cursor-pointer transition-all hover:opacity-80'
          }
        />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content side="bottom" align="center">
          <div className="ml-16 mt-6 flex h-[6.25rem] w-[8.5rem] flex-col gap-2 rounded bg-cromai-m3-sys-light-surface2 p-2 py-1 shadow-lightElevation2 dark:bg-cromai-m3-sys-dark-surface2 dark:shadow-darkElevation2">
            <h1 className="font-mono text-bodyMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
              Cor da etiqueta
            </h1>
            <div className="flex flex-wrap gap-2">
              {colors.map((color: any) => (
                <button
                  aria-label={color}
                  onClick={() => setColor(color)}
                  value={color}
                  key={color}
                  className="transition-all hover:opacity-80"
                >
                  <TagColor key={color} size={24} color={color} />
                </button>
              ))}
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
