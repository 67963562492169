import { apiAthenas } from '@/services/api/apiAthenas'

export async function deleteTag({
  boardId,
  tagId
}: {
  boardId: string
  tagId: string
}) {
  await apiAthenas.delete(`/tags/${boardId}/${tagId}`)
}
