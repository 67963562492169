import * as DialogRadix from '@radix-ui/react-dialog'
import { Button, Dialog } from '@/components'
import { useDeleteColumn } from '@/hooks/boards/useDeleteColumn'

interface DeleteColumnDialogProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  columnName: string
  columnId: string
  boardId: string
}

export function DeleteColumnDialog({
  isOpen,
  setIsOpen,
  columnName,
  boardId,
  columnId
}: DeleteColumnDialogProps) {
  const { mutate: deleteColumn } = useDeleteColumn()

  function handleDeleteColumn() {
    deleteColumn({ boardId, columnId })
    setIsOpen(false)
  }

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="w-[27rem] p-6">
        <DialogRadix.Title className="mb-2 text-titleLarge text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
          Remover coluna
        </DialogRadix.Title>
        <span className="font-mono text-bodyLarge text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
          Quaisquer demandas que estiverem nesta coluna serão movidas para a
          coluna {columnName}
        </span>
        <footer className="mt-6 flex w-full items-center justify-between">
          <DialogRadix.Close asChild>
            <Button variant="outlined">Cancelar</Button>
          </DialogRadix.Close>
          <Button onClick={handleDeleteColumn}>Remover</Button>
        </footer>
      </div>
    </Dialog>
  )
}
