import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiAtlas } from '@/services/api/apiAtlas'
import { env } from '@/settings/env'

export type AuthRequest = {
  data: {
    token: string
  }
}

export type AuthResponse = {
  token: string
}

export async function GetAccess({
  data
}: AuthRequest): Promise<AxiosResponse<AuthResponse, any>> {
  const method = 'GET'
  const url = `/authentication/access_token?context=company&context_id=${
    env.API_AUTH_COMPANY_ID ?? '1'
  }`

  const options: AxiosRequestConfig = {
    method,
    url,
    data,
    headers: {
      Authorization: `Bearer ${data.token}`
    }
  }

  const response = await apiAtlas.request<AuthResponse>(options)
  return response
}
