import { apiAthenas } from '@/services/api/apiAthenas'

export async function updateColumnName({
  boardId,
  columnId,
  columnName
}: {
  columnId: string
  boardId: string
  columnName: string
}) {
  return await apiAthenas.put(`/boards/${boardId}/column/${columnId}`, {
    columnName
  })
}
