import { Button, Scrollarea, SlideSheet } from '@/components'
import { useAddNewPart } from '@/hooks/useAddNewPart'

import { Demand, Reviewer } from '@/types/demands'
import { MdAdd as PlusIcon } from 'react-icons/md'
import { GrowLinePart } from './GrowLinePart'

import { MdUploadFile as UploadFileIcon } from 'react-icons/md'

import { twMerge } from 'tailwind-merge'
interface DialogUploadFailureProps {
  demand: Demand
  reviewer: Reviewer
}

export function DialogUploadFailure({
  demand,
  reviewer
}: DialogUploadFailureProps) {
  const { mutate, isLoading } = useAddNewPart()

  return (
    <SlideSheet
      heading={`Resultados de ${reviewer.userName}`}
      trigger={
        <div
          className={twMerge(
            'flex items-center gap-3 px-3 py-1.5',
            'hover:bg-cromai-m3-sys-light-surface2-hover dark:hover:bg-cromai-m3-sys-dark-surface2-hover',
            'text-cromai-m3-sys-light-inverse-surface dark:text-cromai-m3-sys-dark-inverse-surface'
          )}
        >
          <UploadFileIcon size={21} />
          {demand.status.value === 'concluded'
            ? 'Visualizar arquivos'
            : 'Realizar upload de arquivos'}
        </div>
      }
    >
      <Scrollarea className="h-[calc(100vh-8rem)]">
        {reviewer.files.grow_lines?.map((part) => {
          return (
            <GrowLinePart
              key={part.partId}
              part={part}
              demandId={demand.id}
              demandStatus={demand.status.value}
              reviewer={reviewer}
            />
          )
        })}
      </Scrollarea>
      <Button
        variant="outlined"
        disabled={demand.status.value == 'concluded'}
        loading={isLoading}
        onClick={() => mutate({ demandId: demand.id, userId: reviewer.userId })}
      >
        <PlusIcon size={16} /> Adicionar parte
      </Button>
    </SlideSheet>
  )
}
