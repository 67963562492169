import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  ReactNode
} from 'react'
import { handleToast } from '@/utils/handleToast'
import { useSignIn } from '@/hooks/useSignIn'
import { isValidEmail } from '@/utils/isValidEmail'

export type User = {
  id: number
  email: string
  name: string
  permissions: string
  exp: number
}

function useAuthContext() {
  const [token, setToken] = useState<string>('')
  const [user, setUser] = useState<User>({} as User)
  const [isInitialLoading, setIsInitialLoading] = useState(true)

  const { isLoading, mutate, reset } = useSignIn({
    onSuccess: ({ token, user, accessToken }) => {
      localStorage.setItem('@athenas:accessToken', accessToken)
      localStorage.setItem('@athenas:token', token)
      setUser(user)
      setToken(token)
    },
    onError: () => {
      handleToast({
        message: 'E-mail e/ou senha incorretos.',
        type: 'error'
      })
    }
  })

  const isAuthenticated = Boolean(token)

  const signOut = useCallback(() => {
    localStorage.clear()
    setUser({} as User)
    setToken('')
    reset()
  }, [reset])

  const signIn = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      if (isAuthenticated) signOut()

      const isCromaiEmail = isValidEmail(email)

      if (!email || !password || !isCromaiEmail) {
        handleToast({
          message: 'E-mail e/ou senha incorretos.',
          type: 'error'
        })
        return
      }

      if (email.trim().length !== 0 && password.trim().length !== 0) {
        mutate({ data: { email, password } })
      }
    },
    [isAuthenticated, mutate, signOut]
  )

  const checkUserSession = useCallback(
    (showToast = false) => {
      const sessionToken = localStorage.getItem('@athenas:token')

      if (sessionToken) {
        const claims = sessionToken.split('.')[1]
        const user = JSON.parse(atob(claims)) as User

        const expDate = new Date(user.exp * 1000)
        const now = new Date()

        const isValidToken =
          expDate > now && expDate.getDate() !== now.getDate()

        if (!isValidToken) {
          showToast &&
            handleToast({
              message: 'Sua sessão expirou. Faça o login novamente!',
              type: 'info'
            })
          return signOut()
        }
        return { user, token: sessionToken }
      } else {
        return signOut()
      }
    },
    [signOut]
  )

  useEffect(() => {
    const userSession = checkUserSession()

    if (userSession) {
      setToken(userSession.token)
      setUser(userSession.user)
    }
    setIsInitialLoading(false)

    const checkUserSessionWithToast = () => checkUserSession(true)

    window.addEventListener('focus', checkUserSessionWithToast)
    return () => window.removeEventListener('focus', checkUserSessionWithToast)
  }, [checkUserSession])

  return {
    token: token!,
    user: user!,
    signIn,
    signOut,
    isLoading,
    isAuthenticated,
    isInitialLoading
  } as const
}

export const AuthContext = createContext(
  {} as ReturnType<typeof useAuthContext>
)

export function AuthProvider({ children }: { children: ReactNode }) {
  return (
    <AuthContext.Provider value={useAuthContext()}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  return context
}
