import { getSignedUrl } from '@/services/modules/athenas/demands'
import { useMutation } from '@tanstack/react-query'

export function useDownload({ onError }: { onError: () => void }) {
  const mutation = useMutation({
    mutationKey: ['downloadFile'],
    mutationFn: async ({
      id,
      key,
      value
    }: {
      id: string
      key: string
      value: string
    }) => {
      const response = await getSignedUrl(id, key, value)

      const anchorElement = document.createElement('a')
      anchorElement.setAttribute('download', 'title')

      anchorElement.href = response.data
      anchorElement.click()
    },
    onError: onError
  })
  return {
    download: mutation
  }
}
