import { deliveryDemand } from '@/services/modules/athenas/demands'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { handleToast } from '@/utils/handleToast'
import { Demand } from '@/types/demands'
import { makeGetDemandKey } from './useGetDemand'
import { produce } from 'immer'
import { useCallback } from 'react'

interface UseDeliveryDemand {
  onSuccess: () => void
}

export function useDeliveryDemand({ onSuccess }: UseDeliveryDemand) {
  const queryClient = useQueryClient()

  const updateStatusDemandFromCache = useCallback(
    (variables: { demandId: string; treatment: Demand['treatment'] }) =>
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(variables.demandId),
        produce((draft) => {
          if (!draft) return

          draft.status = {
            value: 'delivering',
            message: 'A entrega da demanda está sendo processada.'
          } as any
        })
      ),
    [queryClient]
  )

  const mutation = useMutation({
    mutationFn: deliveryDemand,
    cacheTime: 0,
    onSuccess: (_, variables) => {
      onSuccess()
      updateStatusDemandFromCache(variables)
      handleToast({
        type: 'info',
        message: 'A entrega da demanda está sendo processada .'
      })
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Ocorreu um erro ao entregar a demanda. Tente novamente.'
      })
  })
  return {
    deliveryDemand: mutation
  }
}
