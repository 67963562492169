import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { Button, Dialog, Input, MultiSelect, SlideSheet } from '@/components'
import { MdFilterList, MdOutlineDateRange } from 'react-icons/md'
import { ptBR } from 'date-fns/locale'

import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import {
  DialogDescription,
  DialogClose,
  DialogTitle
} from '@radix-ui/react-dialog'

import { useGetUsers } from '@/hooks/demands/queries/useGetUsers'
import { zodResolver } from '@hookform/resolvers/zod'
import { useGetCompanies } from '@/hooks/useGetCompanies'
import { FilterSkeleton } from './FilterSkeleton'
import { useFiltersApplied } from '@/pages/Board/hooks/useFiltersApplied'
import { Tooltip } from '../Tooltip'

type FiltersProps = {
  options?: {
    status?: boolean
    type?: boolean
  }
}

const filtersSchema = z.object({
  type: z
    .array(z.enum(['scanweed-cane', 'scanweed-soybean', 'growline-cane']))
    .default([]),
  deadlines: z.array(z.enum(['late', 'tomorrow', 'today'])).default([]),
  startDate: z.union([z.date(), z.null()]),
  endDate: z.union([z.date(), z.null()]),
  status: z.array(z.string()).default([]),
  companies: z.array(z.string()).default([]),
  responsibles: z.array(z.string()).default([]),
  others: z.array(z.enum(['qa', 'mosaic'])).default([])
})

type FiltersSchema = z.infer<typeof filtersSchema>

export function Filters({ options }: FiltersProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [_, setSearchParams] = useSearchParams()
  const { data: users, isLoading: isLoadingUsers } = useGetUsers()
  const { data: companies, isLoading: isLoadingCompanies } = useGetCompanies()

  const { buttonLabel, filters } = useFiltersApplied()

  const { handleSubmit, control, reset } = useForm<FiltersSchema>({
    resolver: zodResolver(filtersSchema)
  })

  function handlerClearForm() {
    reset({
      type: [],
      status: [],
      startDate: null,
      endDate: null,
      deadlines: [],
      companies: [],
      others: [],
      responsibles: []
    })
  }

  const onSubmit = handleSubmit((data, event) => {
    event?.preventDefault()

    const isSelectedDate = !!data.startDate || !!data.endDate
    const isDeadline = !!data.deadlines.length

    const isInvalidQuery = isDeadline && isSelectedDate

    if (isInvalidQuery) {
      setIsOpen(true)
      reset({ deadlines: [], startDate: null, endDate: null })

      return
    }

    setSearchParams((prev) => {
      prev.delete('type')
      prev.delete('late')
      prev.delete('today')
      prev.delete('tomorrow')
      prev.delete('startDate')
      prev.delete('endDate')
      prev.delete('status')
      prev.delete('companyId')
      prev.delete('userId')
      prev.delete('qa')
      prev.delete('mosaic')

      data.type.forEach((type) => prev.append('type', type))

      data.deadlines.forEach((deadline) => prev.append(deadline, 'true'))

      data.responsibles.forEach((responsible) =>
        prev.append('userId', responsible)
      )
      data.status.forEach((status) => prev.append('status', status))

      data.others.forEach((other) => prev.append(other, 'true'))

      data.companies.forEach((companie) => prev.append('companyId', companie))

      data.startDate &&
        prev.append('startDate', new Date(data.startDate).toISOString())

      data.endDate &&
        prev.append('endDate', new Date(data.endDate).toISOString())

      return prev
    })
  })

  const responsibles = users?.map((responsible) => ({
    id: responsible.id.toString(),
    label: responsible.name
  }))

  const clients = companies?.map((company) => ({
    id: company.id.toString(),
    label: company.name
  }))

  return (
    <>
      <SlideSheet
        heading="Filtrar"
        trigger={
          <div aria-label="btn-trigger-open-filters">
            <Tooltip
              align="center"
              side="bottom"
              listItems={filters}
              trigger={
                <div className="flex items-center gap-2 whitespace-nowrap text-cromai-m3-sys-light-on-background transition-all hover:opacity-80 dark:text-cromai-m3-sys-dark-on-background">
                  <MdFilterList size={14} />
                  {buttonLabel}
                </div>
              }
            />
          </div>
        }
      >
        {isLoadingUsers || isLoadingCompanies ? (
          <FilterSkeleton />
        ) : (
          <form
            onSubmit={onSubmit}
            className="flex h-[calc(100vh-6rem)] w-full flex-col gap-4 overflow-auto"
          >
            {options?.type && (
              <Controller
                name="type"
                control={control}
                defaultValue={[]}
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    placeholder="Tipo de demanda"
                    options={[
                      { id: 'scanweed-cane', label: 'Scanweed cana-de-açúcar' },
                      { id: 'scanweed-soybean', label: 'Scanweed soja' },
                      { id: 'growline-cane', label: 'Growline cana-de-açúcar' }
                    ]}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            )}
            <Controller
              name="deadlines"
              control={control}
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  placeholder="Prazo"
                  options={[
                    { id: 'late', label: 'Atrasadas' },
                    { id: 'today', label: 'Entrega hoje' },
                    { id: 'tomorrow', label: 'Entrega amanhã' }
                  ]}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <section className="flex w-full justify-between gap-4">
              <Controller
                name="startDate"
                control={control}
                defaultValue={null}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    dateFormat="dd/MM/YYYY"
                    locale={ptBR}
                    selected={value}
                    onChange={(date) => onChange(date)}
                    placeholderText="Data inicial"
                    customInput={
                      <Input
                        className="w-[200px]"
                        icon={<MdOutlineDateRange size={24} />}
                      />
                    }
                    enableTabLoop={false}
                    showPopperArrow={false}
                  />
                )}
              />
              <Controller
                name="endDate"
                control={control}
                defaultValue={null}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    dateFormat="dd/MM/YYYY"
                    locale={ptBR}
                    selected={value}
                    onChange={(date) => onChange(date)}
                    placeholderText="Data final"
                    customInput={
                      <Input
                        className="w-[200px]"
                        icon={<MdOutlineDateRange size={24} />}
                      />
                    }
                    enableTabLoop={false}
                    showPopperArrow={false}
                  />
                )}
              />
            </section>
            <Controller
              name="companies"
              control={control}
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  placeholder="Cliente"
                  options={clients!}
                  value={value}
                  onChange={onChange}
                  search
                />
              )}
            />
            {options?.status && (
              <Controller
                name="status"
                control={control}
                defaultValue={[]}
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    placeholder="Status"
                    options={[
                      { id: 'registered', label: 'Cadastrada' },
                      { id: 'processing', label: 'Processando' },
                      { id: 'processed', label: 'Processada' },
                      { id: 'rejected', label: 'Recusada' },
                      { id: 'concluded', label: 'Concluída' }
                    ]}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            )}
            <Controller
              name="responsibles"
              control={control}
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  placeholder="Responsavél"
                  options={responsibles!}
                  value={value}
                  onChange={onChange}
                  search
                />
              )}
            />
            <Controller
              name="others"
              control={control}
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  placeholder="Outras identificações"
                  options={[
                    { id: 'qa', label: 'QA' },
                    { id: 'mosaic', label: 'Mosaico' }
                  ]}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <div className="mt-auto flex w-full">
              <Button
                type="button"
                onClick={handlerClearForm}
                variant="text"
                className="w-full"
              >
                Limpar
              </Button>
              <Button type="submit" className="w-full">
                Aplicar
              </Button>
            </div>
          </form>
        )}
      </SlideSheet>
      <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="h-[196px] w-[432px] p-6">
          <DialogTitle className="text-titleLarge text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
            Escolha entre o filtro prazo ou filtro datas
          </DialogTitle>
          <DialogDescription className="font-mono text-bodyLarge text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
            Não é possível usar o filtro prazo e o filtro data inicial ou data
            final ao mesmo tempo.
          </DialogDescription>
          <DialogClose asChild className="ml-auto mt-8">
            <Button>Fechar</Button>
          </DialogClose>
        </div>
      </Dialog>
    </>
  )
}
