import { twMerge } from '@/styles/tailwindMergeConfig'

type ItemProps = {
  label: string
}

export function Item({ label }: ItemProps) {
  return (
    <span
      className={twMerge(
        'flex gap-1 rounded  px-2 font-mono text-labelLarge',
        'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface-variant',
        'bg-cromai-m3-sys-light-surface-variant  dark:bg-cromai-m3-sys-dark-surface-variant'
      )}
    >
      {label}
    </span>
  )
}
