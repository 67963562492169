import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiAtlas } from '@/services/api/apiAtlas'

export type AuthRequest = {
  data: {
    email: string
    password: string
  }
}

export type AuthResponse = {
  token: string
}

export async function PostAuth({
  data
}: AuthRequest): Promise<AxiosResponse<AuthResponse, any>> {
  const method = 'POST'
  const url = `/authentication/auth`

  const options: AxiosRequestConfig = {
    method,
    url,
    data
  }

  const response = await apiAtlas.request<AuthResponse>(options)
  return response
}
