import { useRef, ChangeEvent, PropsWithChildren } from 'react'
import { Button } from '..'

type FileInputButtonProps = PropsWithChildren & {
  onFileSelect: (file: FileList | null) => void
  label: string
  multiple?: boolean
  size?: 'xl' | 'base' | 'lg' | 'sm' | 'xs' | 'icon'
  variant?: 'filled' | 'pill' | 'outlined' | 'text'
  disabled?: boolean
  loading?: boolean
  className?: string
}

export function FileInputButton({
  onFileSelect,
  className,
  multiple,
  children,
  disabled,
  loading,
  variant,
  label,
  size
}: FileInputButtonProps) {
  const ref = useRef<HTMLInputElement>(null)

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files
    if (file) {
      onFileSelect(file)
    }
  }

  const handleButtonClick = () => {
    if (ref.current) {
      ref.current.click()
      ref.current.value = ''
    }
  }

  return (
    <>
      <input
        ref={ref}
        type="file"
        data-testid="input-file"
        accept=".shp, .shx, .prj, .dbf, .cpg"
        className="hidden"
        onChange={handleFileSelect}
        multiple={multiple}
      />
      <Button
        size={size}
        className={className}
        onClick={handleButtonClick}
        loading={loading}
        disabled={disabled}
        variant={variant}
      >
        {children}
        {label}
      </Button>
    </>
  )
}
