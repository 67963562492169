import { apiAthenas } from '@/services/api/apiAthenas'

export type GetUsersResponse = {
  id: number
  name: string
  email: string
}

export async function getUsers(signal?: AbortSignal) {
  const { data } = await apiAthenas.get<GetUsersResponse[]>('/users', {
    signal
  })
  return data
}
