import { useMutation, useQueryClient } from '@tanstack/react-query'

import { makeGetDemandKey } from './useGetDemand'
import { Demand } from '@/types/demands'

import { putRevisionTime } from '@/services/modules/athenas/demands'
import { handleToast } from '@/utils/handleToast'
import { produce } from 'immer'
import { useCallback } from 'react'

interface QueryFnProps {
  demandId: string
  userId: number
  revisionTime: string
}

export function usePutRevisionTime() {
  const queryClient = useQueryClient()

  const recordRevisionTimeFromCache = useCallback(
    (variables: QueryFnProps) =>
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(variables.demandId),
        produce((draft) => {
          if (!draft) return

          const index = draft.userList.findIndex(
            (user) => user.userId === variables.userId
          )
          if (index !== -1)
            draft.userList[index].revisionTime = variables.revisionTime
        })
      ),
    [queryClient]
  )

  const mutation = useMutation({
    mutationFn: async ({ demandId, userId, revisionTime }: QueryFnProps) => {
      await putRevisionTime(demandId, userId, revisionTime)
    },
    cacheTime: 0,
    onSuccess: (_, variables) => {
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(variables.demandId),
        recordRevisionTimeFromCache(variables)
      )
    },
    onError: () =>
      handleToast({
        type: 'error',
        message:
          'Não foi possível adicionar o tempo de revisão. Tente novamente.'
      })
  })
  return {
    putRevisionTime: mutation
  }
}
