import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { produce } from 'immer'

import { makeGetDemandKey } from '@/hooks/useGetDemand'

import { handleToast } from '@/utils/handleToast'
import { Demand } from '@/types/demands'
import { updateIsQACheckedOnResponsible } from '@/services/modules/athenas/demands/update-is-qa-checked-on-responsible'

type UpdateIsQACheckedOnResponsibleProps = {
  demandId: string
  reviewerId: number
  isQAChecked: boolean
}

export function useUpdateIsQACheckedOnResponsible() {
  const queryClient = useQueryClient()

  const updateIsQACheckedOnResponsibleFromCache = useCallback(
    ({
      reviewerId,
      demandId,
      isQAChecked
    }: UpdateIsQACheckedOnResponsibleProps) =>
      queryClient.setQueryData<Demand>(
        makeGetDemandKey(demandId),
        produce((draft) => {
          if (!draft) return

          const reviewerIndex = draft.userList.findIndex(
            (user) => user.userId === reviewerId
          )

          draft.userList[reviewerIndex].isQAChecked = isQAChecked
        })
      ),
    [queryClient]
  )

  const mutation = useMutation({
    mutationFn: async ({
      reviewerId,
      demandId,
      isQAChecked
    }: UpdateIsQACheckedOnResponsibleProps) => {
      updateIsQACheckedOnResponsibleFromCache({
        reviewerId,
        demandId,
        isQAChecked
      })

      await updateIsQACheckedOnResponsible({
        reviewerId,
        demandId,
        isQAChecked
      })
    },
    onError: (_, variables) => {
      queryClient.invalidateQueries(makeGetDemandKey(variables.demandId))
      handleToast({
        type: 'error',
        message: `Erro ao ${variables.isQAChecked ? 'marcar' : 'desmarcar'} o checklist de QA. Atualize a página e tente novamente.`
      })
    }
  })

  return mutation
}
