import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/providers'
import { produce } from 'immer'

import { handleToast } from '@/utils/handleToast'
import { SummaryBoards } from '@/services/modules/athenas/boards/get-summary-boards'
import { createTag } from '@/services/modules/athenas/boards/create-tag'
import { GET_SUMMARY_BOARDS_KEY } from '../useGetSummaryBoards'
import { Colors } from '@/types/Colors'

export function useCreateTag() {
  const createTagOnCache = useCallback(
    ({
      id,
      name,
      color,
      bordId
    }: {
      id: string
      name: string
      color: Colors
      bordId: string
    }) =>
      queryClient.setQueryData<SummaryBoards[]>(
        [GET_SUMMARY_BOARDS_KEY],
        produce((draft) => {
          if (!draft) return

          const index = draft.findIndex((bord) => bord.id === bordId)

          draft[index].tags.push({ id, name, color })
        })
      ),
    []
  )

  const mutation = useMutation({
    mutationFn: createTag,
    onSuccess: ({ id, name, color }, variables) => {
      createTagOnCache({ id, name, color, bordId: variables.boardId })
    },
    onError: () =>
      handleToast({
        message:
          'Erro ao criar etiqueta. Atualize a página e tente criar novamente.',
        type: 'error'
      })
  })

  return mutation
}
