import { Button, Accordion, FileInputButton, LoadingCircle } from '@/components'
import { useDeleteGrowLinesFiles } from '@/hooks/useDeleteGrowLinesFiles'
import { useUploadGrowLinesFiles } from '@/hooks/useUploadGrowLinesFiles'
import { twMerge } from '@/styles/tailwindMergeConfig'
import { Status } from '@/types/demands'
import { FileType } from '@/types/fileTypes'
import { MdAdd as PlusIcon, MdCached as UpdateIcon } from 'react-icons/md'

interface usePartFileProps {
  demandId: string
  demandStatus: Status['value']
  userId: number
  fileType: FileType
  partId: string
  files: string[] | null
}

const labeByType = {
  perimeter: 'Perímetro',
  planting_failure: 'Falha de plantio',
  reconstruction_lines: 'Reconstrução de linhas'
}

export function PartFilesUpload({
  demandId,
  demandStatus,
  userId,
  fileType,
  partId,
  files
}: usePartFileProps) {
  const { mutate: uploadGrowLinesFiles, isLoading: isUploadGrowLinesFiles } =
    useUploadGrowLinesFiles()
  const { mutate: deleteGrowLinesFiles, isLoading: isDeleteGrowLinesFiles } =
    useDeleteGrowLinesFiles()

  const isNotFinishedDemand = demandStatus !== 'concluded'

  return (
    <Accordion.Item value={fileType}>
      <Accordion.Header disabled={!files}>
        <div className="flex w-full items-center">
          <span className="mr-auto font-mono text-bodyMedium text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
            {labeByType[fileType]}
          </span>
          {isNotFinishedDemand && (
            <FileInputButton
              size="xs"
              variant={files ? 'filled' : 'outlined'}
              label={files ? 'Substituir arquivos' : 'Adicionar arquivos'}
              onFileSelect={(files) =>
                uploadGrowLinesFiles({
                  demandId,
                  userId,
                  fileType,
                  fileList: files!, // TODO: receber null
                  partId
                })
              }
              loading={isUploadGrowLinesFiles}
              multiple
              className={[
                'w-[9.1875rem]',
                files &&
                  'bg-cromai-m3-sys-light-secondary-container text-cromai-m3-sys-light-on-secondary-container dark:bg-cromai-m3-sys-dark-secondary-container dark:text-cromai-m3-sys-dark-on-secondary-container'
              ].join(' ')}
            >
              {files ? <UpdateIcon size={16} /> : <PlusIcon size={16} />}
            </FileInputButton>
          )}
        </div>
      </Accordion.Header>
      <Accordion.Content>
        {files && (
          <div
            className={twMerge(
              'break-all px-2',
              'text-cromai-m3-sys-light-on-surface-variant',
              'dark:text-cromai-m3-sys-dark-on-surface-variant'
            )}
          >
            {files?.map((file) => (
              <span
                className="flex items-center gap-2 px-3 py-2 font-mono text-labelSmall"
                key={file}
              >
                {file}
              </span>
            ))}

            {isNotFinishedDemand && (
              <Button
                className="ml-auto mr-6 text-cromai-m3-sys-light-error hover:text-cromai-m3-sys-light-error hover:opacity-80 dark:text-cromai-m3-sys-dark-error"
                variant="text"
                onClick={() =>
                  deleteGrowLinesFiles({
                    demandId,
                    fileType,
                    partId,
                    userId
                  })
                }
              >
                {isDeleteGrowLinesFiles && <LoadingCircle size={20} />}
                Remover arquivos
              </Button>
            )}
          </div>
        )}
      </Accordion.Content>
    </Accordion.Item>
  )
}
