import { apiAthenas } from '@/services/api/apiAthenas'
import { Demand, Term } from '@/types/demands'

export async function getDemand(id: string) {
  const { data } = await apiAthenas.get<Demand>(`/demands/${id}`)
  return data
}

export function getSignedUrl(id: string, key: string, value: string) {
  return apiAthenas.get<string>(`/demands/${id}/${key}/${value}`)
}

export function postResponsible({
  demandId,
  userId,
  userName
}: {
  demandId: string
  userId: number
  userName: string
}) {
  return apiAthenas.post(`/demands/${demandId}/${userId}`, { userName })
}

export function deleteResponsible({
  demandId,
  userId
}: {
  demandId: string
  userId: number
}) {
  return apiAthenas.delete(`/demands/${demandId}/${userId}`)
}

export function putReviewFinished({
  demandId,
  isFinish,
  userId
}: {
  demandId: string
  userId: number
  isFinish: boolean
}) {
  return apiAthenas.put(`/demands/${demandId}/${userId}/isFinish`, {
    isFinish
  })
}

export function putRevisionTime(
  demandId: string,
  userId: number,
  revisionTime: string
) {
  return apiAthenas.put(`/demands/${demandId}/${userId}`, { revisionTime })
}

export function deleteRevisionTime({
  demandId,
  userId
}: {
  demandId: string
  userId: number
}) {
  return apiAthenas.delete(`/demands/${demandId}/${userId}/revisionTime`)
}

export async function genereteUrlForUploadFiles(
  demandId: string,
  userId: number,
  fileType: string
) {
  const { data } = await apiAthenas.get<string>(
    `/demands/${demandId}/${userId}/files/${fileType}`
  )
  return data
}

export function confirmUploadWeedFiles(
  demandId: string,
  userId: number,
  fileType: string,
  fileNameList: string[]
) {
  return apiAthenas.post<string>(
    `/user-demand/${demandId}/${userId}/${fileType}`,
    { fileNameList }
  )
}

export function deliveryDemand({
  demandId,
  treatment
}: {
  demandId: string
  treatment: Demand['treatment']
}) {
  return apiAthenas.post<string>(`/delivery/${demandId}/${treatment}`)
}

export function recoverDemand({ demandId }: { demandId: string }) {
  return apiAthenas.put<string>(`/recover/${demandId}`)
}
export async function changeDeadline({
  demandId,
  deliveryDate
}: {
  demandId: string
  deliveryDate: string
}) {
  const { data } = await apiAthenas.put<{ date: string; type: Term }>(
    `/delivery-date/${demandId}`,
    { deliveryDate }
  )
  return data
}
