import { apiAthenas } from '@/services/api/apiAthenas'

export async function deleteColumn({
  boardId,
  columnId
}: {
  boardId: string
  columnId: string
}) {
  await apiAthenas.delete(`/boards/${boardId}/column/${columnId}`)
}
