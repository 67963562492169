import { apiAthenas } from '@/services/api/apiAthenas'

export async function changeIsTagOnCard({
  demandId,
  isTag,
  tagId
}: {
  tagId: string
  demandId: string
  isTag: boolean | 'indeterminate'
}) {
  await apiAthenas.put<{
    columnName: string
    columnId: string
  }>(`/tags/${tagId}/demand/${demandId}`, { isTag })
}
