import { Reviewers } from './ValidationTypes'

export function useAllPartsWithoutNumber(reviewers: Reviewers[]) {
  return reviewers
    .map((reviewer) => {
      return {
        reviewer: reviewer.userName,
        parts: reviewer.files.filter((part) => !part.partNumber)
      }
    })
    .filter((reviewer) => reviewer.parts?.length)
}
