import { apiAthenas } from '@/services/api/apiAthenas'

export async function updateIsQACheckedOnResponsible({
  demandId,
  reviewerId,
  isQAChecked
}: {
  demandId: string
  reviewerId: number
  isQAChecked: boolean
}) {
  await apiAthenas.put(`/user-demand/${demandId}/${reviewerId}`, {
    isQAChecked
  })
}
