import { apiAthenas } from '@/services/api/apiAthenas'

export async function moveTag({
  boardId,
  organizedTagsId
}: {
  boardId: string
  organizedTagsId: string[]
}) {
  await apiAthenas.put(`/tags/${boardId}`, { organizedTagsId })
}
