import axios from 'axios'
import { FileType } from '@/types/fileTypes'

export async function uploadRevisedWeedFilesOnStorage(
  zipBlob: Uint8Array,
  url: string,
  demandId: string,
  userId: number,
  fileName: string,
  fileType: FileType
) {
  await axios.put(url, zipBlob, {
    headers: {
      'x-goog-meta-demand-filename': `${fileType}:${demandId}-${userId}-${fileType}`,
      'x-goog-meta-demand-id': `${demandId}`,
      'Content-Disposition': `attachment; filename=${fileName}.zip`,
      'Content-Type': 'application/octet-stream'
    }
  })
}

export async function uploadRevisedGrowLinesFilesOnStorage(
  zipBlob: Uint8Array,
  url: string,
  demandId: string,
  userId: number,
  fileName: string,
  fileType: FileType,
  partId: string
) {
  await axios.put(url, zipBlob, {
    headers: {
      'x-goog-meta-demand-filename': `${fileType}:${demandId}-${userId}-${partId}-${fileType}`,
      'x-goog-meta-demand-id': `${demandId}`,
      'Content-Disposition': `attachment; filename=${fileName}.zip`,
      'Content-Type': 'application/octet-stream'
    }
  })
}
